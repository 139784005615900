import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';

import CommandsList, { type CommandListRef } from './CommandsList';

const renderSlashMenu = (tippySlashMenuRoot: string) => () => {
  let component: ReactRenderer<CommandListRef> | undefined = undefined;
  const myWindow = window as any;

  return {
    onStart: (props: any) => {
      component = new ReactRenderer(CommandsList, {
        props,
        editor: props.editor,
      });

      myWindow.tippySlashMenu = tippy(`#${tippySlashMenuRoot}`, {
        appendTo: 'parent',
        getReferenceClientRect: props.clientRect,
        content: component.element,
        showOnCreate: true,
        interactive: true,
        trigger: 'manual',
        placement: 'bottom-start',
        theme: 'sdPopper',
      });
    },
    onUpdate(props: any) {
      component?.updateProps(props);

      if (myWindow.tippySlashMenu?.[0]?.popperInstance) {
        myWindow.tippySlashMenu[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      }
    },
    onKeyDown(props: any) {
      return component?.ref?.onKeyDown(props);
    },
    onExit() {
      if (myWindow.tippySlashMenu?.[0]?.popperInstance) {
        myWindow.tippySlashMenu[0].destroy();
      }
      component?.destroy();
    },
  };
};

export default renderSlashMenu;
