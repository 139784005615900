import clsx from 'clsx';
import { useMemo, useState } from 'react';

import { claimChallenge } from '@api/challenge';
import linkedFigma from '@assets/img/figma-integration-linked.svg';
import unlinkedFigma from '@assets/img/figma-integration-unlinked.svg';
import Head from '@components/Head';
import { useSession } from '@hooks/useAuth';
import Button from '@ui/Button';
import styles from './AuthorizeIntegration.module.scss';

const AuthorizeIntegration = () => {
  const session = useSession();
  const [isComplete, setIsComplete] = useState(false);

  // check if there is a code challenge in the url
  const codeChallenge = useMemo(() => {
    const url = new URL(window.location.toString());
    return url.searchParams.get('codeChallenge');
  }, []);

  const onAuthorize = async () => {
    if (session?.kind === 'authenticated' && codeChallenge) {
      // claim the code challenge
      await claimChallenge(codeChallenge);
      setIsComplete(true);
    }
  };

  if (isComplete) {
    return (
      <>
        <Head title="Connected to Figma" />
        <div className={styles.container}>
          <div className={styles.light} />
          <div className={styles.content}>
            <img src={linkedFigma} alt="Figma" />
            <h1 className="title1">Connected to Figma</h1>
            <p className={clsx('body4', styles.subtitle)}>
              It's safe to close this window now
            </p>
            <div className={styles.buttons}>
              <Button
                onClick={() => window.open('https://www.figma.com', '_self')}
                variant="primary"
                size="lg"
              >
                <span>Open Figma</span>
              </Button>
              {/* TODO: support link */}
              <Button variant="outlined" size="lg">
                <span>Get help</span>
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Head title="Authorize Figma" />
      <div className={styles.container}>
        <div className={styles.light} />
        <div className={styles.content}>
          <img src={unlinkedFigma} alt="Figma" />
          <h1 className="title1">Authorize Figma</h1>
          <p className={clsx('body4', styles.subtitle)}>
            Please authorize Figma to connect Spaceduck.
          </p>
          <div className={styles.buttons}>
            <Button onClick={onAuthorize} variant="primary" size="lg">
              <span>Continue</span>
            </Button>
            {/* TODO: support link */}
            <Button variant="outlined" size="lg">
              <span>Get help</span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorizeIntegration;
