import { useContext } from 'react';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router';

import { Icon24 } from '@spaceduck/icons';
import { exists } from '@spaceduck/utils';

import type { UserInfo, Workspace } from '@spaceduck/api';
import { Icon64 } from '@spaceduck/icons';

import { useDeleteWorkspaceMember, usePatchWorkspace } from '@api/workspace';
import { ROLE_NAMES } from '@components/admin/const';
import NoEntries from '@components/NoEntries';
import { useDestroySession, useUserInfo } from '@hooks/useAuth';
import useWorkspaces from '@hooks/useWorkspaces';
import { ThemeContext } from '@themes/Theme';
import Button, { ButtonLink } from '@ui/Button';
import { useConfirmModal } from '@ui/ConfirmModal';
import { RecursiveDropdownMenu } from '@ui/DropdownMenu';
import { WorkspaceAvatar } from '@ui/UserAvatar';
import { urlFor } from '@/urls';
import { Container, Header, TopNav } from './common';
import styles from './WorkspacesManager.module.scss';

const { Menu } = Icon24;
const { Organization } = Icon64;

export default function WorkspacesManagerPage() {
  const user = useUserInfo();
  const { workspaces } = useWorkspaces();

  if (!user) return null;

  return (
    <>
      <TopNav title="Manage Workspaces" owner="account" currentBreadcrumb="Workspaces">
        <Link to={urlFor('newUserOnboarding')}>
          <Button variant="outlined" size="sm">
            New Workspace
          </Button>
        </Link>
      </TopNav>
      {workspaces.length ? (
        <WorkspaceList user={user} workspaces={workspaces} />
      ) : (
        <NoWorkspaces />
      )}
    </>
  );
}

const WorkspaceList = ({
  user,
  workspaces,
}: { user: UserInfo; workspaces: Workspace[] }) => {
  const { mutateAsync: patchWorkspace, status: patchWorkspaceStatus } =
    usePatchWorkspace();
  const { mutateAsync: deleteWorkspaceMember } = useDeleteWorkspaceMember();
  const leaveWorkspaceConfirmModal = useConfirmModal<{
    workspaceId: string;
  }>({
    title: 'Are you sure?',
    subtitle:
      'Upon your departure from this workspace, all permissions and access to spaces you are involved in will be permanently revoked. Please be aware that this action is irreversible.',
    confirmText: 'Yes, leave workspace',
    onConfirm(vars) {
      if (!vars?.workspaceId || !user) return;

      return deleteWorkspaceMember({
        workspaceId: vars.workspaceId,
        userId: user.id,
      });
    },
  });

  const navigate = useNavigate();

  return (
    <Container>
      <Header>
        <h1>Workspaces</h1>
        <p>Select your current workspace, or go to settings to manage it.</p>
      </Header>
      <div className={styles.workspaceList}>
        {workspaces.map((workspace) => {
          const menuItems = [
            workspace.role !== 'O'
              ? {
                  content: <span className={styles.dangerText}>Leave workspace</span>,
                  onClick: () => {
                    leaveWorkspaceConfirmModal.open({
                      workspaceId: workspace.id,
                    });
                  },
                  disabled: workspace.isFavorite,
                }
              : null,
          ].filter(exists);

          return (
            <div
              key={workspace.id}
              className={clsx(
                styles.workspaceRow,
                menuItems.length > 0 && styles.hasMenu
              )}
            >
              <div className={styles.details}>
                <Link to={urlFor('workspaceHome', { workspaceId: workspace.id })}>
                  <WorkspaceAvatar
                    workspaceName={workspace.label}
                    workspaceAvatar={workspace.avatarUrl}
                  />
                </Link>
                <div>
                  <h3>
                    <Link to={urlFor('workspaceHome', { workspaceId: workspace.id })}>
                      {workspace.label}
                    </Link>
                  </h3>
                  <p>{ROLE_NAMES[workspace.role]}</p>
                </div>
              </div>
              <div className={styles.select}>
                <Button
                  disabled={workspace.isFavorite || patchWorkspaceStatus === 'pending'}
                  className={
                    patchWorkspaceStatus === 'pending' ? styles.pending : undefined
                  }
                  onClick={() => {
                    patchWorkspace({
                      workspaceId: workspace.id,
                      patch: {
                        isFavorite: true,
                      },
                    });
                  }}
                  size="sm"
                  variant="outlined"
                >
                  {workspace.isFavorite ? 'Default' : 'Make Default'}
                </Button>
              </div>
              <div className={styles.settings}>
                <Button
                  onClick={() => {
                    navigate(
                      urlFor('workspaceSettings', {
                        workspaceId: workspace.id,
                      })
                    );
                  }}
                  size="sm"
                  variant="outlined"
                >
                  Settings
                </Button>
              </div>
              <div className={styles.leave}>
                {menuItems.length > 0 && (
                  <RecursiveDropdownMenu
                    dropdownMenuProps={{
                      align: 'end',
                      isPadded: true,
                      width: 200,
                    }}
                    items={menuItems}
                  >
                    <Button variant="icon" className={styles.dropdownMenuTrigger}>
                      <Menu />
                    </Button>
                  </RecursiveDropdownMenu>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

const NoWorkspaces = () => {
  const destroySession = useDestroySession();

  const { theme } = useContext(ThemeContext);
  const iconProps =
    theme === 'light'
      ? {
          topStroke: '#4B4E68',
          leftStroke: '#B5B7CA',
          rightStroke: '#7A7E9F',
        }
      : {};

  return (
    <Container className={styles.fullContainer}>
      <NoEntries className={styles.noWorkspaces} icon={<Organization {...iconProps} />}>
        <h1>Workspaces</h1>
        <p>
          You need a workspace to use the platform. Workspaces are essential for
          conducting research, managing spaces, collaboration, and more.
        </p>
        <div className={styles.buttons}>
          <ButtonLink size="sm" to={urlFor('newUserOnboarding')} variant="primary">
            Create a workspace
          </ButtonLink>
          <Button onClick={destroySession} size="sm" variant="outlined">
            Sign out
          </Button>
        </div>
      </NoEntries>
    </Container>
  );
};
