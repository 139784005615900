import { CreateCategoryModal } from '@components/modals/ManageCategoryModal';
import { useModalManager } from '@context/ModalManagerContext';

export function useCreateCategoryModal() {
  const { openModal, closeModal } = useModalManager();
  return {
    open: (projectId: string) => {
      openModal({
        component: (
          <CreateCategoryModal closeModal={closeModal} projectId={projectId} />
        ),
      });
    },
    close: closeModal,
  };
}
