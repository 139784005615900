const SECONDS_PER_MINUTE = 60;
const SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE;

const paddedNumber = (value: number | string, digits = 2) =>
  `${value}`.padStart(digits, '0');

export const humanizeSeconds = (seconds: number) => {
  const hours = Math.floor(seconds / SECONDS_PER_HOUR);
  let remainder = seconds % SECONDS_PER_HOUR;
  const minutes = Math.floor(remainder / SECONDS_PER_MINUTE);
  remainder = seconds % SECONDS_PER_MINUTE;

  const [a, b] = remainder.toFixed(2).split('.');

  return `${paddedNumber(hours)}:${paddedNumber(minutes)}:${paddedNumber(a!)}.${b}`;
};
