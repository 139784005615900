import { useEffect, useMemo, useRef, useState } from 'react';
import { camelCase } from 'lodash';
import { useParams } from 'react-router';
import { FloatingPortal } from '@floating-ui/react';
import { offset, useFloating } from '@floating-ui/react-dom';

import type {
  AiSettingsContentType,
  AiSettingsWritingStyle,
  AiSettingsLanguage,
  MediaGroupDTO,
  MediaGroupAiSettings,
} from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';
import { ContentType as ContentTypeIcon } from '@components/icons';
import MediaGroupPreviewList from '@components/MediaGroupPreviewList';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { useFileUploadWrapper } from '@hooks/useFileUploadWrapper';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Button from '@ui/Button';
import Checkbox from '@ui/Checkbox';
import Select, { Option, type SelectProps } from '@ui/Select';
import Spinner from '@ui/Spinner';
import { urlFor } from '@/urls';
import Row from './components/Row';
import Container from './components/Container';
import FieldRow from './components/FieldRow';
import Section from './components/Section';
import styles from './AISettings.module.scss';
import {
  useAddResourceMediaGroupAiSettings,
  useRemoveResourceMediaGroupAiSettings,
  useMediaGroupAiSettings,
  usePatchMediaGroupAiSettings,
} from '@/api/ai';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import { useMediaGroupSummary } from '@/api/mediaGroup';
import { asMilliseconds } from '@spaceduck/utils';

const { ArrowBack, Delete, Search } = Icon16;
const ACCEPTED_MIME_TYPES = ['application/pdf'].join(',');

const contentTypes: AiSettingsContentType[] = [
  'essay',
  'literature review',
  'research paper',
  'personal statement',
  'article or blog',
  'speech',
  'grant proposal',
] as const;
const contentTypeOptions: Record<AiSettingsContentType, string> = {
  essay: 'Essay',
  'literature review': 'Literature review',
  'research paper': 'Research paper',
  'personal statement': 'Personal statement',
  'article or blog': 'Articles / Blog',
  speech: 'Speech',
  'grant proposal': 'Grant proposal',
};

function getContentTypeDisplayName(value: AiSettingsContentType) {
  switch (value) {
    case 'article or blog':
      return 'Article';
    case 'grant proposal':
      return 'Grant';
    case 'literature review':
      return 'Literature';
    case 'personal statement':
      return 'Personal';
    case 'research paper':
      return 'Research';
    default:
      return contentTypeOptions[value];
  }
}

const writingStyles: AiSettingsWritingStyle[] = [
  'academic',
  'bold',
  'business',
  'casual',
  'friendly',
  'persuasive',
] as const;

const writingStyleOptions: Record<AiSettingsWritingStyle, string> = {
  academic: 'Academic',
  bold: 'Bold',
  casual: 'Casual',
  friendly: 'Friendly',
  persuasive: 'Persuasive',
  business: 'Business',
};

const languages: AiSettingsLanguage[] = ['EN_US', 'EN_GB'] as const;

const languageOptions: Record<AiSettingsLanguage, string> = {
  EN_US: 'English - US',
  EN_GB: 'English - UK',
};

export default function AiSettingsPanel({ mediaGroup }: { mediaGroup: MediaGroupDTO }) {
  const mediaGroupId = mediaGroup.id;
  const { data } = useMediaGroupAiSettings(mediaGroupId);
  if (data) {
    return <AISettings settings={data.settings} mediaGroup={mediaGroup} />;
  }
  return <LoadingPlaceholder />;
}

function AISettings({
  mediaGroup,
  settings,
}: {
  mediaGroup: MediaGroupDTO;
  settings: MediaGroupAiSettings;
}) {
  const mediaGroupId = mediaGroup.id;
  const { mutateAsync: updateSettings } = usePatchMediaGroupAiSettings(mediaGroupId);
  const makeId = (label: string) => `field-${mediaGroupId}-${camelCase(label)}`;
  const selectProps: Pick<SelectProps, 'placeholder'> & Partial<SelectProps> = {
    placeholder: 'Select',
    className: styles.select,
    contentClassName: styles.selectContent,
    textAlign: 'left',
    variant: 'primary',
  };

  const [documentPrompt, setDocumentPrompt] = useState(settings.documentPrompt);
  const [contentType, setContentType] = useState<AiSettingsContentType>(
    settings.contentType
  );
  const [writingStyle, setWritingStyle] = useState<AiSettingsWritingStyle>(
    settings.writingStyle
  );
  const [language, setLanguage] = useState<AiSettingsLanguage>(settings.language);
  const [hasAutoCite, setHasAutoCite] = useState(settings.autoCite);

  return (
    <Container title="AI Settings" className={styles.container}>
      <Section>
        <FieldRow label={<label htmlFor={makeId('prompt')}>Document prompt</label>}>
          <textarea
            id={makeId('prompt')}
            placeholder="Writing topic description to help with context..."
            onChange={(ev) => {
              setDocumentPrompt(ev.currentTarget.value);
            }}
            onBlur={(ev) => {
              updateSettings({ documentPrompt: ev.currentTarget.value });
            }}
            value={documentPrompt}
          />
        </FieldRow>
        <FieldRow
          columns={2}
          label={<label htmlFor={makeId('type')}>Content type</label>}
        >
          <Select
            {...selectProps}
            displayValue={<Option label={getContentTypeDisplayName(contentType)} />}
            onValueChange={(value) => {
              setContentType(value as AiSettingsContentType);
              updateSettings({ contentType: value as AiSettingsContentType });
            }}
            selectGroups={[
              {
                options: contentTypes.map((value) => ({
                  label: contentTypeOptions[value],
                  value,
                })),
              },
            ]}
            triggerId={makeId('type')}
            value={contentType}
          />
        </FieldRow>
        <FieldRow
          columns={2}
          label={<label htmlFor={makeId('level')}>Writing style</label>}
        >
          <Select
            {...selectProps}
            onValueChange={(value) => {
              setWritingStyle(value as AiSettingsWritingStyle);
              updateSettings({ writingStyle: value as AiSettingsWritingStyle });
            }}
            selectGroups={[
              {
                options: writingStyles.map((value) => ({
                  label: writingStyleOptions[value],
                  value,
                })),
              },
            ]}
            triggerId={makeId('level')}
            value={writingStyle}
          />
        </FieldRow>
        <FieldRow
          columns={2}
          label={<label htmlFor={makeId('language')}>Language</label>}
        >
          <Select
            {...selectProps}
            onValueChange={(value) => {
              setLanguage(value as AiSettingsLanguage);
              updateSettings({ language: value as AiSettingsLanguage });
            }}
            selectGroups={[
              {
                options: languages.map((value) => ({
                  label: languageOptions[value],
                  value,
                })),
              },
            ]}
            triggerId={makeId('language')}
            value={language}
          />
        </FieldRow>
        <FieldRow
          className={styles.alignLeft}
          columns={2}
          label={<label htmlFor={makeId('autoCite')}>Auto-cite</label>}
        >
          <Checkbox
            id={makeId('autoCite')}
            checked={hasAutoCite}
            hideCheckboxIcon
            layout="toggle"
            onChange={(ev) => {
              setHasAutoCite(ev.target.checked);
              updateSettings({ autoCite: ev.target.checked });
            }}
            size="sm"
            value="on"
          />
        </FieldRow>
      </Section>
      <WritingResources mediaGroupId={mediaGroupId} settings={settings} />
      <StyleReference mediaGroupId={mediaGroupId} settings={settings} />
    </Container>
  );
}

const ResourceBrowser = ({
  onAddClick,
  onGoBackClick,
  excludeMediaGroupIds,
}: {
  onAddClick: (mediaGroup: MediaGroupDTO) => void;
  onGoBackClick: () => void;
  excludeMediaGroupIds?: string[];
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const [searchTitle, setSearchTitle] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [onKeyDown, setOnKeyDown] = useState<(event: KeyboardEvent) => boolean>();
  const { refs, floatingStyles } = useFloating({
    middleware: [offset(10)],
    placement: 'top-end',
  });
  const { containerRef } = useOnClickOutside<HTMLDivElement>({
    callback: () => setShowPreview(false),
  });

  useEffect(() => {
    if (onKeyDown) {
      inputRef.current?.addEventListener('keydown', onKeyDown);
      return () => inputRef.current?.removeEventListener('keydown', onKeyDown);
    }
  }, [onKeyDown]);

  return (
    <div className={styles.resourceBrowser} ref={refs.setReference}>
      <Button
        className={styles.backButton}
        onClick={onGoBackClick}
        type="button"
        variant="outlined"
      >
        <ArrowBack />
      </Button>
      <div className={styles.searchBox}>
        <Search className={styles.icon} />
        <input
          onChange={(ev) => {
            setShowPreview(true);
            setSearchTitle(ev.currentTarget.value);
          }}
          onFocus={() => setShowPreview(true)}
          onKeyDown={(ev) => {
            if (ev.key === 'Escape') {
              ev.preventDefault();
              ev.stopPropagation();
              setShowPreview(false);
            }
          }}
          placeholder="Search library..."
          type="search"
          ref={inputRef}
        />
      </div>
      {showPreview && (
        <FloatingPortal>
          <div ref={containerRef}>
            <div
              className={styles.mediaGroupPreview}
              ref={refs.setFloating}
              style={floatingStyles}
            >
              <MediaGroupPreviewList
                handleSelectItem={(item) => {
                  onAddClick(item);
                  setShowPreview(false);
                }}
                searchTitle={searchTitle}
                setOnKeyDown={setOnKeyDown}
                defaultFilters={{
                  contentType: ['article', 'document', 'wiki', 'pdf'],
                }}
                excludeMediaGroupIds={excludeMediaGroupIds}
              />
            </div>
          </div>
        </FloatingPortal>
      )}
    </div>
  );
};

const ResourceListItem = ({
  mediaGroupId,
  onRemoveClick,
}: {
  mediaGroupId: string;
  onRemoveClick: (mediaGroupId?: string) => void;
}) => {
  const mediaGroupURL = urlFor('mediaGroup', {
    mediaGroupId,
  });
  const { data: mediaGroupData } = useMediaGroupSummary(mediaGroupId, (response) => {
    const isReady = response.state.data?.mediaGroup.isResearchAssistantReady ?? false;
    return isReady ? undefined : asMilliseconds({ seconds: 3 });
  });
  const ready = mediaGroupData?.mediaGroup.isResearchAssistantReady ?? false;

  const mediaGroup = mediaGroupData?.mediaGroup;
  if (!mediaGroup) {
    return null;
  }
  const { contentType, id, label } = mediaGroup;

  return (
    <div
      className={styles.resource}
      onClick={() => window.open(mediaGroupURL)}
      key={id}
    >
      <span>
        {!ready ? (
          <Spinner size={20} />
        ) : (
          <ContentTypeIcon contentType={contentType} size={20} />
        )}
      </span>
      <span className={styles.label}>
        <span>{!ready ? 'Loading...' : label}</span>
      </span>
      <span>
        <Button
          onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            onRemoveClick(mediaGroupId);
          }}
          type="button"
          variant="icon"
        >
          <Delete />
        </Button>
      </span>
    </div>
  );
};

const ResourceList = ({
  onRemoveClick,
  resources,
}: {
  onRemoveClick: (mediaGroupId?: string) => void;
  resources: Array<string>;
}) => {
  if (!resources.length) return null;

  return (
    <div>
      {resources.map((mediaGroupId: string) => (
        <ResourceListItem
          key={mediaGroupId}
          onRemoveClick={onRemoveClick}
          mediaGroupId={mediaGroupId}
        />
      ))}
    </div>
  );
};

const WritingResources = ({
  mediaGroupId,
  settings,
}: {
  mediaGroupId: string;
  settings: MediaGroupAiSettings;
}) => {
  const workspaceId = useWorkspaceId();
  const projectId = useParams<{ projectId?: string }>().projectId;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showWritingResourcesBrowser, setShowWritingResourcesBrowser] = useState(false);

  const {
    mutateAsync: addResource,
    variables: added,
    status: addStatus,
  } = useAddResourceMediaGroupAiSettings(mediaGroupId);
  const {
    mutateAsync: removeResource,
    variables: removed,
    status: removeStatus,
  } = useRemoveResourceMediaGroupAiSettings(mediaGroupId);

  const { handleChange } = useFileUploadWrapper(
    {
      workspaceId,
      projectId,
    },
    {
      onSuccess: async (mediaGroupId: string) => {
        addResource(mediaGroupId);
        if (fileInputRef.current) {
          (fileInputRef.current as HTMLInputElement).value = '';
        }
      },
    }
  );

  const writingResources = useMemo(() => {
    let writingResources = settings.writingResources.map((r) => r.id);
    if (addStatus === 'pending' && writingResources.indexOf(added) < 0) {
      writingResources.push(added);
    }

    if (removeStatus === 'pending') {
      writingResources = writingResources.filter((r) => r !== removed);
    }
    return writingResources;
  }, [settings.writingResources, added, removed]);

  return (
    <Section>
      <Row
        title="Writing resources"
        info={
          <p>
            Upload resources to provide the AI with content to reference directly in its
            writing. The AI will pull relevant information from these resources and cite
            them in the generated text.
          </p>
        }
      >
        {writingResources.length ? (
          <ResourceList
            onRemoveClick={(mediaGroupId?: string) => {
              if (mediaGroupId) {
                removeResource(mediaGroupId);
              }
            }}
            resources={writingResources}
          />
        ) : (
          <p>
            Upload documents or files that contain information you'd like the AI to use
            directly in its writing. The AI will extract relevant content from these
            resources and include citations as needed.
          </p>
        )}
        {showWritingResourcesBrowser ? (
          <ResourceBrowser
            onAddClick={(mediaGroup: MediaGroupDTO) => addResource(mediaGroup.id)}
            onGoBackClick={() => setShowWritingResourcesBrowser(false)}
            excludeMediaGroupIds={writingResources}
          />
        ) : (
          <div className={styles.buttons}>
            <Button
              onClick={() => setShowWritingResourcesBrowser(true)}
              size="sm"
              variant="outlined"
            >
              Add from library
            </Button>
            <Button
              onClick={() => fileInputRef.current?.click()}
              size="sm"
              variant="outlined"
            >
              Upload
            </Button>
          </div>
        )}
        <input
          accept={ACCEPTED_MIME_TYPES}
          onChange={handleChange}
          ref={fileInputRef}
          style={{ display: 'none' }}
          type="file"
        />
      </Row>
    </Section>
  );
};

const StyleReference = ({
  mediaGroupId,
  settings,
}: {
  mediaGroupId: string;
  settings: MediaGroupAiSettings;
}) => {
  const {
    mutateAsync: updateSettings,
    variables,
    status,
  } = usePatchMediaGroupAiSettings(mediaGroupId);

  const styleReference = useMemo(() => {
    if (
      status === 'pending' &&
      settings.writingReference?.id !== variables.writingReference
    ) {
      return variables.writingReference;
    }
    return settings.writingReference?.id;
  }, [variables?.writingReference, settings.writingReference?.id]);

  const workspaceId = useWorkspaceId();
  const projectId = useParams<{ projectId?: string }>().projectId;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isProcessingUpload, setIsProcessingUpload] = useState(false);
  const { handleChange } = useFileUploadWrapper(
    {
      workspaceId,
      projectId,
    },
    {
      onError: () => {
        setIsProcessingUpload(false);
      },
      onSuccess: async () => {
        if (fileInputRef.current) {
          (fileInputRef.current as HTMLInputElement).value = '';
        }
        setIsProcessingUpload(false);
      },
    }
  );
  const [showStyleReferenceBrowser, setShowStyleReferenceBrowser] = useState(false);

  const removeStyleReference = () => {
    updateSettings({ writingReference: null });
  };

  return (
    <Section>
      <Row
        title="Style reference"
        info={
          <p>
            Upload a reference document to guide the AI's writing style. The AI will use
            this file as a reference to match tone, structure, and content style in its
            responses.
          </p>
        }
      >
        {styleReference ? (
          <ResourceList
            onRemoveClick={removeStyleReference}
            resources={[styleReference]}
          />
        ) : (
          <p>
            Upload a document or PDF that reflects your preferred writing style, tone,
            or specific content you want the AI to consider when generating responses.
            This helps the AI create content that's aligned with your voice and format.
          </p>
        )}
        {!styleReference && (
          <div>
            {showStyleReferenceBrowser ? (
              <ResourceBrowser
                onAddClick={(mediaGroup: MediaGroupDTO) => {
                  updateSettings({ writingReference: mediaGroup.id });
                  setShowStyleReferenceBrowser(false);
                }}
                onGoBackClick={() => setShowStyleReferenceBrowser(false)}
              />
            ) : (
              <div className={styles.buttons}>
                <Button
                  disabled={isProcessingUpload}
                  onClick={() => setShowStyleReferenceBrowser(true)}
                  size="sm"
                  variant="outlined"
                >
                  Add from library
                </Button>
                <Button
                  disabled={isProcessingUpload}
                  onClick={() => fileInputRef.current?.click()}
                  size="sm"
                  variant="outlined"
                >
                  Upload
                </Button>
              </div>
            )}
          </div>
        )}
        <input
          accept={ACCEPTED_MIME_TYPES}
          onChange={(ev) => {
            setIsProcessingUpload(true);
            handleChange(ev);
          }}
          ref={fileInputRef}
          style={{ display: 'none' }}
          type="file"
        />
      </Row>
    </Section>
  );
};
