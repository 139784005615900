import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';

import type { Tag, TagCapability } from '@spaceduck/api';
import { Icon24, Icon64 } from '@spaceduck/icons';

import { useCreateTag, useDeleteTag, usePatchTag, useTags } from '@api/tags';
import NoEntries from '@components/NoEntries';
import { useModalManager } from '@context/ModalManagerContext';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { EditMenu, Search } from '@pages/common';
import Button from '@ui/Button';
import { useConfirmModal } from '@ui/ConfirmModal';
import Dialog from '@ui/Dialog';
import { DropdownMenuItem } from '@ui/DropdownMenu';
import createToast from '@utils/createToast';
import styles from './TagManager.module.scss';
import { Container, Header, SettingsError, TopNav } from './common';

const { Add, ProjectCategory } = Icon24;
const { Tags } = Icon64;

type TagFormData = {
  label: string;
};

const CreateTagModel = ({ closeModal }: { closeModal: () => void }) => {
  const workspaceId = useWorkspaceId();
  const { mutateAsync: createTag } = useCreateTag();

  const onSubmit = async (data: TagFormData) => {
    if (!workspaceId) {
      console.error('Failed to create tag due to missing workspaceId');
      createToast({
        titleText: 'Error creating tag',
        bodyText: 'Please try again later.',
        iconVariant: 'warning',
      });
      return;
    }
    const { created, tag } = await createTag({
      workspaceId,
      label: data.label,
      description: '',
    });
    closeModal();

    if (created) {
      createToast({
        titleText: 'Tag added successfully',
        bodyText: `Added "${tag.label}" as a new tag.`,
        iconVariant: 'success',
      });
    } else {
      createToast({
        titleText: 'Tag already exists',
        bodyText: `The tag name "${tag.label}" already exists and was not added; tags must be unique.`,
        iconVariant: 'warning',
      });
    }
  };

  return (
    <CreateOrEditForm
      closeModal={closeModal}
      heading="New tag"
      onSubmit={onSubmit}
      saveText="Add"
    />
  );
};

const EditTagModel = ({
  tag,
  closeModal,
}: {
  tag: Tag;
  closeModal: () => void;
}) => {
  const { mutateAsync: patchTag } = usePatchTag();
  const onSubmit = async (data: TagFormData) => {
    await patchTag({ tagId: tag.id, patch: data });
    closeModal();
    createToast({
      titleText: 'Tag edited successfully',
      bodyText: `Tag name was changed to "${data.label}".`,
      iconVariant: 'success',
    });
  };

  return <CreateOrEditForm tag={tag} onSubmit={onSubmit} closeModal={closeModal} />;
};

const CreateOrEditForm = ({
  closeModal,
  heading = 'Edit tag',
  onSubmit,
  saveText = 'Save',
  tag,
}: {
  closeModal: () => void;
  heading?: string;
  onSubmit: (data: TagFormData) => void;
  saveText?: string;
  tag?: Tag;
}) => {
  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
    setFocus,
  } = useForm<TagFormData>();

  const onOpenAutoFocus = (ev: Event) => {
    ev.preventDefault();
    setFocus('label');
  };

  return (
    <Dialog
      className={styles.tagModal}
      closeModal={closeModal}
      headerPadding={0}
      isOpen={true}
      maxWidth="32.5rem"
      modalHeading={heading}
      onOpenAutoFocus={onOpenAutoFocus}
      padding="lg"
    >
      <form
        className={styles.tagForm}
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
          reset();
        })}
      >
        <div className="formGroup">
          <label htmlFor="tagLabel">Tag name</label>
          <input
            defaultValue={tag?.label}
            id="tagLabel"
            type="text"
            {...register('label', {
              required: 'Tag name cannot be empty',
              setValueAs: (value: string) => value.trim().toLowerCase(),
            })}
          />
        </div>
        {errors?.label?.message && (
          <p className="errorMessage fieldError">{errors.label.message}</p>
        )}
        <div className={styles.tagFormFooter}>
          <Button size="sm" type="button" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="sm" type="submit" variant="primary">
            {saveText}
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export function useCreateTagModel() {
  const { openModal, closeModal } = useModalManager();
  return {
    open: () => {
      openModal({
        component: <CreateTagModel closeModal={closeModal} />,
      });
    },
    close: closeModal,
  };
}

export function useEditTagModel() {
  const { openModal, closeModal } = useModalManager();
  return {
    open: (tag: Tag) => {
      openModal({
        component: <EditTagModel tag={tag} closeModal={closeModal} />,
      });
    },
    close: closeModal,
  };
}

type TagTableProps = {
  tags: Tag[];
  lastRef: ReturnType<typeof useInView>['ref'];
  capabilities: Set<TagCapability>;
};

const TagTable = ({ tags, lastRef, capabilities }: TagTableProps) => {
  const lastIndex = tags.length - 1;

  const { mutateAsync: deleteTag } = useDeleteTag();

  const editModal = useEditTagModel();
  const confirmDeleteModal = useConfirmModal<{ id: string }>({
    title: 'Delete tag',
    subtitle: 'This action cannot be undone. This tag will be permanently deleted.',
    confirmVariant: 'danger',
    confirmText: 'Yes, delete tag',
    onConfirm: async (vars) => {
      if (!vars) return;
      const { id } = vars;
      const { label } = await deleteTag(id);
      createToast({
        titleText: 'Tag deleted successfully',
        bodyText: `Tag name "${label}" was deleted.`,
        iconVariant: 'success',
      });
    },
  });

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th className={styles.label}>Tag</th>
          <th className={styles.usageCount}>Usage</th>
          <th className={styles.actions} title="Actions" />
        </tr>
      </thead>
      <tbody>
        {tags.map((tag, index) => (
          <tr key={tag.id} ref={index === lastIndex ? lastRef : null}>
            <td className={styles.rowHeading}>
              <span>
                <ProjectCategory size={20} />
                {tag.label}
              </span>
            </td>
            <td>
              {tag.usageCount} {`item${tag.usageCount === 1 ? '' : 's'}`}
            </td>
            <td>
              <EditMenu>
                <DropdownMenuItem
                  onSelect={() => editModal.open(tag)}
                  disabled={!capabilities.has('edit')}
                >
                  Edit
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => confirmDeleteModal.open({ id: tag.id })}
                  disabled={!capabilities.has('delete')}
                >
                  Delete
                </DropdownMenuItem>
              </EditMenu>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const TagManager = ({
  setAllowTagCreate,
}: {
  setAllowTagCreate: (value: boolean) => void;
}) => {
  const { ref, inView } = useInView();
  const [searchQuery, setSearchQuery] = useState('');
  const workspaceId = useWorkspaceId();
  const {
    data: paginator,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    status,
  } = useTags(workspaceId, searchQuery);
  const pageCount = paginator?.pages.length ?? 0;
  const debouncedSetSearchQuery = useCallback(debounce(setSearchQuery, 400), []);
  const lastPage = paginator?.pages.at(-1);
  const allowTagCreate = (lastPage?.capabilities?.indexOf('create') ?? -1) >= 0;

  useEffect(() => {
    setAllowTagCreate(allowTagCreate);
  }, [allowTagCreate]);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, pageCount, fetchNextPage, isFetchingNextPage]);

  if (status === 'error') {
    return (
      <Container>
        <PageHeader />
        <SettingsError />
      </Container>
    );
  }

  if (lastPage?.total === 0) {
    return <NoTags />;
  }

  const tags = paginator?.pages.flatMap((page) => page.tags);

  return (
    <Container>
      <PageHeader />
      <Search
        defaultValue={searchQuery}
        onInput={(e) => debouncedSetSearchQuery(e.currentTarget.value)}
        placeholder="Search tags..."
        status={status}
      />
      {status === 'pending' ? (
        <div className={styles.statusMessage}>Searching...</div>
      ) : (
        <>
          {tags?.length ? (
            <>
              <TagTable
                tags={tags}
                lastRef={ref}
                capabilities={new Set(lastPage?.capabilities)}
              />
              {isFetchingNextPage && (
                <div className={styles.statusMessage}>Loading more...</div>
              )}
            </>
          ) : (
            <div className={styles.statusMessage}>No matches found.</div>
          )}
        </>
      )}
    </Container>
  );
};

export default function TagManagerPage() {
  const createModal = useCreateTagModel();
  const [allowTagCreate, setAllowTagCreate] = useState(false);

  return (
    <>
      <TopNav
        currentBreadcrumb="Tags"
        buttonIsDisabled={!allowTagCreate}
        buttonOnClick={() => createModal.open()}
        buttonText="New tag"
        owner="workspace"
        showAddIcon
        title="Manage Tags"
      />
      <TagManager setAllowTagCreate={setAllowTagCreate} />
    </>
  );
}

const NoTags = () => {
  const createModal = useCreateTagModel();

  return (
    <NoEntries className={styles.noEntries} icon={<Tags />} pageType="settings">
      <h1>No tags yet</h1>
      <p>
        Tags help you organize, manage, and simplify the searchability of your content.
      </p>
      <Button
        onClick={() => createModal.open()}
        iconBefore={<Add />}
        size="sm"
        variant="primary"
      >
        New tag
      </Button>
    </NoEntries>
  );
};

const PageHeader = () => {
  return (
    <Header>
      <h1>Tags</h1>
      <p>Manage your workspace tags</p>
    </Header>
  );
};
