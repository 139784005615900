export default function Icon({
  color = 'currentColor',
  width,
  height,
}: {
  color?: string;
  width?: number;
  height?: number;
}) {
  const displayWidth = width ?? (height ? (height * 104) / 18 : 104);
  const displayHeight = height ?? (width ? (width * 18) / 104 : 18);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={displayWidth}
      height={displayHeight}
      viewBox="0 0 104 18"
      fill="none"
    >
      <path
        d="M101.31 14.3191C101.2 14.3191 101.098 14.2667 101.034 14.1783L97.7049 9.54607L96.2635 10.9011V13.982C96.2635 14.1682 96.1121 14.3191 95.9252 14.3191H94.3891C94.2023 14.3191 94.0508 14.1682 94.0508 13.982V0.337079C94.0508 0.150915 94.2023 0 94.3891 0H95.9252C96.1121 0 96.2635 0.150915 96.2635 0.337079V7.98876L100.409 4.2141C100.472 4.15732 100.553 4.12584 100.638 4.12584H102.617C102.923 4.12584 103.072 4.49917 102.849 4.70838L99.3289 8.00899L103.437 13.7872C103.596 14.0104 103.436 14.3191 103.161 14.3191H101.31Z"
        fill={color}
      />
      <path
        d="M87.4967 14.481C84.5126 14.481 82.3404 12.2562 82.3404 9.20231C82.3404 6.16861 84.4923 3.96411 87.4967 3.96411C90.0237 3.96411 91.7202 5.29498 92.1607 7.34618C92.2036 7.54561 92.0448 7.72591 91.8401 7.72591H90.2721C90.1103 7.72591 89.9738 7.61074 89.9244 7.45733C89.6095 6.47953 88.6901 5.86524 87.4967 5.86524C85.8524 5.86524 84.5938 7.24052 84.5938 9.20231C84.5938 11.1843 85.8524 12.5798 87.4967 12.5798C88.7641 12.5798 89.7063 11.9321 90.0093 10.9339C90.0568 10.7772 90.1947 10.6585 90.3591 10.6585H91.9179C92.1238 10.6585 92.2828 10.8407 92.2354 11.0403C91.7738 12.9821 89.9756 14.481 87.4967 14.481Z"
        fill={color}
      />
      <path
        d="M74.6187 14.4809C72.4263 14.4809 70.8225 13.0854 70.8225 10.6382V4.46293C70.8225 4.27677 70.974 4.12585 71.1609 4.12585H72.7172C72.9041 4.12585 73.0556 4.27677 73.0556 4.46293V10.2337C73.0556 11.6899 73.8879 12.5393 75.1871 12.5393C76.9736 12.5393 78.0495 11.0023 78.0495 8.51462V4.46293C78.0495 4.27677 78.201 4.12585 78.3878 4.12585H79.9442C80.1311 4.12585 80.2826 4.27677 80.2826 4.46293V13.982C80.2826 14.1682 80.1311 14.3191 79.9442 14.3191H78.3878C78.201 14.3191 78.0495 14.1682 78.0495 13.982V12.7416C77.4811 13.7326 76.2428 14.4809 74.6187 14.4809Z"
        fill={color}
      />
      <path
        d="M63.1099 14.4809C60.0851 14.4809 58.0551 12.418 58.0551 9.22247C58.0551 6.02697 60.0851 3.96404 63.1099 3.96404C64.5106 3.96404 65.8302 4.59101 66.3986 5.54157V0.337079C66.3986 0.150916 66.5501 0 66.7369 0H68.2933C68.4802 0 68.6316 0.150915 68.6316 0.337079V13.982C68.6316 14.1682 68.4802 14.3191 68.2933 14.3191H66.7369C66.5501 14.3191 66.3986 14.1682 66.3986 13.982V12.8831C65.8302 13.8539 64.4903 14.4809 63.1099 14.4809ZM60.3287 9.22247C60.3287 11.2045 61.5874 12.5798 63.3535 12.5798C65.1399 12.5798 66.3986 11.1843 66.3986 9.22247C66.3986 7.22022 65.1399 5.86517 63.3535 5.86517C61.5874 5.86517 60.3287 7.22022 60.3287 9.22247Z"
        fill={color}
      />
      <path
        d="M51.9919 14.481C48.8656 14.481 46.7747 12.2967 46.7747 9.22254C46.7747 6.14838 48.9265 3.96411 51.8701 3.96411C55.0163 3.96411 56.9816 6.31708 56.7328 9.33203C56.7189 9.50105 56.5741 9.62703 56.4039 9.62703H49.0077C49.1498 11.5282 50.307 12.6203 51.9513 12.6203C53.1379 12.6203 53.9519 12.0974 54.2852 11.2672C54.342 11.1258 54.4732 11.0225 54.626 11.0225H56.1962C56.4068 11.0225 56.567 11.2128 56.5109 11.415C56.0032 13.2443 54.3256 14.481 51.9919 14.481ZM49.1295 7.94838H54.4077C54.3874 6.59332 53.352 5.74389 51.8701 5.74389C50.4288 5.74389 49.4543 6.4922 49.1295 7.94838Z"
        fill={color}
      />
      <path
        d="M40.8094 14.481C37.8252 14.481 35.653 12.2562 35.653 9.20231C35.653 6.16861 37.8049 3.96411 40.8094 3.96411C43.3363 3.96411 45.0328 5.29498 45.4734 7.34618C45.5162 7.54561 45.3574 7.72591 45.1527 7.72591H43.5847C43.4229 7.72591 43.2864 7.61074 43.237 7.45733C42.9221 6.47953 42.0027 5.86524 40.8094 5.86524C39.165 5.86524 37.9064 7.24052 37.9064 9.20231C37.9064 11.1843 39.165 12.5798 40.8094 12.5798C42.0767 12.5798 43.0189 11.9321 43.3219 10.9339C43.3695 10.7772 43.5074 10.6585 43.6717 10.6585H45.2305C45.4364 10.6585 45.5955 10.8407 45.548 11.0403C45.0865 12.9821 43.2882 14.481 40.8094 14.481Z"
        fill={color}
      />
      <path
        d="M28.6032 14.481C26.4311 14.481 25.0303 13.2877 25.0303 11.5282C25.0303 9.50568 26.4311 8.454 29.598 8.11018L31.6686 7.90793V7.64501C31.6686 6.45175 30.816 5.74389 29.5168 5.74389C28.4012 5.74389 27.6262 6.28676 27.3789 7.29482C27.3387 7.45866 27.198 7.58434 27.0287 7.58434H25.4808C25.2751 7.58434 25.116 7.40249 25.1626 7.20288C25.5882 5.38054 27.1615 3.96411 29.5168 3.96411C32.0746 3.96411 33.9017 5.40007 33.9017 7.90793V13.9821C33.9017 14.1683 33.7502 14.3192 33.5633 14.3192H32.007C31.8201 14.3192 31.6686 14.1683 31.6686 13.9821V12.8428C31.2423 13.7326 29.9837 14.481 28.6032 14.481ZM27.2431 11.427C27.2431 12.236 27.913 12.7214 28.9686 12.7214C30.5927 12.7214 31.6686 11.4473 31.6686 9.82928V9.54613L29.6995 9.78883C27.9739 9.99108 27.2431 10.4158 27.2431 11.427Z"
        fill={color}
      />
      <path
        d="M13.3455 18.0001C13.1586 18.0001 13.0071 17.8491 13.0071 17.663V4.46299C13.0071 4.27682 13.1586 4.12591 13.3455 4.12591H14.9019C15.0887 4.12591 15.2402 4.27682 15.2402 4.46299V5.54164C15.8289 4.59108 17.1282 3.96411 18.5289 3.96411C21.574 3.96411 23.5837 6.02703 23.5837 9.22254C23.5837 12.418 21.574 14.481 18.5289 14.481C17.1485 14.481 15.8289 13.854 15.2402 12.8832V17.663C15.2402 17.8491 15.0887 18.0001 14.9019 18.0001H13.3455ZM15.2402 9.22254C15.2402 11.1843 16.4988 12.5798 18.2853 12.5798C20.0514 12.5798 21.3304 11.2046 21.3304 9.22254C21.3304 7.22029 20.0514 5.86524 18.2853 5.86524C16.4988 5.86524 15.2402 7.22029 15.2402 9.22254Z"
        fill={color}
      />
      <path
        d="M6.04583 14.4809C3.08041 14.4809 0.902449 12.8932 0.502941 9.95114C0.476414 9.75579 0.632818 9.58652 0.830691 9.58652H2.49314C2.66629 9.58652 2.80996 9.71759 2.83999 9.88748C3.14363 11.6051 4.41294 12.4382 6.04583 12.4382C7.60897 12.4382 8.7052 11.6697 8.7052 10.4764C8.7052 7.13932 0.909787 8.97977 0.909787 3.88314C0.909787 1.53708 2.96014 0 5.68042 0C8.51907 0 10.4025 1.42742 10.8591 3.82965C10.8969 4.02862 10.7387 4.20674 10.5354 4.20674H8.8624C8.70039 4.20674 8.56324 4.09144 8.51771 3.93653C8.16749 2.74484 7.11777 2.0427 5.68042 2.0427C4.21878 2.0427 3.28495 2.71011 3.28495 3.78202C3.28495 6.65393 11.1007 4.85393 11.1007 10.3348C11.1007 12.8427 8.98941 14.4809 6.04583 14.4809Z"
        fill={color}
      />
    </svg>
  );
}
