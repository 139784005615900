import { clsx } from 'clsx';

import OptionalLink from '@components/ui/OptionalLink';
import styles from './Breadcrumb.module.scss';

export type BreadcrumbItem = {
  children?: React.ReactElement;
  icon?: React.ReactElement;
  text?: string;
  url?: string;
};

type BreadcrumbProps = {
  breadcrumb: BreadcrumbItem[];
  className?: string;
  truncate?: boolean;
  nav?: boolean;
  showTitle?: boolean;
};

export default function Breadcrumb({
  breadcrumb,
  className,
  truncate = false,
  nav = true,
  showTitle = false,
}: BreadcrumbProps) {
  const NavElement = nav ? 'nav' : 'div';

  return (
    <NavElement
      className={clsx(styles.breadcrumb, truncate && styles.truncate, className)}
      aria-label="Breadcrumb"
    >
      <ul>
        {breadcrumb.map(({ children, icon, text, url }, idx) => {
          return (
            <li key={idx} title={showTitle && text ? text : undefined}>
              {icon && <span className={styles.icon}>{icon}</span>}
              {text && (
                <OptionalLink href={url}>
                  <span className={styles.text}>{text}</span>
                </OptionalLink>
              )}
              {children}
            </li>
          );
        })}
      </ul>
    </NavElement>
  );
}
