import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { clsx } from 'clsx';

import Button from '@components/ui/Button';
import { Icon16 } from '@spaceduck/icons';
import styles from './Alert.module.scss';
const { Close } = Icon16;

type AlertProps = {
  children?: React.ReactNode;
  className?: string;
  description?: string;
  onClose?: () => void;
  isOpen?: boolean;
  title: string;
};

const Alert = ({
  children,
  className,
  description,
  isOpen,
  onClose,
  title,
}: AlertProps) => {
  return (
    <AlertDialog.Root open={isOpen}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className={clsx(styles.alertDialogOverlay)} />
        <AlertDialog.Content className={clsx(styles.alertDialogContent, className)}>
          {!title && (
            <VisuallyHidden asChild>
              <AlertDialog.Title>{title}</AlertDialog.Title>
            </VisuallyHidden>
          )}
          {!description && (
            <VisuallyHidden asChild>
              <AlertDialog.Description>Action required</AlertDialog.Description>
            </VisuallyHidden>
          )}
          <div className={styles.header}>
            <AlertDialog.Title className={clsx('title3', styles.alertDialogTitle)}>
              {title}
            </AlertDialog.Title>
            {onClose && (
              <Button variant="icon" onClick={() => onClose()}>
                <Close size={20} />
              </Button>
            )}
          </div>
          {description && (
            <AlertDialog.Description className="body5">
              {description}
            </AlertDialog.Description>
          )}
          {children}
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};

export default Alert;
