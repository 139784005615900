import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { clsx } from 'clsx';
import type { HTMLAttributes, ReactNode } from 'react';

import Shortcut, { MetaKey } from './Shortcut';
import styles from './Tooltip.module.scss';

type TooltipProps = {
  align?: 'center' | 'start' | 'end';
  children: ReactNode;
  content: ReactNode;
  defaultOpen?: boolean;
  delayDuration?: number;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
  portalProps?: TooltipPrimitive.TooltipPortalProps;
  props?: HTMLAttributes<HTMLDivElement>;
  side?: 'left' | 'right' | 'bottom' | 'top';
  size?: 'md' | 'lg';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'bare';
  shortKeys?: string[];
};

const tooltipSize = {
  lg: styles.tooltipLarge,
  md: styles.tooltipMedium,
};

export const tooltipVariant = {
  primary: styles.tooltipPrimary,
  secondary: styles.tooltipSecondary,
  tertiary: styles.tooltipTertiary,
  bare: undefined,
};

export default function Tooltip(tooltipProps: TooltipProps) {
  const { children, defaultOpen, delayDuration, onOpenChange, open, portalProps } =
    tooltipProps;

  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root
        defaultOpen={defaultOpen}
        delayDuration={delayDuration}
        onOpenChange={onOpenChange}
        open={open}
      >
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        {portalProps ? (
          <TooltipPrimitive.Portal {...portalProps}>
            <div>
              <TooltipContent {...tooltipProps} />
            </div>
          </TooltipPrimitive.Portal>
        ) : (
          <TooltipContent {...tooltipProps} />
        )}
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
}

const TooltipContent = ({
  align = 'center',
  children,
  content,
  defaultOpen,
  delayDuration = 0,
  // icons = [],
  onOpenChange,
  open,
  side = 'bottom',
  size = 'md',
  variant,
  portalProps,
  shortKeys,
  ...props
}: TooltipProps) => {
  const TooltipSize = tooltipSize[size ?? 'md'];
  const TooltipVariant = tooltipVariant[variant ?? 'primary'];

  return (
    <TooltipPrimitive.Content
      side={side}
      align={align}
      sideOffset={4}
      {...props}
      className={clsx(
        styles.tooltip,
        TooltipSize,
        TooltipVariant,
        shortKeys?.length && styles.hasShortKeys
      )}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.content}>{content}</div>
        {shortKeys?.map((key, idx) =>
          key === 'META' ? (
            <MetaKey className={styles.shortKey} shadow key={idx} isFixedWidth />
          ) : (
            <Shortcut className={styles.shortKey} shadow key={idx} isFixedWidth>
              {key}
            </Shortcut>
          )
        )}
      </div>
    </TooltipPrimitive.Content>
  );
};
