import type { MediaGroupDetailDTO } from '@spaceduck/api';
import { MediaType } from '@spaceduck/utils';

import PdfPreview from '@detailsModal/media/PdfPreview';

export const PdfView = ({
  className,
  mediaGroup,
}: {
  className?: string;
  mediaGroup: MediaGroupDetailDTO;
}) => {
  const media = mediaGroup.media;
  if (media?.mediaType !== MediaType.PDF) return null;

  const { assetName, assetUrl } = media;

  return (
    <div className={className}>
      <PdfPreview name={assetName} url={assetUrl} />
    </div>
  );
};
