import * as Popover from '@radix-ui/react-popover';
import { Icon24 } from '@spaceduck/icons';
import { clsx } from 'clsx';
import { type Ref, forwardRef } from 'react';

import { css } from '@/lib/css';
import Checkbox from '@ui/Checkbox';
import dropdownStyles from '@ui/DropdownMenu.module.scss';
import ScrollArea from '@ui/ScrollArea';
import styles from './TagSuggestions.module.scss';

const { Add, Tags } = Icon24;

type TagSuggestionsProps = {
  addNewTag: (tag: string) => void;
  handleTagClick: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  menuMaxHeight: number | null;
  menuMaxWidth?: number;
  optionsContainerRef?: Ref<HTMLDivElement>;
  results: string[];
  setShowTagsMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setTagInput: React.Dispatch<React.SetStateAction<string>>;
  showSelectedTags?: boolean;
  showTagsMenu: boolean;
  tagInput: string;
  tags: string[];
};

const TagSuggestions = forwardRef<HTMLDivElement, TagSuggestionsProps>(
  (
    {
      addNewTag,
      handleTagClick,
      menuMaxHeight,
      menuMaxWidth,
      optionsContainerRef,
      results,
      setShowTagsMenu,
      showTagsMenu,
      showSelectedTags = true,
      tagInput,
      tags,
    },
    ref
  ) => {
    const tagsInLowercase = tags.map((tag) => tag.toLowerCase());
    const renderedResults = results.filter((tag) => {
      if (showSelectedTags) return true;
      return !tagsInLowercase.includes(tag.toLowerCase());
    });
    const inputExists = !!results.find(
      (tag) => tag.toLowerCase() === tagInput.toLowerCase()
    );

    const hidePopover =
      tagInput.length > 0 &&
      tagsInLowercase.includes(tagInput.toLowerCase()) &&
      renderedResults.length < 2;

    return (
      <Popover.Root open={true} onOpenChange={setShowTagsMenu} modal={false}>
        <Popover.Trigger asChild>
          <div />
        </Popover.Trigger>
        <Popover.Anchor />
        <Popover.Portal>
          <Popover.Content
            align="start"
            avoidCollisions={false}
            className={clsx(
              styles.tagsMenu,
              showTagsMenu && styles.tagsMenuOpen,
              hidePopover && styles.hidden
            )}
            side="bottom"
            sideOffset={6}
            style={css({
              '--menu-width': menuMaxWidth
                ? `${menuMaxWidth}px`
                : 'var(--radix-popper-anchor-width)',
            })}
          >
            <ScrollArea
              className={styles.scrollArea}
              style={css({
                '--width': '100%',
                '--maxHeight':
                  menuMaxHeight && menuMaxHeight > 0 ? `${menuMaxHeight}px` : 0,
              })}
              type="auto"
            >
              <div className={clsx(dropdownStyles.dropdownMenuContent, styles.content)}>
                {renderedResults.length ? (
                  <div className={styles.options} ref={optionsContainerRef}>
                    {renderedResults.map((tag, idx) => (
                      <Checkbox
                        asMenuItem
                        checked={tags.includes(tag)}
                        className={styles.option}
                        icon={showSelectedTags ? undefined : <Tags size={20} />}
                        key={idx}
                        onChange={handleTagClick}
                        hideCheckboxIcon={!showSelectedTags}
                        tabIndex={showTagsMenu ? 0 : -1}
                        value={tag}
                      >
                        {tag}
                      </Checkbox>
                    ))}
                  </div>
                ) : (
                  <div className={styles.noResults}>No matching tags</div>
                )}
                {!inputExists && !!tagInput.trim() && (
                  <button className={styles.addNew} onClick={() => addNewTag(tagInput)}>
                    <Add size={20} />
                    <span>
                      add tag "<span className={styles.highlighted}>{tagInput}</span>"
                    </span>
                  </button>
                )}
              </div>
              <div ref={ref} />
            </ScrollArea>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    );
  }
);

export default TagSuggestions;
