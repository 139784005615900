import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import { useContext, useState } from 'react';

import { ThemeContext } from '@themes/Theme';
import Button from '@ui/Button';
import { ControlledDropdownMenu } from '@ui/DropdownMenu';
import styles from './EmojiButton.module.scss';

export function EmojiButton({
  emoji,
  count,
  onClick,
  active,
}: {
  emoji: string;
  count?: number;
  active?: boolean;
  onClick?: () => void;
}) {
  return (
    <Button
      className={clsx(styles.emojiButton, active && styles.active)}
      size="xs"
      variant="emoji"
      onClick={onClick}
    >
      {emoji} {count}
    </Button>
  );
}

export function EmojiPicker({
  onClick,
  containerRef,
  children,
  asTippy,
}: {
  children: React.ReactNode;
  onClick?: (emoji: string) => void;
  containerRef?: React.RefObject<HTMLElement>;
  asTippy?: boolean;
}) {
  const [open, setOpen] = useState(false);

  const onEmojiSelect = (emoji: { native: string }) => {
    setOpen(false);
    onClick?.(emoji.native);
  };

  if (asTippy) {
    return (
      <Tippy
        appendTo={containerRef?.current ?? 'parent'}
        content={
          <div className={styles.emojiPickerMenuTippy}>
            <StyledPicker onEmojiSelect={onEmojiSelect} />
          </div>
        }
        interactive={true}
        placement="bottom-start"
        visible={open}
        onClickOutside={() => setOpen(false)}
      >
        <span onClick={() => setOpen((state) => !state)}>{children}</span>
      </Tippy>
    );
  }

  return (
    <ControlledDropdownMenu
      open={open}
      onOpenChange={setOpen}
      align="center"
      modal={false}
      className={styles.emojiPickerMenu}
      container={containerRef?.current}
      triggerContent={children}
    >
      <StyledPicker onEmojiSelect={onEmojiSelect} />
    </ControlledDropdownMenu>
  );
}

const StyledPicker = ({
  onEmojiSelect,
}: {
  onEmojiSelect: (emoji: { native: string }) => void;
}) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Picker
      theme={theme}
      autoFocus={true}
      onEmojiSelect={onEmojiSelect}
      style={{
        width: '100%',
      }}
      emojiSize={18}
      perLine={7}
      showPreview={true}
      showSkinTones={true}
      data={emojiData}
    />
  );
};
