import clsx from 'clsx';
import prettyBytes from 'pretty-bytes';

import type { MediaDTO, MediaGroupDTO } from '@spaceduck/api';
import { MediaType } from '@spaceduck/utils';

import { FileIcon } from '@components/FileIcon';
import styles from './FileBlock.module.scss';

function getFileTypeLabel(mediaType: string) {
  switch (mediaType) {
    case MediaType.PDF:
      return 'PDF';
    case MediaType.ZIP:
      return 'Zip';
    default:
      return 'File';
  }
}

function getFileSize(size?: number) {
  if (!size) return null;

  return prettyBytes(size);
}

type Orientation = 'landscape' | 'portrait';

export default function FileBlock({
  className,
  mediaGroup,
  onDoubleClick,
  orientation = 'landscape',
}: {
  className?: string;
  mediaGroup?: MediaGroupDTO;
  onDoubleClick?: () => void;
  orientation?: Orientation;
}) {
  if (!mediaGroup) return null;
  if (!mediaGroup.media) return <NoMedia mediaGroup={mediaGroup} />;

  const isBookmark = mediaGroup.contentType === 'bookmark';
  const backupLabel =
    mediaGroup.thumbnail.source.kind === 'fileIcon'
      ? mediaGroup.thumbnail.source.label
      : undefined;

  return (
    <div className={clsx(styles.container, styles.single, className)}>
      <Media
        isBookmark={isBookmark}
        label={mediaGroup.label || (backupLabel ?? '')}
        media={mediaGroup.media}
        onDoubleClick={onDoubleClick}
        orientation={orientation}
      />
    </div>
  );
}

const NoMedia = ({ mediaGroup }: { mediaGroup: MediaGroupDTO }) => {
  const isBookmark = mediaGroup.contentType === 'bookmark';
  if (!isBookmark) return null;

  return (
    <div className={clsx(styles.container, styles.single)}>
      <div
        className={styles.media}
        onDoubleClick={() => {
          if (mediaGroup.linkUrl) {
            window.open(mediaGroup.linkUrl);
          }
        }}
      >
        <div className={styles.info}>
          <div className={styles.label}>{mediaGroup.linkUrlSource}</div>
          <div className={styles.fileInfo}>
            <span>Bookmark</span>
          </div>
        </div>
        <div className={styles.icon}>
          <FileIcon mediaType={MediaType.URI_LIST} />
        </div>
      </div>
    </div>
  );
};

const Media = ({
  isBookmark,
  label,
  media,
  onDoubleClick,
  orientation,
}: {
  isBookmark?: boolean;
  label: string;
  media: MediaDTO;
  onDoubleClick?: () => void;
  orientation?: Orientation;
}) => {
  const { assetUrl, mediaType, sizeBytes } = media;
  const isVideo = mediaType.startsWith('video/');

  let fileType = getFileTypeLabel(mediaType);
  if (isBookmark) {
    fileType = 'Bookmark';
  } else if (isVideo) {
    fileType = 'Video';
  }

  if (orientation === 'portrait') {
    return (
      <div
        className={clsx(styles.media, styles.portrait)}
        onDoubleClick={() => (onDoubleClick ? onDoubleClick() : window.open(assetUrl))}
      >
        <div className={styles.info}>
          <div className={styles.icon}>
            <FileIcon mediaType={isBookmark ? MediaType.URI_LIST : mediaType} />
          </div>
          <div className={styles.label}>{label}</div>
          <div className={styles.fileInfo}>
            <span>{fileType}</span>
            {sizeBytes && (
              <>
                •<span>{getFileSize(sizeBytes)}</span>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(styles.media, styles.landscape)}
      onDoubleClick={() => window.open(assetUrl)}
    >
      <div className={styles.info}>
        <div className={styles.label}>{label}</div>
        <div className={styles.fileInfo}>
          <span>{fileType}</span>
          {sizeBytes && (
            <>
              •<span>{getFileSize(sizeBytes)}</span>
            </>
          )}
        </div>
      </div>
      <div className={styles.icon}>
        <FileIcon mediaType={isBookmark ? MediaType.URI_LIST : mediaType} />
      </div>
    </div>
  );
};
