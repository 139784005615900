export const connectionLineColors = [
  'yellow',
  'amber',
  'orange',
  'red',
  'pink',
  'purple',
  'green',
  'sky',
  'blue',
  'neutral1',
  'neutral2',
  'neutral3',
] as const;

export type ConnectionLineColor = (typeof connectionLineColors)[number];

export const connectionLineColorsAsHex: Record<ConnectionLineColor, string> = {
  yellow: 'var(--react-flow-group-gold-border)',
  amber: 'var(--react-flow-group-roasted-sesame-border)',
  orange: 'var(--react-flow-group-peach-border)',
  red: 'var(--react-flow-group-red-border)',
  pink: 'var(--react-flow-group-pink-border)',
  purple: 'var(--react-flow-group-perry-winkle-border)',
  green: 'var(--react-flow-group-green-border)',
  sky: 'var(--react-flow-group-blue-border)',
  blue: 'var(--react-flow-group-lavender-border)',
  neutral1: 'var(--react-flow-group-neutral-1-border)',
  neutral2: 'var(--react-flow-group-neutral-2-border)',
  neutral3: 'var(--react-flow-group-neutral-3-border)',
};

export const nodeBorderColors = [
  'gold',
  'roastedSesame',
  'peach',
  'red',
  'pink',
  'perryWinkle',
  'green',
  'blue',
  'lavender',
  'neutral1',
  'neutral2',
  'neutral3',
] as const;

export type NodeBorderColor = (typeof nodeBorderColors)[number];
