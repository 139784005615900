export default function Icon({
  color = 'currentColor',
  width,
  height,
}: {
  color?: string;
  width?: number;
  height?: number;
}) {
  const displayWidth = width ?? (height ? (height * 33) / 32 : 33);
  const displayHeight = height ?? (width ? (width * 32) / 33 : 32);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={displayWidth}
      height={displayHeight}
      viewBox="0 0 33 32"
      fill="none"
    >
      <g clipPath="url(#clip0_2542_90)">
        <path
          d="M8.00367 4.12017C6.3479 4.6236 5.04091 5.95439 4.54648 7.64031C4.48132 7.86276 4.18236 7.86276 4.12104 7.64031C3.62661 5.95439 2.31962 4.6236 0.663852 4.12017C0.445383 4.05382 0.445383 3.74942 0.663852 3.68698C2.31962 3.18354 3.62661 1.85276 4.12104 0.166836C4.1862 -0.055612 4.48516 -0.055612 4.54648 0.166836C5.04091 1.85276 6.3479 3.18354 8.00367 3.68698C8.22214 3.75332 8.22214 4.05772 8.00367 4.12017Z"
          fill={color}
        />
        <path
          d="M32.5002 19.8125C32.5002 20.443 32.006 20.95 31.3913 20.95H26.4678C26.1953 26.9885 20.8725 32 14.5992 32C8.32598 32 2.71806 26.7155 2.71806 20.4625V19.8645L0.645981 16.179C0.341822 15.633 0.525585 14.9375 1.05153 14.6255C1.5838 14.3135 2.26182 14.4955 2.57232 15.0415L4.61905 18.675H16.6587V12.3375C16.6587 8.8405 19.4278 6 22.8369 6C26.246 6 29.0151 8.8405 29.0151 12.3375V14.6125C29.0151 15.243 28.5208 15.75 27.9062 15.75C27.2915 15.75 26.7973 15.243 26.7973 14.6125V12.3375C26.7973 10.095 25.023 8.275 22.8369 8.275C20.6507 8.275 18.8765 10.095 18.8765 12.3375V18.675H19.9854C20.6 18.675 21.0943 19.182 21.0943 19.8125V20.3C21.0943 23.7385 18.1224 26.6375 14.5992 26.6375C12.4765 26.6375 10.3917 25.5195 9.16242 23.7125C8.8139 23.199 8.94063 22.4905 9.44123 22.1265C9.94182 21.769 10.6325 21.899 10.981 22.4125C11.8048 23.615 13.1862 24.3625 14.5992 24.3625C16.646 24.3625 18.4646 22.8285 18.8194 20.95H4.94856C5.2337 25.6755 9.62499 29.725 14.5992 29.725C19.5735 29.725 24.2626 25.396 24.2626 20.4625V19.8125C24.2626 19.182 24.7569 18.675 25.3715 18.675H31.3913C32.006 18.675 32.5002 19.182 32.5002 19.8125Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2542_90">
          <rect width="32" height="32" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
