import type { MouseEvent } from 'react';
import { useController } from 'yet-another-react-lightbox';

import { Icon24 } from '@spaceduck/icons';
import Button from '@ui/Button';
import Tooltip from '@ui/Tooltip';
import styles from './LightboxModalHeader.module.scss';
const { Close, ZoomIn, ZoomOut } = Icon24;

type LightboxMenuButtonProps = {
  disabled?: boolean;
  onClick?: () => void;
  type: 'close' | 'next' | 'prev' | 'zoomIn' | 'zoomOut';
};

export default function LightboxMenuButton({
  disabled = false,
  onClick,
  type,
}: LightboxMenuButtonProps) {
  const { close, focus: resetFocus } = useController();
  const wrapClickAction = (ev: MouseEvent, cb?: () => void) => {
    ev.preventDefault();
    cb?.();
    resetFocus();
  };

  switch (type) {
    case 'close':
      return (
        <Tooltip content="Close" shortKeys={['Esc']} size="md" variant="secondary">
          <Button
            className={styles.button}
            disabled={disabled}
            onClick={(ev) => wrapClickAction(ev, close)}
            variant="icon"
          >
            <Close />
          </Button>
        </Tooltip>
      );
    case 'zoomIn':
      return (
        <Tooltip content="Zoom In" size="md" variant="secondary">
          <Button
            className={styles.button}
            disabled={disabled}
            onClick={(ev) => wrapClickAction(ev, onClick)}
            variant="icon"
          >
            <ZoomIn />
          </Button>
        </Tooltip>
      );
    case 'zoomOut':
      return (
        <Tooltip content="Zoom Out" size="md" variant="secondary">
          <Button
            disabled={disabled}
            className={styles.button}
            onClick={(ev) => wrapClickAction(ev, onClick)}
            variant="icon"
          >
            <ZoomOut />
          </Button>
        </Tooltip>
      );
    default:
      return null;
  }
}
