import * as RadixDialog from '@radix-ui/react-dialog';
import { Icon24 } from '@spaceduck/icons';
import { clsx } from 'clsx';
import { useCallback, useEffect } from 'react';

import { css } from '@/lib/css';
import Breadcrumb, { type BreadcrumbItem } from '@ui/Breadcrumb';
import Button from '@ui/Button';
import styles from './Dialog.module.scss';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
const { Close } = Icon24;

type DialogProps = {
  breadcrumb?: BreadcrumbItem[];
  children?: React.ReactNode;
  className?: string;
  closeModal?: () => void;
  contentProps?: RadixDialog.DialogContentProps;
  description?: string;
  footer?: React.ReactNode;
  headerPadding?: 0;
  isOpen: boolean;
  maxWidth?: string;
  modalHeading?: string | React.ReactNode;
  modalHeadingExtra?: React.ReactNode;
  onOpenAutoFocus?: (event: Event) => void;
  overlayClassName?: string;
  padding?: 'md' | 'lg';
  showHeader?: boolean;
  title?: string;
  variant?: 'primary' | 'secondary';
};

export default function Dialog({
  breadcrumb,
  children,
  className,
  closeModal,
  contentProps,
  description,
  footer,
  headerPadding,
  isOpen,
  maxWidth,
  modalHeading,
  onOpenAutoFocus,
  overlayClassName,
  padding = 'md',
  showHeader = true,
  title,
  modalHeadingExtra,
  variant = 'primary',
}: DialogProps) {
  useEffect(() => {
    // Mimic effects of Overlay
    document.querySelector('body')?.classList.add('dialogActive');

    return () => {
      document.querySelector('body')?.classList.remove('dialogActive');
    };
  }, []);

  const handleClose = useCallback(() => closeModal?.(), [closeModal]);

  return (
    <RadixDialog.Root open={isOpen}>
      <RadixDialog.Portal>
        {/* Removed Overlay as it breaks scrolling in menus.
        TODO: Replace with official solution when available - https://github.com/radix-ui/primitives/issues/1159 */}
        <div
          className={clsx(styles.dialogOverlay, overlayClassName)}
          onClick={handleClose}
        />
        {/* <RadixDialog.Overlay
          className={clsx(styles.dialogOverlay, overlayClassName)}
        /> */}
        <RadixDialog.Content
          {...contentProps}
          className={clsx(styles.dialogContent, styles[variant], className)}
          onOpenAutoFocus={onOpenAutoFocus}
          style={css({
            '--max-width': maxWidth,
            '--padding': padding === 'lg' ? 'var(--size-6)' : 'var(--size-4)',
          })}
        >
          <VisuallyHidden asChild>
            <RadixDialog.Title>
              {modalHeading || title || 'Dialog title'}
            </RadixDialog.Title>
          </VisuallyHidden>
          <VisuallyHidden asChild>
            <RadixDialog.Description>
              {description || 'Dialog description'}
            </RadixDialog.Description>
          </VisuallyHidden>
          {showHeader && (
            <div
              className={clsx(
                'dialogHeader',
                headerPadding === 0 && styles.removePaddingBottom,
                styles.header
              )}
            >
              {breadcrumb && (
                <Breadcrumb breadcrumb={breadcrumb} className={styles.breadcrumbs} />
              )}
              {modalHeading && <h3 className={styles.modalHeading}>{modalHeading}</h3>}
              {modalHeadingExtra}
              {closeModal && (
                <Button
                  aria-label="close"
                  className={styles.closeBtn}
                  onClick={handleClose}
                  variant="icon"
                >
                  <Close />
                </Button>
              )}
            </div>
          )}
          {(title || description) && (
            <div className={styles.dialogBody}>
              {title && (
                <RadixDialog.Title className={styles.dialogTitle}>
                  {title}
                </RadixDialog.Title>
              )}
              {description && (
                <RadixDialog.Description className={styles.dialogDescription}>
                  {description}
                </RadixDialog.Description>
              )}
            </div>
          )}
          {children}
          {footer && <div className={styles.footer}>{footer}</div>}
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
}
