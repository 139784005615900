import type { Dispatch } from 'react';

import { Icon24 } from '@spaceduck/icons';

import Badge from '@ui/Badge';
import styles from './PlanSelection.module.scss';
import { RadioButton } from './RadioButton';
import type { BillingInterval } from '@spaceduck/api';

type PlanSelectionProps = {
  billingInterval: BillingInterval;
  setBillingInterval: Dispatch<React.SetStateAction<BillingInterval>>;
};

const { Check } = Icon24;

export const PlanSelection = ({
  billingInterval,
  setBillingInterval,
}: PlanSelectionProps) => {
  return (
    <>
      <ul className={styles.features}>
        <li>
          <Check />
          <span>Unlimited AI Writing</span>
        </li>
        <li>
          <Check />
          <span>500MB Upload size</span>
        </li>
        <li>
          <Check />
          <span>Unlimited AI Assistant</span>
        </li>
        <li>
          <Check />
          <span>500GB Storage </span>
        </li>
        <li>
          <Check />
          <span>Unlimited AI Summaries</span>
        </li>
        <li>
          <Check />
          <span>AI Tagging</span>
        </li>
        <li>
          <Check />
          <span>Unlimited Items</span>
        </li>
        <li>
          <Check />
          <span>AI Organization</span>
        </li>
        <li>
          <Check />
          <span>Unlimited Spaces</span>
        </li>
        <li>
          <Check />
          <span>AI Search</span>
        </li>
      </ul>
      <div className={styles.planSelection}>
        <RadioButton
          isActive={billingInterval === 'year'}
          name="plan"
          onSelect={() => setBillingInterval('year')}
          selected={billingInterval}
          value="year"
        >
          <div className={styles.planDetails}>
            <span className={styles.planName}>
              <span>Annual</span>
              <Badge className={styles.saveBadge} size="sm">
                Save 25%
              </Badge>
            </span>
            <span className={styles.pricing}>
              $8.99 <span className={styles.muted}>/ mo</span>
            </span>
          </div>
        </RadioButton>
        <RadioButton
          isActive={billingInterval === 'month'}
          name="plan"
          onSelect={() => setBillingInterval('month')}
          selected={billingInterval}
          value="month"
        >
          <div className={styles.planDetails}>
            <span className={styles.planName}>
              <span>Monthly</span>
            </span>
            <span className={styles.pricing}>
              $11.99 <span className={styles.muted}>/ mo</span>
            </span>
          </div>
        </RadioButton>
      </div>
    </>
  );
};
