import type { MediaGroupDetailDTO } from '@spaceduck/api';
import { Icon24, Icon32 } from '@spaceduck/icons';

import Button from '@ui/Button';
import Spinner from '@ui/Spinner';
import styles from './BookmarkPlaceholder.module.scss';
import PositionContainer from './PositionContainer';
import clsx from 'clsx';

const { Open } = Icon24;
const { Bookmark: BookmarkIcon } = Icon32;

type BookmarkPlaceholderProp = {
  mediaGroup: MediaGroupDetailDTO;
};

export default function BookmarkPlaceholder({ mediaGroup }: BookmarkPlaceholderProp) {
  if (mediaGroup.kind !== 'bookmark' || mediaGroup.media) {
    return null;
  }

  const { contentType, isGeneratingContent, media } = mediaGroup;
  const isYoutubeVideo =
    mediaGroup.embed?.kind === 'youtube' && !!mediaGroup.embed?.videoId;

  const hasEmbeddedView =
    mediaGroup.contentType === 'bookmark' && (mediaGroup.media || isYoutubeVideo);

  if (
    (hasEmbeddedView && contentType !== 'bookmark') ||
    (!isGeneratingContent && media)
  )
    return null;

  return (
    <PositionContainer>
      <BookmarkPendingMessage mediaGroup={mediaGroup} />
    </PositionContainer>
  );
}

type BookmarkPendingMessageProps = {
  mediaGroup: MediaGroupDetailDTO;
  className?: string;
};

export const BookmarkPendingMessage = ({
  mediaGroup,
  className,
}: BookmarkPendingMessageProps) => {
  const { isGeneratingContent, linkUrl, linkUrlSource } = mediaGroup;

  return (
    <div className={clsx(styles.sizeContainer, className)}>
      {isGeneratingContent ? <Spinner /> : <BookmarkIcon />}
      <h3>{linkUrlSource}</h3>
      {isGeneratingContent && (
        <p>
          Generating website info. This might take a moment, so please check back later.
        </p>
      )}
      {linkUrl && (
        <Button
          iconAfter={<Open />}
          onClick={() => window.open(linkUrl)}
          size="md"
          variant="outlined"
        >
          Open link
        </Button>
      )}
    </div>
  );
};
