import Link from '@tiptap/extension-link';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { getAttributes } from '@tiptap/core';

export default Link.extend({
  exitable: true,
  addOptions() {
    return {
      ...this.parent?.(),
      openOnClick: false,
    };
  },
  addProseMirrorPlugins() {
    const isMac = window.navigator.userAgent.indexOf('Mac') !== -1;
    const plugins: Plugin[] = this.parent?.() || [];

    const ctrlClickHandler = new Plugin({
      key: new PluginKey('handleControlClick'),
      props: {
        handleDOMEvents: {
          keydown: (view, event) => {
            const keyMatched =
              (!isMac && event.ctrlKey) ||
              (isMac && (event.metaKey || event.key === 'Meta'));
            if (!keyMatched) return false;

            view.dom.setAttribute('data-clickable-links', 'true');

            return false;
          },
          keyup: (view, event) => {
            const keyMatched =
              (!isMac && event.ctrlKey) ||
              (isMac && (event.metaKey || event.key === 'Meta'));
            if (!keyMatched) return false;
            view.dom.setAttribute('data-clickable-links', 'false');

            return false;
          },
        },
        handleClick(view, _, event) {
          const attrs = getAttributes(view.state, 'link');
          const link = (event.target as HTMLElement)?.closest('a');

          const keyPressed = (!isMac && event.ctrlKey) || (isMac && event.metaKey);

          if (keyPressed && link && attrs.href) {
            event.preventDefault();
            window.open(attrs.href, attrs.target);

            return true;
          }

          return false;
        },
      },
    });

    plugins.push(ctrlClickHandler);

    return plugins;
  },
});
