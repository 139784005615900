import clsx from 'clsx';
import { getMonth, getYear } from 'date-fns';
import { useState } from 'react';
import ReactDatePicker, {
  type DatePickerProps as ReactDatePickerProps,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './DatePicker.override.scss';
import { FULL_MONTH_NAMES, SHORT_MONTH_NAMES } from '@/utils/date';
import { Icon24 } from '@spaceduck/icons';
import Button from './Button';
import styles from './DatePicker.module.scss';
const { Left, Right } = Icon24;

type DatePickerProps = {
  escapeOverflow?: boolean;
  onValueChange?: (date: Date | null) => void;
} & Omit<
  ReactDatePickerProps,
  'onChange' | 'selectsMultiple' | 'selectsRange' | 'showMonthYearDropdown'
>;

export type CalendarView = 'days' | 'months' | 'years';

export default function DatePicker(props: DatePickerProps) {
  const { className, escapeOverflow, onValueChange, ...datePickerProps } = props;

  const [currentView, setCurrentView] = useState<CalendarView>('days');

  const handleSelect = () => {
    if (currentView === 'years') {
      setCurrentView('months');
    }
    if (currentView === 'months') {
      setCurrentView('days');
    }
  };

  return (
    <div className={clsx('reactDatePicker', className)}>
      <ReactDatePicker
        onSelect={handleSelect}
        onChange={(date: Date | null) => {
          if (currentView === 'days' && onValueChange) {
            onValueChange(date);
          }
        }}
        renderDayContents={(day) => <span>{day}</span>}
        showPopperArrow={false}
        shouldCloseOnSelect={currentView === 'days'}
        showMonthYearPicker={currentView === 'months'}
        showYearPicker={currentView === 'years'}
        renderMonthContent={(month) => {
          return <div>{SHORT_MONTH_NAMES[month]}</div>;
        }}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          decreaseYear,
          increaseYear,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          prevYearButtonDisabled,
          nextYearButtonDisabled,
        }) => {
          return (
            <>
              {currentView === 'days' && (
                <div className={styles.header}>
                  <Button
                    className={styles.button}
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                    isSquare
                    size="sm"
                    type="button"
                    variant="outlined"
                  >
                    <Left />
                  </Button>
                  <div
                    onClick={() => {
                      setCurrentView('months');
                    }}
                  >
                    {`${FULL_MONTH_NAMES[getMonth(date)]} ${getYear(date)}`}
                  </div>
                  <Button
                    className={styles.button}
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                    isSquare
                    size="sm"
                    type="button"
                    variant="outlined"
                  >
                    <Right />
                  </Button>
                </div>
              )}
              {currentView === 'months' && (
                <div className={styles.header}>
                  <Button
                    className={styles.button}
                    onClick={decreaseYear}
                    disabled={prevYearButtonDisabled}
                    isSquare
                    size="sm"
                    type="button"
                    variant="outlined"
                  >
                    <Left />
                  </Button>
                  <div>{getYear(date)}</div>
                  <Button
                    className={styles.button}
                    onClick={increaseYear}
                    disabled={nextYearButtonDisabled}
                    isSquare
                    size="sm"
                    type="button"
                    variant="outlined"
                  >
                    <Right />
                  </Button>
                </div>
              )}
            </>
          );
        }}
        {...datePickerProps}
        popperProps={{ strategy: escapeOverflow ? 'fixed' : 'absolute' }}
        selectsMultiple={undefined}
        selectsRange={undefined}
        showMonthYearDropdown={undefined}
      />
    </div>
  );
}
