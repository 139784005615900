import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router';
import { createPortal } from 'react-dom';
import Tippy from '@tippyjs/react';
import { create } from 'zustand';

import {
  type AiProvider,
  createMediaGroup,
  providerModels,
  rewriteChatSessionMessage,
  type Chat,
  type ChatInteraction,
  type ChatSessionSource,
} from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import { exists } from '@spaceduck/utils';

import { useGetChatSession, useQueryChatSession } from '@api/ai';
import { useProject } from '@api/project';
import { toastApiErrorOr } from '@api/util';
import { ChatHistory } from '@components/ai/ChatHistory';
import ChatMessageInput from '@components/ai/ChatMessageInput';
import Sidebar from '@components/ai/Sidebar';
import Head from '@components/Head';
import { ContentType } from '@components/icons';
import { RenderedMarkdown } from '@components/RenderedMarkdown';
import { useNotesEditor } from '@hooks/useNotesEditor';
import { useTypewriter } from '@hooks/useTypewriter';
import { css } from '@lib/css';
import Button, { ButtonLink } from '@ui/Button';
import ScrollArea from '@ui/ScrollArea';
import Tooltip from '@ui/Tooltip';
import { copyMarkdownToClipboard } from '@utils/copyToClipboard';
import { Favicon } from '@ui/Favicon';
import { markdownToHtml } from '@utils/markdown';
import { urlFor } from '@/urls';
import PageHeader from '../projects/common/PageHeader';
import ProjectTabs from '../projects/common/ProjectTabs';
import sharedStyles from './SharedStyles.module.scss';
import styles from './ResearchAssistant.module.scss';
import { useInteractWithChatMessage } from '@/hooks/useInteractWithChatMessage';

type SourcesStore = {
  sources: ChatSessionSource[];
  setSources: (sources: ChatSessionSource[]) => void;
};

const useSourcesStore = create<SourcesStore>((set) => ({
  sources: [],
  setSources: (sources: ChatSessionSource[]) => set(() => ({ sources })),
}));

const { At, Spaceduck, Document, Copy, ChatHistory: ChatHistoryIcon } = Icon24;
const { Edit, SourceReference, Repeat, ArrowBack } = Icon16;

type ChatContentProps = {
  messages: Chat['messages'];
  sources: ChatSessionSource[];
  chatId: string;
  chatProvider: AiProvider;
  addResponseAction?: (text: string, label?: string) => void;
  expandSources?: () => void;
  showSourcesCount?: number;
  hasPreviousMessages?: boolean;
};

type ChatSourceProps = {
  sources: ChatSessionSource[];
  expandSources?: () => void;
  showSourcesCount?: number;
};

const getSourceLink = (source: ChatSessionSource) => {
  if (source.kind !== 'bookmark' || !source.linkUrl) {
    return urlFor('mediaGroup', {
      mediaGroupId: source.id,
    });
  }

  return source.linkUrl;
};

const ChatSource = (
  props: ChatSessionSource & {
    index: number;
    showDescription: boolean;
  }
) => {
  return (
    <a
      target="_blank"
      href={getSourceLink(props)}
      className={styles.source}
      rel="noreferrer"
    >
      <h4>{props.label}</h4>
      {props.showDescription && <div className={styles.body}>{props.description}</div>}
      <div className={styles.subtitle}>
        {(props.kind !== 'bookmark' || !props.linkUrl) && (
          <span className={styles.icon}>
            <ContentType contentType={props.contentType} />
          </span>
        )}
        {props.kind === 'bookmark' && !!props.linkUrl && (
          <span className={styles.icon}>
            <Favicon size={16} url={props.linkUrl} />
          </span>
        )}
        <span>
          <span>
            {(props.kind === 'bookmark' && props.linkUrlSource) || props.container}
          </span>
          <span>•</span>
          <span>{props.index + 1}</span>
        </span>
      </div>
    </a>
  );
};

const OtherChatSources = ({
  sources,
  onClick,
}: { sources: ChatSessionSource[]; onClick: () => void }) => {
  return (
    <div onClick={onClick} className={styles.otherSources}>
      <div className={styles.icons}>
        {sources.slice(0, 5).map((source) => (
          <span key={source.id} className={styles.iconWrapper}>
            <ContentType contentType={source.contentType} />
          </span>
        ))}
      </div>
      <div className={styles.subtitle}>View {sources.length} more</div>
    </div>
  );
};

const ChatSources = ({ sources, expandSources, showSourcesCount }: ChatSourceProps) => {
  const storeSources = useSourcesStore((state) => state.sources);
  const first3 = sources.slice(0, showSourcesCount ?? 3);
  const otherSources = sources.slice(showSourcesCount ?? 3);
  return (
    <div className={styles.sources}>
      <h3 className={styles.sectionHeader}>
        <At /> Sources
      </h3>
      <div className={styles.sourceList}>
        {first3.map((source, i) => {
          return (
            <div key={i} className={styles.sourceWrapper}>
              <ChatSource
                index={storeSources.findIndex((s) => s.id === source.id)}
                showDescription={false}
                {...source}
              />
            </div>
          );
        })}
        {!!otherSources.length && (
          <div className={styles.sourceWrapper}>
            <OtherChatSources
              onClick={() => expandSources?.()}
              sources={otherSources}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ReferencedSource = ({ mediaGroupId }: { mediaGroupId: string }) => {
  const sources = useSourcesStore((state) => state.sources);
  const mediaGroupSource = sources.find((source) => source.id === mediaGroupId);
  if (!mediaGroupSource) {
    return;
  }
  return (
    <Tippy
      content={
        <ChatSource
          index={sources.findIndex((s) => s.id === mediaGroupId)}
          showDescription
          {...mediaGroupSource}
        />
      }
    >
      <span className={styles.reference}>
        <span>{sources.findIndex((s) => s.id === mediaGroupId) + 1}</span>
      </span>
    </Tippy>
  );
};

const addCitations = (markdown: string, references: string[] | null) => {
  if (!references || references.length === 0) {
    return markdown;
  }
  const citations = references.map((reference) => `[^${reference}]`).join(' ');
  const citeSources = references
    .map((reference) => `[^${reference}]: ${reference}`)
    .join('\n');

  return `${markdown} ${citations}\n\n${citeSources}`;
};

const MessageParagraph = ({
  isActive = false,
  text,
  references,
}: {
  isActive?: boolean;
  references: string[] | null;
  text: string;
}) => {
  const [sup, setSup] = useState<Element>();
  const ref = useRef<HTMLDivElement>(null);
  const markdown = useMemo(() => addCitations(text, references), [text, references]);
  const { finishedTyping, typedText } = useTypewriter({
    text: markdown,
    isComplete: !isActive,
  });
  useEffect(() => {
    if (ref.current) {
      const sup = ref.current.querySelector('a[data-footnote-ref=true]');
      if (sup?.parentElement) {
        setSup(sup.parentElement);
      }
    }
  }, [isActive ? finishedTyping : typedText]);

  return (
    <div ref={ref}>
      <RenderedMarkdown
        className={clsx(styles.markdown, !finishedTyping && styles.typing)}
      >
        {typedText}
      </RenderedMarkdown>
      {sup &&
        references &&
        references.map((reference) =>
          createPortal(<ReferencedSource mediaGroupId={reference} />, sup)
        )}
    </div>
  );
};

const AssistantMessage = ({
  addResponseAction,
  expandSources,
  isActive,
  message,
  onRewriteClick,
  onEditClick,
}: {
  addResponseAction?: (response: string, label?: string) => void;
  expandSources?: () => void;
  isActive?: boolean;
  message: ChatInteraction;
  onEditClick: (messageId: string) => void;
  onRewriteClick: (messageId: string) => void;
}) => {
  const { editor } = useNotesEditor();
  const onCopyClick = async () => {
    const markdownText = message.response.parts
      .map((message) => message.text)
      .join('\n\n');
    await copyMarkdownToClipboard(markdownText);
  };
  const addToDocument = async () => {
    if (!editor) {
      return;
    }

    const text = message.response.parts.map((message) => message.text).join('\n\n');
    addResponseAction?.(text, message.query);
  };

  return (
    <div className={styles.assistantMessage}>
      {message.pending && <TextLoader />}
      {!message.pending && (
        <>
          {message.response.parts.map((p, i) => {
            return <MessageParagraph key={i} isActive={isActive} {...p} />;
          })}
          <div className={styles.messageFooter}>
            <span>
              <Button
                onClick={addToDocument}
                variant="ghost"
                iconBefore={<Document />}
                size="sm"
              >
                Add to document
              </Button>
              <Button
                onClick={() => onRewriteClick(message.id)}
                variant="ghost"
                iconBefore={<Repeat />}
                size="sm"
              >
                Rewrite
              </Button>
            </span>
            <span className={styles.smallActions}>
              <Tooltip content="Copy" side="top">
                <Button onClick={onCopyClick} variant="ghost" size="xs">
                  <Copy size={16} />
                </Button>
              </Tooltip>
              <Tooltip content="Edit" side="top">
                <Button
                  onClick={() => onEditClick(message.id)}
                  variant="ghost"
                  size="xs"
                >
                  <Edit size={16} />
                </Button>
              </Tooltip>
              <Tooltip content="Show sources" side="top">
                <Button onClick={() => expandSources?.()} variant="ghost" size="xs">
                  <SourceReference size={16} />
                </Button>
              </Tooltip>
            </span>
          </div>
          <hr />
        </>
      )}
    </div>
  );
};

const NoMessages = () => {
  return <div>This chat has no messages.</div>;
};

const EditQueryInput = ({
  onCancel,
  onChange,
  value,
}: {
  onCancel: () => void;
  onChange: (value: string) => void;
  value: string;
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const handleKeyUp = (e: KeyboardEvent) => {
    const key = e.key;
    if (key === 'Enter') {
      if (ref.current?.value) {
        onChange(ref.current?.value);
      }
    }

    if (key === 'Escape') {
      onCancel();
    }
  };

  useEffect(() => {
    ref.current?.addEventListener('keyup', handleKeyUp);
    return () => {
      ref.current?.removeEventListener('keyup', handleKeyUp);
    };
  }, [ref.current]);
  return (
    <div className={styles.editQuery}>
      <div className={styles.inputWrapper}>
        <input ref={ref} defaultValue={value} />
      </div>
      <div className={styles.actions}>
        <Button onClick={onCancel} variant="ghost" size="sm">
          Cancel
        </Button>
        <Button
          className={styles.editQuerySend}
          onClick={() => {
            if (ref.current?.value) {
              onChange(ref.current?.value);
            }
          }}
          variant="primary"
          size="sm"
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export const ChatContent = ({
  messages: propsMessages,
  sources,
  chatId,
  chatProvider,
  expandSources,
  hasPreviousMessages,
  showSourcesCount,
  addResponseAction,
}: ChatContentProps) => {
  const {
    mutateAsync: rewrite,
    isPending,
    variables,
  } = useInteractWithChatMessage(rewriteChatSessionMessage);

  const [editableTitles, setEditableTitles] = useState<string[]>([]);
  const [messages, setMessages] = useState(propsMessages);
  const [hasPending, setHasPending] = useState(!hasPreviousMessages);

  const onRewriteClick = useCallback(
    async (messageId: string) => {
      try {
        await rewrite({
          chatId,
          body: { id: messageId, part: 'response' },
        });
      } catch (error) {
        toastApiErrorOr(error, 'Rewrite request failed', {
          iconVariant: 'warning',
          titleText: 'Failed to rewrite content',
          bodyText:
            'An error occurred when attempting to perform rewrite. Please try again later.',
        });
      }
    },
    [chatId]
  );

  const makeTitleNotEditable = useCallback((messageId?: string) => {
    setEditableTitles((messageIds) => messageIds.filter((mId) => mId !== messageId));
  }, []);

  const onEditClick = useCallback(
    (messageId: string) => {
      setEditableTitles([...editableTitles, messageId]);
    },
    [editableTitles]
  );

  const editMessageQuery = useCallback(
    (value: string, messageId?: string) => {
      makeTitleNotEditable(messageId);
      if (value && messageId) {
        rewrite({
          chatId,
          body: { part: 'query', query: value, id: messageId },
        });
      }
    },
    [chatId, makeTitleNotEditable]
  );

  const optimisticMessages = useMemo(() => {
    if (!isPending || variables === undefined) {
      return messages;
    }

    return messages.map((message) => {
      if (message.id !== variables.body.id) {
        return message;
      }
      return {
        ...message,
        pending: true,
        query:
          variables.body.part === 'query'
            ? (variables.body.query ?? message.query)
            : message.query,
      };
    });
  }, [messages, isPending, variables]);

  useEffect(() => {
    if (!hasPending && optimisticMessages.find((message) => message.pending)) {
      setHasPending(true);
    }
  }, [optimisticMessages]);

  useEffect(() => {
    setMessages(propsMessages);
  }, [propsMessages]);

  if (optimisticMessages.length === 0) {
    return <NoMessages />;
  }

  let titleEditable = false;
  if (
    optimisticMessages[0] &&
    editableTitles.includes(optimisticMessages[0].id) &&
    !optimisticMessages[0].pending
  ) {
    titleEditable = true;
  }

  const toggleTruncate = (ev: React.MouseEvent) => {
    ev.currentTarget.toggleAttribute('data-expanded');
  };

  return (
    <div className={styles.chatContent}>
      {titleEditable && optimisticMessages[0]?.id && (
        <EditQueryInput
          onChange={(value) => {
            editMessageQuery(value, optimisticMessages[0]?.id);
          }}
          onCancel={() => {
            makeTitleNotEditable(optimisticMessages[0]?.id);
          }}
          value={optimisticMessages[0]?.query}
        />
      )}
      {!titleEditable && (
        <h1 className={styles.query} onClick={toggleTruncate}>
          {optimisticMessages[0]?.query}
        </h1>
      )}
      {!!sources.length && (
        <ChatSources
          expandSources={expandSources}
          sources={sources}
          showSourcesCount={showSourcesCount}
        />
      )}
      <div className={styles.answerLabelWrapper}>
        <h3 className={styles.sectionHeader}>
          <Spaceduck /> Answer
        </h3>
        <span>{providerModels[chatProvider]}</span>
      </div>
      {optimisticMessages.map((message, i) => (
        <Fragment key={i}>
          {editableTitles.includes(message.id) && i !== 0 && (
            <EditQueryInput
              onChange={(value) => {
                editMessageQuery(value, message.id);
              }}
              onCancel={() => {
                makeTitleNotEditable(message.id);
              }}
              value={message.query}
            />
          )}
          {!editableTitles.includes(message.id) && i !== 0 && !!message.query && (
            <h2 className={styles.query} onClick={toggleTruncate}>
              {message.query}
            </h2>
          )}
          <AssistantMessage
            key={i}
            message={message}
            onEditClick={onEditClick}
            onRewriteClick={onRewriteClick}
            expandSources={expandSources}
            addResponseAction={addResponseAction}
            isActive={optimisticMessages.length - 1 === i && hasPending}
          />
        </Fragment>
      ))}
    </div>
  );
};

export const SourcesPanel = ({
  onClose,
  sources,
  title,
}: {
  className?: string;
  onClose: () => void;
  sources: ChatSessionSource[];
  title: string;
}) => {
  const [showTitlePreview, setShowTitlePreview] = useState(true);

  return (
    <div className={styles.sourcesPanel}>
      <div className={styles.header}>
        <h4>
          <SourceReference /> {`${sources.length} sources`}
        </h4>
      </div>
      <div className={styles.titleContainer}>
        <ScrollArea
          orientation="vertical"
          style={css({
            '--width': '100%',
            '--maxHeight': '33dvh',
          })}
        >
          <div
            className={styles.title}
            onClick={() => setShowTitlePreview((state) => !state)}
          >
            <div className={clsx(showTitlePreview && styles.heightLimiter)}>
              {title}
            </div>
          </div>
        </ScrollArea>
      </div>
      <div className={styles.bodyText}>
        <ScrollArea
          orientation="vertical"
          style={css({
            '--width': '100%',
            '--maxHeight': '100%',
          })}
        >
          {!!sources.length && (
            <div className={styles.sourceList}>
              {sources.map((source, i) => {
                return (
                  <div className={styles.sourceItem} key={i}>
                    <ChatSource {...source} index={i} showDescription />
                  </div>
                );
              })}
            </div>
          )}
        </ScrollArea>
      </div>
      <div className={styles.footer}>
        <Button
          size="sm"
          variant="outlined"
          onClick={onClose}
          iconBefore={<ArrowBack />}
        >
          Back
        </Button>
        {/* {TODO: Support removing sources} */}
      </div>
    </div>
  );
};

const SourcesSidebar = ({
  className,
  onClose,
  sources,
  title,
}: {
  className?: string;
  onClose: () => void;
  sources: ChatSessionSource[];
  title: string;
}) => {
  return (
    <div className={clsx(styles.sourcesSidebar, className)}>
      <SourcesPanel onClose={onClose} sources={sources} title={title} />
    </div>
  );
};

export default function ResearchAssistantChatPage({
  isResearchChat,
}: {
  isResearchChat?: boolean;
}) {
  const { state: locationState } = useLocation();
  const { chatId } = useParams();
  const { data: chatData, error } = useGetChatSession(chatId || null);
  const { mutateAsync: queryChatSessionInternal } = useQueryChatSession();
  const {
    mutateAsync: queryChatSession,
    status,
    variables,
  } = useInteractWithChatMessage(queryChatSessionInternal);
  const setSources = useSourcesStore((state) => state.setSources);

  const handleSubmit = useCallback(
    async (query: string) => {
      if (!chatId) {
        return false;
      }
      try {
        await queryChatSession({ chatId, query });
      } catch (error) {
        toastApiErrorOr(error, 'Failed to add chat message', {
          iconVariant: 'warning',
          titleText: 'Failed to add message',
          bodyText:
            'Unknown error occurred while adding your message. Please try again later.',
        });
        return false;
      }
      return true;
    },
    [chatId]
  );

  const [showChatHistory, setShowChatHistory] = useState(false);
  const [showSourcesSidebar, setShowSourcesSidebar] = useState(false);

  useEffect(() => {
    if (chatData) {
      setSources(chatData.chat.mediaGroups.map((mg) => mg));
    }
  }, [chatData]);
  const projectId = chatData?.chat.projectId;

  const { data: project } = useProject(projectId || null);
  const navigate = useNavigate();

  const inputOffsetRef = useRef<HTMLDivElement | null>(null);
  const inputContainerRef = useRef<HTMLDivElement | null>(null);
  const shadowHeight = 32;

  const resizeInputOffsetRef = () => {
    if (inputOffsetRef.current && inputContainerRef.current) {
      const sharedHeight = inputContainerRef.current.getBoundingClientRect().height;
      inputOffsetRef.current.style.height = `${sharedHeight + shadowHeight}px`;
    }
  };

  const debouncedResizeInputOffsetRef = debounce(resizeInputOffsetRef, 300);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (inputOffsetRef.current && inputContainerRef.current) {
        resizeInputOffsetRef();
        inputOffsetRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [chatData?.chat.id]);

  useLayoutEffect(() => {
    if (!inputContainerRef.current) return;

    const observer = new ResizeObserver((entries) => {
      if (entries.length) {
        debouncedResizeInputOffsetRef();
      }
    });

    observer.observe(inputContainerRef.current);

    return () => observer.disconnect();
  }, []);

  const { editor } = useNotesEditor();
  const addToDocument = async (text: string, label?: string) => {
    if (!editor) {
      return;
    }
    const html = await markdownToHtml(text);

    editor.commands.setContent(html);
    const document = editor.getJSON();
    const plainText = editor.getText();

    const { mediaGroupId } = await createMediaGroup({
      kind: 'document',
      label: label,
      projectId,
      document,
      plainText,
    });
    navigate(urlFor('mediaGroup', { mediaGroupId }));
  };

  useEffect(() => {
    if (error?.message === 'Not found' && projectId) {
      navigate(urlFor('spaceResearchAssistant', { projectId }));
    }
  }, [error]);

  const sources = useMemo(
    () => (chatData ? [...chatData.chat.mediaGroups] : []),
    [chatData]
  );
  const messages = useMemo(() => {
    const items = chatData ? [...chatData.chat.messages] : [];

    if (status === 'pending') {
      items.push({
        id: '',
        pending: true,
        query: variables.query,
        response: {
          parts: [],
        },
      });
    }

    return items.filter(exists);
  }, [chatData, status, variables]);

  useEffect(() => {
    if (chatData?.chat.messages.length) {
      const timeoutId = setTimeout(() => {
        inputOffsetRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 0);

      return () => clearTimeout(timeoutId);
    }
  }, [messages]);

  if (!chatData || !projectId) {
    return null;
  }

  return (
    <>
      <Head title={`${project?.project?.label || 'Space'} Research Assistant`} />
      <PageHeader projectId={projectId} />
      <ProjectTabs
        isResearchChat={isResearchChat}
        projectId={projectId}
        showSidebarToggle={false}
        cta={
          <>
            <ButtonLink
              className={styles.buttonLink}
              size="sm"
              to={urlFor('spaceResearchAssistant', { projectId })}
              variant="outlined"
            >
              New chat
            </ButtonLink>
            <Button
              onClick={() => setShowChatHistory(!showChatHistory)}
              size="sm"
              variant="outlined"
              isSquare
            >
              <ChatHistoryIcon size={20} />
            </Button>
          </>
        }
      />
      <div className={styles.contentWrapper}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <ScrollArea
              orientation="vertical"
              style={css({
                '--width': '100%',
                '--maxHeight': '100%',
              })}
            >
              <div className={styles.sizeWrapper}>
                <div className={styles.chats}>
                  <ChatContent
                    chatId={chatData.chat.id}
                    hasPreviousMessages={!locationState?.fromIndex}
                    messages={messages}
                    chatProvider={chatData.chat.provider}
                    sources={sources}
                    expandSources={() => setShowSourcesSidebar(true)}
                    addResponseAction={addToDocument}
                  />
                </div>
              </div>
              <div ref={inputOffsetRef} />
            </ScrollArea>
          </div>
          <div className={styles.messageInputWrapper}>
            <div className={styles.sizeWrapper}>
              <div className={styles.messageInput} ref={inputContainerRef}>
                <ChatMessageInput
                  projectId={projectId}
                  placeholder="Ask a follow-up"
                  readOnly={true}
                  onSubmit={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
        <Sidebar open={showChatHistory}>
          <h5 className={sharedStyles.chatHistoryTitle}>Chat history</h5>
          <ChatHistory projectId={projectId} />
        </Sidebar>
        {showSourcesSidebar && (
          <div
            onClick={() => setShowSourcesSidebar(false)}
            className={styles.overlay}
          />
        )}
        <SourcesSidebar
          className={clsx(showSourcesSidebar && styles.active)}
          onClose={() => setShowSourcesSidebar(false)}
          sources={sources}
          title={chatData.chat.label}
        />
      </div>
    </>
  );
}

const TextLoader = ({ text = 'Thinking' }: { text?: string }) => {
  const { typedText } = useTypewriter({
    text,
    timeout: 20,
  });

  return (
    <div className={styles.textLoader}>
      {typedText} <span className={clsx(styles.pending)}>|</span>
    </div>
  );
};
