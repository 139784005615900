import { useState } from 'react';

import { Icon32 } from '@spaceduck/icons';

import NewCategoryCard from '@components/NewCategoryCard';
import Lightbox from './empty/Lightbox';
import styles from './EmptyCategories.module.scss';

const { Play } = Icon32;

const VIDEO_URL = null;

export default function EmptyCategories({ projectId }: { projectId?: string }) {
  const [showLightbox, setShowLightbox] = useState(false);

  return (
    <>
      <div className={styles.emptyLibraryContainer}>
        <div className={styles.text}>
          <h1>Categories</h1>
          <p>
            Categories enable you to define items by assigning custom properties and
            grouping similar ones together.
          </p>
        </div>
        {!!VIDEO_URL && (
          <div className={styles.video}>
            <button className={styles.iconButton} onClick={() => setShowLightbox(true)}>
              <Play />
            </button>
            <button className={styles.textButton} onClick={() => setShowLightbox(true)}>
              Learn how Categories works
            </button>
          </div>
        )}
        {projectId && (
          <div className={styles.cta}>
            <NewCategoryCard projectId={projectId} />
          </div>
        )}
      </div>
      {!!VIDEO_URL && (
        <Lightbox
          close={() => setShowLightbox(false)}
          embedUrl={VIDEO_URL}
          open={showLightbox}
        />
      )}
    </>
  );
}
