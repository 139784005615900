import {
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { useNavigate } from 'react-router';
import { useCallback, useContext } from 'react';
import { upperFirst } from 'lodash';

import { Icon16, Icon24 } from '@spaceduck/icons';

import { usePatchWorkspace } from '@api/workspace';
import { useDestroySession, useUserInfo } from '@hooks/useAuth.tsx';
import { useSearch } from '@hooks/useSearch';
import useWorkspaceId from '@hooks/useWorkspaceId';
import useWorkspaces from '@hooks/useWorkspaces';
import { trackEvent } from '@lib/analytics/google';
import DropdownMenu from '@ui/DropdownMenu';
import dropdownMenuStyles from '@ui/DropdownMenu.module.scss';
import { useFeedbackModal } from '@ui/FeedbackModal';
import { WorkspaceAndUserAvatar } from '@ui/UserAvatar';
import { openMailTo } from '@utils/openMailTo';
import { BROWSER_EXT_URL } from '@/const';
import { urlFor } from '@/urls';
import { type Theme, ThemeContext } from '@themes/Theme';
import styles from './MenuUserSection.module.scss';

const { BrowserExtension, Changelog } = Icon16;
const {
  Check,
  DarkMode,
  Exit,
  Feedback,
  Linkedin,
  Open,
  Profile,
  Right,
  Settings,
  Sun,
  Support,
  System,
  Theme: ThemeIcon,
  TwitterX,
  Workspace,
  Youtube,
} = Icon24;

const menuData = {
  xLink: 'https://twitter.com/spaceduckapp', // Twitter
  youtubeLink: 'https://www.youtube.com/@spaceduckapp',
  linkedInLink: 'https://www.linkedin.com/company/spaceduckapp/',
};

export default function MenuUserSection() {
  const workspaceId = useWorkspaceId();
  const navigate = useNavigate();
  const destroySession = useDestroySession();
  const user = useUserInfo();
  const { avatarUrl, preferredName } = user || {};
  const { workspaces, workspace } = useWorkspaces();
  const { mutateAsync: patchWorkspace } = usePatchWorkspace();
  const { open: openFeedbackModal } = useFeedbackModal();
  const { resetSearch } = useSearch();
  const { theme, setTheme } = useContext(ThemeContext);

  const handleThemeChange = useCallback(
    (theme: Theme) => {
      if (!setTheme) return;
      setTheme(theme);
    },
    [setTheme]
  );

  return (
    <div className={styles.userSection}>
      <DropdownMenu
        triggerContent={
          <div className={styles.userMenuTrigger}>
            <WorkspaceAndUserAvatar
              avatarUrl={avatarUrl}
              className={styles.avatar}
              userName={preferredName}
              workspaceName={workspace?.label}
              workspaceAvatar={workspace?.avatarUrl}
            />
            <span className={styles.username}>{workspace?.label ?? 'Workspace'}</span>
          </div>
        }
      >
        <div className={styles.menuContent}>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <button className="btn noHoverIcon">
                <Settings size={20} />
                <span className={styles.text}>Settings</span>
                <Right />
              </button>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent
                alignOffset={-9}
                sideOffset={14}
                avoidCollisions={true}
                className={clsx(
                  dropdownMenuStyles.dropdownMenuContent,
                  styles.dropdownMenuContent
                )}
              >
                <div className={styles.menuContent}>
                  <DropdownMenuItem
                    asChild
                    onSelect={() => navigate(urlFor('userSettingsProfile'))}
                  >
                    <button className="btn">
                      <Profile size={20} />
                      <span className={styles.text}>User Settings</span>
                    </button>
                  </DropdownMenuItem>
                  {workspaceId && (
                    <DropdownMenuItem
                      asChild
                      onSelect={() =>
                        navigate(urlFor('workspaceSettings', { workspaceId }))
                      }
                    >
                      <button className="btn">
                        <Settings size={20} />
                        <span className={styles.text}>Workspace Settings</span>
                      </button>
                    </DropdownMenuItem>
                  )}
                </div>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <button className="btn noHoverIcon">
                <Workspace size={20} />
                <span className={styles.text}>Workspace</span>
                <Right />
              </button>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent
                sideOffset={14}
                avoidCollisions={true}
                className={clsx(
                  dropdownMenuStyles.dropdownMenuContent,
                  styles.dropdownMenuContent
                )}
              >
                <div className={styles.menuContent}>
                  {workspaces.map((workspace) => (
                    <DropdownMenuItem
                      key={workspace.id}
                      asChild
                      className={styles.workspaceSwitcherOption}
                      onSelect={() => {
                        resetSearch();
                        navigate(urlFor('workspace', { workspaceId: workspace.id }));
                        patchWorkspace({
                          workspaceId: workspace.id,
                          patch: { isFavorite: true },
                        });
                        trackEvent('select_content', {
                          content_type: 'workspace',
                          content_id: workspace.id,
                        });
                      }}
                      disabled={workspace.id === workspaceId}
                    >
                      <button className="btn">
                        <span className={styles.workspaceIcon}>
                          {workspace.label[0]?.toLocaleUpperCase() ?? '?'}
                        </span>
                        <span className={clsx(styles.text, styles.workspaceLabel)}>
                          {workspace.label}
                        </span>
                        {workspace.id === workspaceId && (
                          <Check className={styles.selectedIcon} />
                        )}
                      </button>
                    </DropdownMenuItem>
                  ))}
                </div>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <button className="btn noHoverIcon">
                <ThemeIcon size={20} />
                <span
                  className={styles.text}
                >{`Theme: ${upperFirst(theme)} Mode`}</span>
                <Right />
              </button>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent
                alignOffset={-9}
                sideOffset={14}
                avoidCollisions={true}
                className={clsx(
                  dropdownMenuStyles.dropdownMenuContent,
                  styles.dropdownMenuContent
                )}
              >
                <div className={styles.menuContent}>
                  <DropdownMenuItem asChild onSelect={() => handleThemeChange('dark')}>
                    <button className="btn">
                      <DarkMode size={20} />
                      <span className={styles.text}>Dark Mode</span>
                      {theme === 'dark' && <Check className={styles.selectedIcon} />}
                    </button>
                  </DropdownMenuItem>
                  <DropdownMenuItem asChild onSelect={() => handleThemeChange('light')}>
                    <button className="btn">
                      <Sun size={20} />
                      <span className={styles.text}>Light Mode</span>
                      {theme === 'light' && <Check className={styles.selectedIcon} />}
                    </button>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    asChild
                    onSelect={() => handleThemeChange('system')}
                  >
                    <button className="btn">
                      <System size={20} />
                      <span className={styles.text}>System preference</span>
                      {theme === 'system' && <Check className={styles.selectedIcon} />}
                    </button>
                  </DropdownMenuItem>
                </div>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          {workspaceId && (
            <>
              <div className={styles.divider} />
              <DropdownMenuItem
                asChild
                onSelect={() =>
                  navigate(urlFor('workspaceSettingsPeople', { workspaceId }))
                }
              >
                <button className="btn noHoverIcon">
                  <Profile size={20} />
                  <span className={styles.text}>Invite People</span>
                </button>
              </DropdownMenuItem>
            </>
          )}
          <div className={styles.divider} />
          <DropdownMenuItem asChild onSelect={() => window.open(BROWSER_EXT_URL)}>
            <button className="btn">
              <BrowserExtension size={20} />
              <span className={styles.text}>Browser Extension</span>
            </button>
          </DropdownMenuItem>
          <div className={styles.divider} />
          <DropdownMenuItem asChild onSelect={openFeedbackModal}>
            <button className="btn noHoverIcon">
              <Feedback size={20} />
              <span className={styles.text}>Give feedback</span>
              <Open />
            </button>
          </DropdownMenuItem>
          <DropdownMenuItem asChild onSelect={() => openMailTo('[SUPPORT]', '')}>
            <button className="btn">
              <Support size={20} />
              <span className={styles.text}>Support</span>
              <Open />
            </button>
          </DropdownMenuItem>
          <DropdownMenuItem
            asChild
            onSelect={() => window.open('https://www.spaceduck.com/blog')}
          >
            <button className="btn">
              <Changelog size={20} />
              <span className={styles.text}>Changelog</span>
              <Open />
            </button>
          </DropdownMenuItem>
          <div className={styles.divider} />
          <DropdownMenuItem
            asChild
            onSelect={async () => {
              await destroySession();
              navigate('/');
            }}
          >
            <button className="btn">
              <Exit size={20} />
              Sign out
            </button>
          </DropdownMenuItem>
          <div className={styles.divider} />
          <div className={styles.menuFooter}>
            <ul className={styles.quickLinks}>
              <li>
                <DropdownMenuItem>
                  <a
                    href="https://www.spaceduck.com/legal/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy
                  </a>
                </DropdownMenuItem>
              </li>
              <li>
                <DropdownMenuItem>
                  <a
                    href="https://www.spaceduck.com/legal/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms
                  </a>
                </DropdownMenuItem>
              </li>
            </ul>
            <div className={styles.socialLinks}>
              {menuData.xLink && (
                <DropdownMenuItem>
                  <a
                    href={menuData.xLink}
                    target="_blank"
                    title="X / Twitter"
                    rel="noreferrer"
                  >
                    <TwitterX size={16} />
                  </a>
                </DropdownMenuItem>
              )}
              {menuData.youtubeLink && (
                <DropdownMenuItem>
                  <a
                    href={menuData.youtubeLink}
                    target="_blank"
                    title="YouTube"
                    rel="noreferrer"
                  >
                    <Youtube size={16} />
                  </a>
                </DropdownMenuItem>
              )}
              {menuData.linkedInLink && (
                <DropdownMenuItem>
                  <a
                    href={menuData.linkedInLink}
                    target="_blank"
                    title="LinkedIn"
                    rel="noreferrer"
                  >
                    <Linkedin size={16} />
                  </a>
                </DropdownMenuItem>
              )}
            </div>
          </div>
        </div>
      </DropdownMenu>
    </div>
  );
}
