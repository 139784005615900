import type { MediaGroupDTO, MediaGroupDetailDTO } from '@spaceduck/api';
import {
  type TableOfContentDataItem,
  TableOfContents,
  getHierarchicalIndexes,
} from '@tiptap-pro/extension-table-of-contents';
import { useEditor } from '@tiptap/react';
import type { EditorProps } from 'prosemirror-view';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { Editor } from '@tiptap/core';

import {
  extensions as defaultExtensions,
  webViewExtensions,
} from '@components/detailsModal/tiptap/extensions';
import { useIsInWebViewRoute } from '@hooks/useIsWebView';
import { useFileUpload } from '@hooks/useNotesFileUpload';
import { useNotesSave } from '@hooks/useNotesSave';
import { useThrottledAction } from '@hooks/useThrottledAction';

export const useNotesEditor = (
  mediaGroup?: MediaGroupDetailDTO,
  editorProps?: EditorProps,
  asQuickView?: boolean
) => {
  const isInWebViewRoute = useIsInWebViewRoute();
  const mediaGroupId = mediaGroup?.id;
  const { save, debounceSave } = useNotesSave(asQuickView);
  const [items, setItems] = useState<TableOfContentDataItem[]>([]);
  const [createdMediaKeys, setCreatedMediaKeys] = useState<Map<string, string>>(
    new Map()
  );

  let editor: Editor | null = null;

  const immediateSave = useCallback(() => {
    console.log('requested save');
    if (mediaGroupId) {
      save(mediaGroupId, editor?.getJSON(), editor?.getText());
    }
  }, [mediaGroupId, save, editor]);

  const { action: throttledSave } = useThrottledAction(immediateSave, 2000);

  const onUpdate = useCallback(
    async ({ editor }: { editor: Editor }) => {
      throttledSave();
      debounceSave(mediaGroupId, editor.getJSON(), editor.getText());
    },
    [mediaGroupId, editor, throttledSave, debounceSave]
  );

  const tableOfContentsOnUpdateRef = useRef<React.Dispatch<
    React.SetStateAction<TableOfContentDataItem[]>
  > | null>(null);

  const editorConfig = {
    editorProps,
    extensions: isInWebViewRoute
      ? [...webViewExtensions]
      : [
          ...defaultExtensions,
          TableOfContents.configure({
            getIndex: getHierarchicalIndexes,
            onUpdate(content) {
              // Fixes warning caused by this being called before the first render
              //  completes. Should investigate if there is a better way to
              //  resolve this.
              tableOfContentsOnUpdateRef.current?.(content);
            },
          }),
        ],
    content: mediaGroup?.document,
    onUpdate,
  };

  editor = useEditor(editorConfig, [mediaGroupId]);

  useEffect(() => {
    if (!editor) return;

    if (!tableOfContentsOnUpdateRef.current) {
      tableOfContentsOnUpdateRef.current = setItems;
    }

    if (!createdMediaKeys.size) return;

    queueMicrotask(() => {
      const content = editor.getHTML();
      if (!content) return;

      editor.setEditable(false, false);
      const container = document.createElement('div');
      container.innerHTML = content;

      Array.from(
        container.querySelectorAll('content-block[loading=true][data-ref]:not(id)')
      ).forEach((contentBlockComponent) => {
        const fileRef = contentBlockComponent.getAttribute('data-ref');
        if (!fileRef) return;

        const id = createdMediaKeys.get(fileRef);
        if (!id) return;

        contentBlockComponent.setAttribute('id', id);
        contentBlockComponent.removeAttribute('loading');
      });

      editor.setEditable(true, false);
      editor.commands.setContent(container.innerHTML);
      editor.setEditable(true);
    });
  }, [editor, createdMediaKeys]);

  const onCreate = useCallback(
    ({ key, mediaGroupId }: { key: string; mediaGroupId: string }) => {
      if (!editor) {
        console.error('Attempt to upload file into dead editor');
        return;
      }

      setCreatedMediaKeys((createdMediaKeys) => {
        const updatedMediaKeys = new Map(createdMediaKeys);
        updatedMediaKeys.set(key, mediaGroupId);

        return updatedMediaKeys;
      });
    },
    [editor]
  );

  const onError = useCallback(
    ({ key }: { key: string }) => {
      if (!editor) return;

      const content = editor.getHTML();
      if (!content) return;

      editor.setEditable(false, false);
      const container = document.createElement('div');
      container.innerHTML = content;

      Array.from(
        container.querySelectorAll(
          `content-block[loading=true][data-ref="${CSS.escape(key)}"]`
        )
      ).forEach((contentBlockComponent) => {
        contentBlockComponent.remove();
      });

      editor.setEditable(true, false);
      editor.commands.setContent(container.innerHTML);
      editor.setEditable(true);
    },
    [editor]
  );

  const {
    handleDrop: handleFileDrop,
    inputRef,
    handleInputChange,
  } = useFileUpload({ editor, mediaGroup, onCreate, onError });

  editor?.setOptions({
    editorProps: {
      handleDrop: (view, event, slice, moved) => {
        const coordinates = view.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        });

        if (coordinates) {
          if (
            /(blockquote|listItem)/.test(editor.$pos(coordinates.pos).node.type.name)
          ) {
            return true;
          }
        }

        const mediaGroupData = event.dataTransfer?.getData('mediaGroup');
        if (mediaGroupData) {
          let mediaGroup = null;
          try {
            mediaGroup = JSON.parse(mediaGroupData) as Partial<MediaGroupDTO>;
          } catch (ex) {
            console.error('Could not parse media group data', ex);
          }

          if (!mediaGroup) {
            return false;
          }

          if (mediaGroup.id === mediaGroupId) {
            // Prevent drop on self
            return true;
          }

          const { schema } = view.state;
          const coordinates = view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          });

          const nodeType =
            mediaGroup.kind === 'document' ? 'outgoing-link' : 'content-block';

          const { id, label, contentType } = mediaGroup;
          const node = schema?.nodes?.[nodeType]?.create({
            id,
            label,
            contentType,
          });

          if (coordinates && node && mediaGroup.id) {
            const transaction = view.state.tr.insert(coordinates.pos, node);
            view.dispatch(transaction);
          }
          return true;
        }

        return handleFileDrop(view, event, slice, moved);
      },
      handleKeyDown(_view, event) {
        if (event.key === 'Tab') {
          event.stopPropagation();
        }

        if (event.key === 'Enter' && !event.shiftKey) {
          const { state } = editor;
          const { selection } = state;
          const { $from, empty } = selection;

          if (!empty) return false;

          const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;

          if (!isAtEnd) return false;

          // Exclude if selection is within a block as it removes other marks
          if (
            editor.isActive('table') ||
            editor.isActive('listItem') ||
            editor.isActive('blockquote') ||
            editor.isActive('codeBlock')
          )
            return false;

          // TODO: Find a more concise way to remove multiple marks
          editor
            .chain()
            .unsetColor()
            .unsetHighlight()
            .unsetBold()
            .unsetItalic()
            .unsetCode()
            .unsetStrike()
            .unsetUnderline()
            .run();

          return false;
        }
      },
    },
  });

  return {
    editor,
    imageUploadInputRef: inputRef,
    handleImageUploadInputChange: handleInputChange,
    tableOfContentItems: items,
  };
};
