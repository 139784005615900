import Providers from '@components/Providers';
import Router from '@components/Router';
import { AnalyticsConsentPopover } from '@lib/analytics/AnalyticsConsentPopover';
import { css } from '@lib/css';
import Toaster from '@lib/toast';

export default function App() {
  return (
    <Providers>
      <Router />
      <Toaster
        className="globalToaster"
        position="bottom-right"
        richColors
        style={css({ '--width': 'calc(100% - var(--offset) * 2)' })}
      />
      <AnalyticsConsentPopover />
    </Providers>
  );
}
