import { Icon16 } from '@spaceduck/icons';

import { useCreateCategoryModal } from '@hooks/useCreateCategoryModal';
import Button from '@ui/Button';
import styles from './NewCategoryCard.module.scss';

const { Add } = Icon16;

export default function NewCategoryCard({ projectId }: { projectId: string }) {
  const { open } = useCreateCategoryModal();

  return (
    <div className={styles.container}>
      <Button isFullWidth size="md" onClick={() => open(projectId)} variant="outlined">
        <Add />
        New category
      </Button>
    </div>
  );
}
