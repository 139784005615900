import {
  Content as DialogContent,
  Description as DialogDescription,
  Title as DialogTitle,
  Root as DialogRoot,
  Portal,
} from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useEffect, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from 'react-router';
import { useShallow } from 'zustand/shallow';

import type { ApiError, errorSchema } from '@spaceduck/api';

import { useListMediaGroups, useMediaGroupDetail } from '@api/mediaGroup';
import { isCapable } from '@api/util';
import Head from '@components/Head';
import { useUpgradePlanModal } from '@components/UpgradePlanModal';
import { useModalManager } from '@context/ModalManagerContext';
import { useNavigateToBackgroundLocation } from '@hooks/useBackgroundLocation';
import { useNotesEditor } from '@hooks/useNotesEditor';
import { useSearch } from '@hooks/useSearch';
import useWorkspaceId from '@hooks/useWorkspaceId';
import {
  type ContentView,
  type DetailsModalState,
  useDetailsModalStore,
} from '@stores/useDetailsModalStore';
import Spinner from '@ui/Spinner';
import { getCloseTo } from '@utils/mediaGroup';
import { knownErrors } from '@/const';
import type { DetailsModelTab } from '@/types/MediaGroup';
import DetailsModalBody from './DetailsModalBody';
import DetailsModalHeader from './DetailsModalHeader';
import DetailsModalPlaceholder from './placeholder/DetailsModalPlaceholder';
import { getDefaultView } from './views';

import styles from './DetailsModal.module.scss';

const RETRIES = 5;
const LOADING_TITLE = 'Loading item info...';
const ERROR_TITLE = 'Error loading item info';

const selector = (state: DetailsModalState) => ({
  currentMediaGroupPageCount: state.currentMediaGroupPageCount,
  isCommenting: state.isCommenting,
  isInLightboxView: state.isInLightboxView,
  isInlineCommenting: state.isInlineCommenting,
  newCommentPosition: state.newCommentPosition,
  setAvailableContentViews: state.setAvailableContentViews,
  setContentView: state.setContentView,
  setIsCommenting: state.setIsCommenting,
  setIsInLightboxView: state.setIsInLightboxView,
  setUserCapabilities: state.setUserCapabilities,
  topRef: state.topRef,
  setQuickViewMediaGroupId: state.setQuickViewMediaGroupId,
  swapQuickViewMediaGroupId: state.swapQuickViewMediaGroupId,
  setSwapQuickViewMediaGroupId: state.setSwapQuickViewMediaGroupId,
  isDraggingOnBoard: state.isDraggingOnBoard,
  setIsDetailsModalView: state.setIsDetailsModalView,
});

export default function DetailsModal({
  mediaGroupId,
  view: _view,
  activeTab,
}: {
  mediaGroupId: string;
  view: string | null;
  activeTab: DetailsModelTab;
}) {
  const location = useLocation();

  const {
    currentMediaGroupPageCount,
    isCommenting,
    isInLightboxView,
    isInlineCommenting,
    newCommentPosition,
    setAvailableContentViews,
    setContentView,
    setIsCommenting,
    setIsInLightboxView,
    setUserCapabilities,
    topRef,
    setQuickViewMediaGroupId,
    swapQuickViewMediaGroupId,
    setSwapQuickViewMediaGroupId,
    isDraggingOnBoard,
    setIsDetailsModalView,
  } = useDetailsModalStore(useShallow(selector));

  const workspaceId = useWorkspaceId();
  const { debouncedSearchValue, mediaGroupFilters, excludeProjectLibraries } =
    useSearch();
  const {
    data: featureMediaGroupsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    enabled,
  } = useListMediaGroups(workspaceId, {
    query: debouncedSearchValue,
    excludeProjectLibraries,
    ...mediaGroupFilters,
  });

  const { data, isError, isPending, failureCount, failureReason, ...rest } =
    useMediaGroupDetail(mediaGroupId, { retry: RETRIES, enabled: !isDraggingOnBoard });

  const kind = isError || isPending ? null : data.mediaGroup.kind;
  const contentType = isError || isPending ? null : data.mediaGroup.contentType;
  const embed = isError || isPending ? null : data.mediaGroup.embed;

  const view = useMemo(() => {
    if (_view) {
      return _view;
    }

    if (kind === null || contentType === null) {
      return _view;
    }

    return getDefaultView({ kind, contentType, embed }) ?? 'media';
  }, [_view, kind, contentType, embed]);

  const userCanEdit = isCapable('edit', data?.mediaGroup?.userCapabilities).capable;

  const notesEditorOptions = useMemo(
    () => ({
      editable: () => userCanEdit ?? false,
    }),
    [userCanEdit]
  );

  const {
    editor,
    handleImageUploadInputChange,
    imageUploadInputRef,
    tableOfContentItems,
  } = useNotesEditor(data?.mediaGroup, notesEditorOptions);

  const error = rest.error as ApiError<Zod.infer<typeof errorSchema>> | null;
  const showUpgradeModal = error?.payload?.errorKind === 'forbidden-by-plan';

  const navigateToBackgroundLocation = useNavigateToBackgroundLocation();
  const { modals } = useModalManager();

  const handleClose = () => {
    navigateToBackgroundLocation(getCloseTo(data?.mediaGroup));
    setQuickViewMediaGroupId(null);
  };

  const { open: openUpgradePlanModal, close: closeUpgradeModal } = useUpgradePlanModal({
    title: 'Browsing limit exceeded',
    header: 'Plan management',
    message: 'Cannot browse this entry, please upgrade your plan',
    closeModal: handleClose,
  });

  useEffect(() => {
    if (swapQuickViewMediaGroupId) {
      setQuickViewMediaGroupId(swapQuickViewMediaGroupId);
      setSwapQuickViewMediaGroupId(null);
    }
  }, [mediaGroupId, swapQuickViewMediaGroupId]);

  useEffect(() => {
    if (activeTab !== 'comment' && isCommenting) {
      setIsCommenting(false);
    }
  }, [activeTab, isCommenting, setIsCommenting]);

  useHotkeys(
    'Escape',
    handleClose,
    {
      enabled:
        !isCommenting &&
        !newCommentPosition &&
        !modals.length &&
        activeTab !== 'info' &&
        !isInLightboxView &&
        !isInlineCommenting &&
        data?.mediaGroup.kind !== 'board',
    },
    [
      isCommenting,
      newCommentPosition,
      modals.length,
      activeTab,
      isInLightboxView,
      isInlineCommenting,
      data?.mediaGroup.kind,
    ]
  );

  useEffect(() => {
    if (
      enabled &&
      hasNextPage &&
      !isFetchingNextPage &&
      (featureMediaGroupsData?.pages?.length ?? 0) < currentMediaGroupPageCount
    ) {
      fetchNextPage();
    }
  }, [enabled, featureMediaGroupsData?.pages, currentMediaGroupPageCount]);

  useEffect(() => {
    setIsCommenting(false);
    setUserCapabilities(data?.userCapabilities ?? []);
    if (data?.mediaGroup.kind === 'document') {
      setContentView('notes');
      return;
    }
    if (data?.mediaGroup.kind === 'board') {
      setContentView('board');
      return;
    }
    if (data?.mediaGroup.kind === 'bookmark') {
      setContentView('content');
      return;
    }
    if (data?.mediaGroup.kind === 'extract') {
      setContentView('content');
      return;
    }
    if (!data?.mediaGroup?.media) {
      setAvailableContentViews([]);
      setContentView(undefined);
      return;
    }
    const includesVideo = !!data.mediaGroup.media?.mediaType.startsWith('video/');
    setAvailableContentViews([
      'content',
      'notes',
      ...(includesVideo ? ['transcript' as ContentView] : []),
    ]);
    setContentView('content');
  }, [data]);

  useEffect(() => {
    setIsInLightboxView(false);
  }, [location]);

  useEffect(() => {
    if (error?.payload?.errorKind === 'forbidden-by-plan') {
      openUpgradePlanModal();
      return closeUpgradeModal;
    }
  }, [error]);

  useEffect(() => {
    // Mimic effects of Overlay
    document.querySelector('body')?.classList.add('detailsModalActive');
    setIsDetailsModalView(true);

    return () => {
      document.querySelector('body')?.classList.remove('detailsModalActive');
      setIsDetailsModalView(false);
    };
  }, []);

  if (
    (isError && !showUpgradeModal && error?.message === 'Not found') ||
    failureReason?.message === 'Not found'
  ) {
    throw new Error(knownErrors.mediaGroupError, { cause: rest.error });
  }

  const title = isPending
    ? LOADING_TITLE
    : isError
      ? ERROR_TITLE
      : data.mediaGroup.label;

  return (
    <>
      <Head title={data?.mediaGroup.label ?? 'Browse'} />
      <DialogRoot open={!showUpgradeModal}>
        <Portal>
          {/* Removed Overlay as it breaks scrolling in menus.
        TODO: Replace with official solution when available - https://github.com/radix-ui/primitives/issues/1159 */}
          {/* <Overlay className={styles.overlay} /> */}
          <div className={styles.overlay} />
          <DialogContent className={styles.dialogContent} ref={topRef}>
            <VisuallyHidden asChild>
              <DialogTitle>{title}</DialogTitle>
            </VisuallyHidden>
            <VisuallyHidden asChild>
              <DialogDescription>Details for the selected item</DialogDescription>
            </VisuallyHidden>
            {!data && (isError || (isPending && failureCount > 0)) && (
              <DetailsModalPlaceholder
                state="error"
                errorMessage={
                  failureCount < RETRIES
                    ? "Something went wrong. Let's try that again..."
                    : undefined
                }
                errorAction={
                  failureCount < RETRIES ? (
                    <div className={styles.spinner}>
                      <Spinner />
                    </div>
                  ) : undefined
                }
              />
            )}
            {data && (
              <>
                <DetailsModalHeader
                  mediaGroup={data.mediaGroup}
                  isNote={data.mediaGroup.kind === 'document'}
                  activeTab={activeTab}
                  view={view}
                />
                <DetailsModalBody
                  activeTab={activeTab}
                  view={view}
                  editor={editor}
                  imageUploadInputRef={imageUploadInputRef}
                  handleImageUploadInputChange={handleImageUploadInputChange}
                  mediaGroupId={mediaGroupId}
                  tableOfContentItems={tableOfContentItems}
                />
                <div>
                  <div id="tippyHoverMenuRoot" />
                  <div id="tiptapSelectionMenuRoot" />
                  <div id="tippySlashMenuRoot" />
                </div>
              </>
            )}
          </DialogContent>
        </Portal>
      </DialogRoot>
    </>
  );
}
