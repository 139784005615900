import * as RadixCollapsible from '@radix-ui/react-collapsible';
import { clsx } from 'clsx';
import { useState } from 'react';

import { Icon16 } from '@spaceduck/icons';
import type { AllOrNone } from '@spaceduck/utils';
import styles from './Collapsible.module.scss';
const { DownArrow, RightArrow } = Icon16;

type CollapsibleProps = {
  className?: string;
  children?: React.ReactNode;
  initiallyExpanded?: boolean;
  triggerContent: React.ReactNode;
  hasUnstyledTrigger?: boolean;
  contentClassName?: string;
  animateHeight?: boolean;
  isStyled?: boolean;
} & AllOrNone<{
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

export default function Collapsible({
  className,
  children,
  initiallyExpanded,
  triggerContent,
  hasUnstyledTrigger = false,
  contentClassName,
  isOpen: externalIsOpen,
  setIsOpen: externalSetIsOpen,
  animateHeight = true,
  isStyled = false,
}: CollapsibleProps) {
  const [internalIsOpen, setInternalIsOpen] = useState(() => !!initiallyExpanded);
  const isOpen = externalIsOpen ?? internalIsOpen;
  const setIsOpen = externalSetIsOpen ?? setInternalIsOpen;

  if (!children)
    return (
      <div className={clsx(isStyled && styles.defaultStyle)}>
        <div className={styles.trigger}>
          <div className={styles.triggerContent}>{triggerContent}</div>
        </div>
      </div>
    );

  return (
    <RadixCollapsible.Root
      className={clsx(
        styles.collapsibleRoot,
        isStyled && styles.defaultStyle,
        className
      )}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <RadixCollapsible.Trigger asChild>
        {hasUnstyledTrigger ? (
          triggerContent
        ) : (
          <button className={styles.trigger}>
            {isOpen ? <DownArrow /> : <RightArrow />}
            <div className={styles.triggerContent}>{triggerContent}</div>
          </button>
        )}
      </RadixCollapsible.Trigger>
      <RadixCollapsible.Content
        className={clsx(
          styles.content,
          animateHeight && styles.animateHeight,
          contentClassName
        )}
      >
        {children}
      </RadixCollapsible.Content>
    </RadixCollapsible.Root>
  );
}
