import {
  type DragEventHandler,
  Fragment,
  type MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  applyEdgeChanges,
  applyNodeChanges,
  type Edge,
  type EdgeChange,
  type Node,
  type NodeChange,
  ReactFlow,
  SelectionMode,
  useReactFlow,
  useViewport,
  type Viewport,
} from '@xyflow/react';
import { isEqual, upperFirst } from 'lodash';
import '@xyflow/react/dist/style.css';
import { useShallow } from 'zustand/shallow';

import type { MediaGroupDTO, MediaGroupDetailDTO } from '@spaceduck/api';
import { exists } from '@spaceduck/utils';

import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import { preventDefault } from '@utils/event';
import ActionMenu from './components/ActionMenu';
import {
  BoardContextMenu,
  type BoardContextMenuCoordinates,
} from './components/BoardContextMenu';
import BaseEdge from './components/Edge';
import HelperLines, { getHelperLines } from './components/HelperLines';
import SupportButton from './components/SupportButton';
import ZoomControls from './components/ZoomControls';
import { EdgeStyleMenu } from './components/EdgeStyleMenu';
import { BoardStoreContext } from './context/boardContext';
import { useBoardStore } from './hooks/useBoardStore';
import { useBoardShortKeys } from './hooks/useBoardShortKeys';
import { useEdges } from './hooks/useEdges';
import { useFrames } from './hooks/useFrames';
import { useLocalStoragePersist } from './hooks/useLocalStoragePersist';
import { useNodes } from './hooks/useNodes';
import { usePersist } from './hooks/usePersist';
import { useHistory } from './hooks/useHistory';
import { useUpdateNodeInternalsOnZoom } from './hooks/useZoomWatcher';
import { ArticleNode } from './nodes/ArticleNode';
import { AudioNode } from './nodes/AudioNode';
import { DocumentNode } from './nodes/DocumentNode';
import { FloatingTextNode } from './nodes/FloatingTextNode';
import { FileNode } from './nodes/FileNode';
import { GroupNode } from './nodes/GroupNode';
import { HighlightNode } from './nodes/HighlightNode';
import { ImageNode } from './nodes/ImageNode';
import { MenuPlaceholder } from './nodes/MenuPlaceholder';
import { PdfNode } from './nodes/PdfNode';
import { TempFileUploadNode } from './nodes/TempFileUploadNode';
import { UnknownNode } from './nodes/UnknownNode';
import { VideoNode } from './nodes/VideoNode';
import { useEdgeFloatingMenu } from './hooks/useEdgeFloatingMenu';
import { useFileUploadToBoard } from './hooks/useFileUploadToBoard';
import type { UnknownNodeType } from './types/board';
import { connectionLineColors, connectionLineColorsAsHex } from './types/colors';
import './ReactFlowBoard.scss';
import styles from './ReactFlowBoard.module.scss';

const nodeTypes = {
  articleNode: ArticleNode,
  audioNode: AudioNode,
  documentNode: DocumentNode,
  fileNode: FileNode,
  floatingTextNode: FloatingTextNode,
  groupNode: GroupNode,
  highlightNode: HighlightNode,
  imageNode: ImageNode,
  menuPlaceholder: MenuPlaceholder,
  pdfNode: PdfNode,
  tempFileUploadNode: TempFileUploadNode,
  unknownNode: UnknownNode,
  videoNode: VideoNode,
};

const edgeTypes = {
  baseEdge: BaseEdge,
};

const PRO_OPTIONS = {
  hideAttribution: true,
};
const DELETE_KEY_CODE = ['Backspace', 'Delete'];

export default function ReactFlowBoard({
  mediaGroup,
}: {
  mediaGroup: MediaGroupDetailDTO;
}) {
  const { id: mediaGroupId } = mediaGroup;
  const boardContext = useContext(BoardStoreContext);

  const {
    actionMenuMode,
    setActionMenuMode,
    nodes,
    setNodes,
    onNodesChange,
    edges,
    onEdgesChange,
    panOnDrag,
    setPanOnDrag,
    setSelectedNodes,
    setSelectedEdges,
    setIsDraggingHandle,
  } = useBoardStore(
    useShallow((state) => ({
      actionMenuMode: state.actionMenuMode,
      setActionMenuMode: state.setActionMenuMode,
      nodes: state.nodes,
      setNodes: state.setNodes,
      onNodesChange: state.onNodesChange,
      edges: state.edges,
      onEdgesChange: state.onEdgesChange,
      panOnDrag: state.panOnDrag,
      setPanOnDrag: state.setPanOnDrag,
      setSelectedNodes: state.setSelectedNodes,
      setSelectedEdges: state.setSelectedEdges,
      setIsDraggingHandle: state.setIsDraggingHandle,
    }))
  );

  const [helperLineHorizontal, setHelperLineHorizontal] = useState<number | undefined>(
    undefined
  );
  const [helperLineVertical, setHelperLineVertical] = useState<number | undefined>(
    undefined
  );

  const setIsDraggingOnBoard = useDetailsModalStore(
    (state) => state.setIsDraggingOnBoard
  );

  const { viewportWatcher } = useLocalStoragePersist(mediaGroupId);
  const { zoomWatcher } = useUpdateNodeInternalsOnZoom();
  const handleViewPortChange = useCallback(
    (viewPort: Viewport) => {
      viewportWatcher(viewPort);
      zoomWatcher(viewPort.zoom);
    },
    [viewportWatcher, zoomWatcher]
  );

  const [boardContextMenuCoordinates, setBoardContextMenuCoordinates] =
    useState<BoardContextMenuCoordinates>(null);

  const {
    addArticleNode,
    addAudioNode,
    addDocumentNode,
    addMediaNode,
    createDocumentNode,
    addFileNode,
    addFloatingTextNode,
    addHighlightNode,
    addImageNode,
    addPdfNode,
    addTempFileUploadNode,
    addVideoNode,
    selectedNodes,
    getNodesAtScreenPosition,
  } = useNodes();

  const { addFrame } = useFrames();
  const { onConnect } = useEdges();
  const { flowToScreenPosition, setViewport } = useReactFlow();
  const { selectedEdges } = useEdgeFloatingMenu();

  const [hasSelected, setHasSelected] = useState(
    !!(selectedNodes.length || selectedEdges.length)
  );
  const onSelectionChange = useCallback(
    ({ nodes, edges }: { nodes: Node[]; edges: Edge[] }) => {
      setHasSelected(!!(nodes.length || edges.length));
      setSelectedNodes?.(nodes.map((node) => node.id));
      setSelectedEdges?.(edges.map((edge) => edge.id));
    },
    [setHasSelected, setSelectedNodes, setSelectedEdges]
  );

  const handleSelectionStart = useCallback(() => {
    // Drag selection has started
    setIsDraggingOnBoard(true);
  }, [setIsDraggingOnBoard]);
  const handleSelectionEnd = useCallback(() => {
    setIsDraggingOnBoard(false);
  }, [setIsDraggingOnBoard]);

  const {
    shouldTriggerSaveFromNodeChanges,
    useDebouncedUpdateEntryNodes,
    useDebouncedUpdateEntryEdges,
  } = usePersist({ mediaGroupId });
  const debouncedUpdateEntryNodes = useDebouncedUpdateEntryNodes(0);
  const debouncedUpdateEntryEdges = useDebouncedUpdateEntryEdges(0);

  const handleNodeChanges = useCallback(
    (changes: NodeChange[]) => {
      if (!changes.length) return;

      // reset the helper lines (clear existing lines, if any)
      setHelperLineHorizontal(undefined);
      setHelperLineVertical(undefined);

      // this will be true if it's a single node being dragged
      // inside we calculate the helper lines and snap position for the position where the node is being moved to
      if (
        changes.length === 1 &&
        changes[0]?.type === 'position' &&
        changes[0].dragging &&
        changes[0].position
      ) {
        const helperLines = getHelperLines(changes[0], nodes);

        // if we have a helper line, we snap the node to the helper line position
        // this is being done by manipulating the node position inside the change object
        changes[0].position.x = helperLines.snapPosition.x ?? changes[0].position.x;
        changes[0].position.y = helperLines.snapPosition.y ?? changes[0].position.y;

        // if helper lines are returned, we set them so that they can be displayed
        setHelperLineHorizontal(helperLines.horizontal);
        setHelperLineVertical(helperLines.vertical);
      }

      onNodesChange(changes);

      const updatedNodes = applyNodeChanges(changes, nodes);

      // Check if is toggle between unknownNode and articleNode
      if (
        updatedNodes.length &&
        updatedNodes.filter((node) => node.type === 'unknownNode').length ===
          updatedNodes.length
      )
        return;

      // Check of added omitted props
      const normalizedNodes = normalizeNodes(nodes);
      const normalizedUpdatedNodes = normalizeNodes(updatedNodes);
      if (isEqual(normalizedNodes, normalizedUpdatedNodes)) return;

      if (shouldTriggerSaveFromNodeChanges(changes)) {
        const isOnlyDimensionChanges = !changes.filter(
          (change) => change.type !== 'dimensions'
        ).length;

        const isOnlyPositionChanges = !changes.filter(
          (change) => change.type !== 'position'
        ).length;

        if (isOnlyDimensionChanges) {
          const mediaGroupNodes = mediaGroup.board?.nodes ?? [];
          const nodesHaveChanged = nodes?.filter((node) => {
            const mediaGroupNode = mediaGroupNodes.find(
              (mediaGroupNode) => mediaGroupNode.id === node.id
            );

            if (!mediaGroupNode) return true;
            return !(
              node.width === mediaGroupNode.width &&
              node.height === mediaGroupNode.height
            );
          });

          if (nodesHaveChanged.length) {
            debouncedUpdateEntryNodes(updatedNodes, false);
          }

          return;
        }

        if (isOnlyPositionChanges) {
          debouncedUpdateEntryNodes(updatedNodes, false);
          return;
        }

        debouncedUpdateEntryNodes(updatedNodes, false);
      }
    },
    [
      onNodesChange,
      shouldTriggerSaveFromNodeChanges,
      nodes,
      mediaGroup,
      debouncedUpdateEntryNodes,
    ]
  );

  const handleEdgeChanges = useCallback(
    async (changes: EdgeChange[]) => {
      onEdgesChange(changes);

      if (
        !changes.filter((change) => {
          if (change.type === 'select') return false;

          return true;
        }).length
      )
        return;

      const updatedEdges = applyEdgeChanges(changes, edges);
      debouncedUpdateEntryEdges(updatedEdges, false);
    },
    [onEdgesChange, debouncedUpdateEntryEdges]
  );

  const ref = useRef<HTMLDivElement | null>(null);
  const { onNodeDragStart, onNodeDrag, onNodeDragStop } = useFrames();
  const { pause, replaceHistoryOfPendingNodes, resume } = useHistory();

  const handleNodeDragStart = useCallback(
    (ev: React.MouseEvent, node: Node) => {
      setIsDraggingOnBoard(true);
      onNodeDragStart(ev, node);
      pause();
    },
    [setIsDraggingOnBoard, onNodeDragStart, pause]
  );

  const handleNodeDrag = useCallback(
    (ev: React.MouseEvent, node: Node) => {
      onNodeDrag(ev, node);
    },
    [onNodeDrag]
  );

  const handleNodeDragStop = useCallback(() => {
    setIsDraggingOnBoard(false);
    onNodeDragStop();
    resume();
  }, [setIsDraggingOnBoard, onNodeDragStop, resume]);

  useBoardShortKeys();

  const { x: viewportX, y: viewportY, zoom } = useViewport();

  const handleKeydown = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === ' ' && panOnDrag) {
        setPanOnDrag(true);
      }

      return false;
    },
    [panOnDrag, setPanOnDrag]
  );

  const handleKeyup = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === ' ' && !panOnDrag) {
        setPanOnDrag(false);
      }

      return false;
    },
    [panOnDrag, setPanOnDrag]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('keyup', handleKeyup);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('keyup', handleKeyup);
    };
  }, []);

  // File upload
  const createTempUploadNode = useCallback(
    ({
      refId,
      coordinates,
      options,
    }: {
      refId: string;
      coordinates: { x: number; y: number };
      options?: { parentId?: string };
    }) => {
      addTempFileUploadNode(refId, coordinates.x, coordinates.y, options);
    },
    [addTempFileUploadNode]
  );

  const removeTempUploadNode = useCallback(
    ({ refId }: { refId: string }) => {
      setNodes((nodes) =>
        nodes.filter((node) => {
          if (node.type !== 'tempFileUploadNode') return true;
          return node.data.id !== refId;
        })
      );
    },
    [setNodes]
  );

  const { handleDrop: handleFileDrop, mediaGroupIds: newFileIds } =
    useFileUploadToBoard({
      mediaGroup,
      createTemporaryNode: ({
        refId,
        coordinates,
        options,
      }: {
        refId: string;
        coordinates: { x: number; y: number };
        options?: { parentId?: string };
      }) => createTempUploadNode({ refId, coordinates, options }),
      removeTemporaryNode: (refId: string) => removeTempUploadNode({ refId }),
    });

  useEffect(() => {
    const pendingNodes: Node[] = nodes.filter((node) => {
      const nodeId = node.data.id;
      if (!nodeId || typeof nodeId !== 'string') return false;
      if (node.type !== 'tempFileUploadNode') return false;

      return nodeId in newFileIds;
    });

    if (pendingNodes.length) {
      const pendingNodeIds = pendingNodes.map((node) => node.id);

      const replacedNodesWithKeys: { key: string; node: UnknownNodeType }[] = nodes
        .map((node) => {
          if (!pendingNodeIds.includes(node.id)) return null;
          const mediaGroupIdKey = node.data.id;
          if (!mediaGroupIdKey || typeof mediaGroupIdKey !== 'string') return null;
          const mediaGroupId = newFileIds[mediaGroupIdKey];
          if (!mediaGroupId || typeof mediaGroupId !== 'string') return null;

          const { x, y } = flowToScreenPosition({
            x: node.position.x,
            y: node.position.y,
          });

          return {
            key: mediaGroupIdKey,
            node: addMediaNode(mediaGroupId, x, y, {
              appendNode: false,
              parentId: node.parentId,
            }),
          };
        })
        .filter(exists);

      setNodes((nodes) =>
        nodes.map((node) => {
          return (
            replacedNodesWithKeys.find(
              (replacedNode) => replacedNode.key === node.data.id
            )?.node ?? node
          );
        })
      );

      replaceHistoryOfPendingNodes(replacedNodesWithKeys);
    }
  }, [newFileIds]);

  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (ev) => {
      const { clientX: x, clientY: y } = ev;
      const isInPane = (ev.target as Element).classList.contains('react-flow__pane');

      if (actionMenuMode === 'select') {
        setBoardContextMenuCoordinates(null);
        return false;
      }

      if (actionMenuMode === 'document') {
        if (isInPane) {
          setActionMenuMode('select');
          createDocumentNode(x, y);
        }
      }

      if (actionMenuMode === 'text') {
        if (isInPane) {
          setActionMenuMode('select');
          addFloatingTextNode(x, y);
        }
      }

      if (actionMenuMode === 'frame') {
        if (isInPane) {
          setActionMenuMode('select');
          addFrame(x, y);
        }
      }
    },
    [
      actionMenuMode,
      setBoardContextMenuCoordinates,
      setActionMenuMode,
      createDocumentNode,
      addFloatingTextNode,
      addFrame,
    ]
  );

  const handleMouseDown = useCallback<MouseEventHandler<HTMLDivElement>>(
    (ev) => {
      const isInPane = (ev.target as Element).classList.contains('react-flow__pane');

      if (ev.button === 1 && isInPane) {
        setPanOnDrag(true);
      }
    },
    [setPanOnDrag]
  );

  const handleMouseUp = useCallback<MouseEventHandler<HTMLDivElement>>(
    (ev) => {
      const isInPane = (ev.target as Element).classList.contains('react-flow__pane');

      if (ev.button === 1 && isInPane) {
        setPanOnDrag(false);
      }
    },
    [setPanOnDrag]
  );

  const handleMouseMove = useCallback<MouseEventHandler<HTMLDivElement>>(
    (ev) => {
      const isInPane = (ev.target as Element).classList.contains('react-flow__pane');

      if (
        ev.button === 0 &&
        ev.movementX &&
        ev.movementY &&
        ev.buttons > 0 &&
        isInPane &&
        panOnDrag
      ) {
        (ev.target as HTMLDivElement).classList.add('dragging');
        setViewport({
          x: viewportX + ev.movementX,
          y: viewportY + ev.movementY,
          zoom,
        });
      }
    },
    [panOnDrag, setViewport, zoom, viewportX, viewportY]
  );

  const handleDrop = useCallback<DragEventHandler<HTMLDivElement>>(
    (ev) => {
      const posX = ev.clientX;
      const posY = ev.clientY;

      const targetFrame = getNodesAtScreenPosition(posX, posY).find(
        ({ type }) => type === 'groupNode'
      );

      const mediaGroupData = ev.dataTransfer?.getData('mediaGroup');

      if (mediaGroupData) {
        let mediaGroup = null;
        try {
          mediaGroup = JSON.parse(mediaGroupData) as Partial<MediaGroupDTO>;
        } catch (ex) {
          console.error('Could not parse media group data', ex);
        }

        if (!mediaGroup?.id) {
          return false;
        }

        if (mediaGroup.id === mediaGroupId) {
          return false;
        }

        const { kind, contentType } = mediaGroup;

        if (
          contentType === 'article' ||
          contentType === 'bookmark' ||
          contentType === 'social' ||
          contentType === 'wiki'
        ) {
          addArticleNode(mediaGroup.id, posX, posY, { parentId: targetFrame?.id });
          return true;
        }

        if (contentType === 'audio') {
          if (kind === 'gallery') {
            addAudioNode(mediaGroup.id, posX, posY, { parentId: targetFrame?.id });
          } else {
            addArticleNode(mediaGroup.id, posX, posY, {
              parentId: targetFrame?.id,
            });
          }
          return true;
        }

        if (contentType === 'document') {
          addDocumentNode(mediaGroup.id, posX, posY, { parentId: targetFrame?.id });
          return true;
        }

        if (contentType === 'highlight' || contentType === 'quote') {
          addHighlightNode(mediaGroup.id, posX, posY, {
            parentId: targetFrame?.id,
          });
          return true;
        }

        if (contentType === 'image') {
          addImageNode(mediaGroup.id, posX, posY, { parentId: targetFrame?.id });
          return true;
        }

        if (contentType === 'video') {
          addVideoNode(mediaGroup.id, posX, posY, { parentId: targetFrame?.id });
          return true;
        }

        if (contentType === 'pdf') {
          addPdfNode(mediaGroup.id, posX, posY, { parentId: targetFrame?.id });
          return true;
        }

        if (contentType === 'file') {
          addFileNode(mediaGroup.id, posX, posY, { parentId: targetFrame?.id });
          return true;
        }

        return false;
      }

      // File drop
      return handleFileDrop(ev, posX, posY, { parentId: targetFrame?.id });
    },
    [
      getNodesAtScreenPosition,
      addArticleNode,
      addAudioNode,
      addDocumentNode,
      addHighlightNode,
      addImageNode,
      addVideoNode,
      addPdfNode,
      addFileNode,
      handleFileDrop,
    ]
  );

  const handleMove = useCallback(() => {
    setBoardContextMenuCoordinates(null);
  }, [setBoardContextMenuCoordinates]);

  const handlePaneContextMenu = useCallback(
    (ev: MouseEvent | React.MouseEvent) => {
      ev.preventDefault();
      const { clientX, clientY } = ev;
      setBoardContextMenuCoordinates({ x: clientX, y: clientY });
    },
    [setBoardContextMenuCoordinates]
  );

  const handleConnectStart = useCallback(() => {
    setIsDraggingHandle(true);
  }, [setIsDraggingHandle]);

  const handleConnectEnd = useCallback(() => {
    setIsDraggingHandle(false);
  }, [setIsDraggingHandle]);

  if (!boardContext) return null;

  return (
    <div className={styles.container}>
      <Markers />
      <ReactFlow
        ref={ref}
        minZoom={0.05}
        edges={edges}
        edgeTypes={edgeTypes}
        nodes={nodes}
        nodeTypes={nodeTypes}
        multiSelectionKeyCode={'Shift'}
        deleteKeyCode={DELETE_KEY_CODE}
        onClick={handleClick}
        onConnectEnd={handleConnectEnd}
        onConnectStart={handleConnectStart}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onConnect={onConnect}
        onDragOver={preventDefault}
        onDrop={handleDrop}
        onEdgesChange={handleEdgeChanges}
        onMove={handleMove}
        onNodeDragStart={handleNodeDragStart}
        onNodeDrag={handleNodeDrag}
        onNodeDragStop={handleNodeDragStop}
        onNodesChange={handleNodeChanges}
        onPaneContextMenu={handlePaneContextMenu}
        onSelectionChange={onSelectionChange}
        onSelectionStart={handleSelectionStart}
        onSelectionEnd={handleSelectionEnd}
        onViewportChange={handleViewPortChange}
        panOnDrag={panOnDrag}
        proOptions={PRO_OPTIONS}
        selectionOnDrag={!panOnDrag}
        selectionMode={SelectionMode.Partial}
        zoomOnDoubleClick={false}
        zoomOnScroll={!panOnDrag}
      >
        <ZoomControls />
        <ActionMenu hasSelected={hasSelected} mediaGroup={mediaGroup} />
        <SupportButton />
        <BoardContextMenu coordinates={boardContextMenuCoordinates} />
        <EdgeStyleMenu selectedEdges={selectedEdges} />
        <HelperLines horizontal={helperLineHorizontal} vertical={helperLineVertical} />
      </ReactFlow>
    </div>
  );
}

function normalizeNodes(nodes: Node[]) {
  return nodes.map((node) => {
    // Removed unsaved props
    const { measured, ...updatedNode } = node;
    return updatedNode;
  });
}

const Markers = () => {
  return (
    <svg
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.customMarkers}
    >
      <defs>
        {connectionLineColors.map((color) => {
          return (
            <Fragment key={color}>
              <marker
                id={`markerTypeCircle${upperFirst(color)}`}
                viewBox="0 0 10 10"
                markerHeight={10}
                markerWidth={10}
                refX={5}
                refY={5}
                fill={connectionLineColorsAsHex[color]}
              >
                <circle cx="5" cy="5" r="5" />
              </marker>
              <marker
                id={`markerTypeDiamond${upperFirst(color)}`}
                viewBox="0 0 10 10"
                markerHeight={10}
                markerWidth={10}
                refX={5}
                refY={5}
                fill={connectionLineColorsAsHex[color]}
              >
                <polygon points="0 5,5 10,10 5,5 0" />
              </marker>
            </Fragment>
          );
        })}
      </defs>
    </svg>
  );
};
