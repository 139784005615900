import { Slot } from '@radix-ui/react-slot';
import { clsx } from 'clsx';
import camelCase from 'lodash/camelCase';
import { type ComponentProps, forwardRef } from 'react';
import { Link } from 'react-router';

import { css } from '@/lib/css';
import styles from './Button.module.scss';

export type ButtonVariant =
  | 'danger'
  | 'emoji'
  | 'ghost'
  | 'icon'
  | 'menu'
  | 'link'
  | 'outlined'
  | 'primary';

type BaseButtonProps = {
  asChild?: boolean;
  children?: React.ReactNode;
  className?: string;
  iconBefore?: React.ReactNode;
  iconAfter?: React.ReactNode;
  iconAbove?: React.ReactNode;
  isFullWidth?: boolean;
  isMuted?: boolean;
  isSquare?: boolean;
  padX?: string;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  shortKeys?: React.ReactNode;
  variant?: ButtonVariant;
  disabled?: boolean;
};

export type ButtonProps = BaseButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

const getClassNames = ({
  iconBefore,
  iconAfter,
  size,
  variant,
  className,
  isFullWidth,
  isMuted,
  isSquare,
}: BaseButtonProps) => {
  let iconClassNames = '';
  if (iconBefore && iconAfter) {
    iconClassNames = 'btnIcons';
  } else if (iconBefore) {
    iconClassNames = 'btnIconLeft';
  } else if (iconAfter) {
    iconClassNames = 'btnIconRight';
  }

  return clsx(
    'btn',
    iconClassNames,
    camelCase(`btn-${size ?? 'md'}${(isSquare ?? false) ? 'Square' : ''}`),
    camelCase(`btn-${variant ?? 'primary'}`),
    isMuted && 'btnIsMuted',
    isFullWidth && 'fullWidth',
    className
  );
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      asChild = false,
      children,
      className,
      iconBefore,
      iconAfter,
      iconAbove,
      isFullWidth,
      isMuted,
      isSquare,
      padX,
      shortKeys,
      size,
      variant,
      ...rest
    }: ButtonProps,
    ref
  ) => {
    const Component = asChild ? Slot : 'button';

    return (
      <Component
        ref={ref}
        className={getClassNames({
          iconBefore,
          iconAfter,
          iconAbove,
          size,
          variant,
          className,
          isFullWidth,
          isMuted,
          isSquare,
        })}
        style={padX ? css({ '--padding-x': `var(--size-${padX})` }) : undefined}
        {...rest}
      >
        <>
          {iconBefore && <span className="icon iconBefore">{iconBefore}</span>}
          {iconAbove ? (
            <div className={styles.iconAboveContainer}>
              <span className="icon iconAbove">{iconAbove}</span>
              {children}
            </div>
          ) : (
            children
          )}
          {iconAfter && <span className="icon iconAfter">{iconAfter}</span>}
          <ShortKeys shortKeys={shortKeys} />
        </>
      </Component>
    );
  }
);

const ShortKeys = ({ shortKeys }: { shortKeys?: React.ReactNode }) => {
  if (!shortKeys) return null;

  if (Array.isArray(shortKeys)) {
    return (
      <span className="shortKeys">
        {shortKeys.map((char, idx) => (
          <span className="shortKey" key={idx}>
            {char}
          </span>
        ))}
      </span>
    );
  }

  return (
    <span className="shortKeys">
      <span className="shortKey">{shortKeys}</span>
    </span>
  );
};

type ButtonLinkProps = Omit<BaseButtonProps, 'asChild'> & ComponentProps<typeof Link>;

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      children,
      className,
      iconBefore,
      iconAfter,
      isMuted,
      isSquare,
      shortKeys,
      size,
      variant,
      ...rest
    },
    ref
  ) => {
    return (
      <Link
        ref={ref}
        className={getClassNames({
          iconBefore,
          iconAfter,
          size,
          variant,
          className,
          isMuted,
          isSquare,
        })}
        {...rest}
      >
        <>
          {iconBefore && <span className="icon">{iconBefore}</span>}
          {children}
          {iconAfter && <span className="icon">{iconAfter}</span>}
          {shortKeys && <span className="shortKeys">{shortKeys}</span>}
        </>
      </Link>
    );
  }
);

export default Button;
