import clsx from 'clsx';
import { useState } from 'react';

import type {
  MediaGroupDTO,
  MediaGroupSortOption,
  MediaGroupStatus,
} from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';

import { useListMediaGroups } from '@api/mediaGroup';
import { useProject } from '@api/project';
import {
  ContentType,
  MediaGroupStatus as MediaGroupStatusIcon,
} from '@components/icons';
import LoadingPlaceholder from '@components/LoadingPlaceholder';
import { useMediaGroupContextMenu } from '@components/MediaGroupActionMenu';
import { NewMediaGroupDropdown } from '@components/NewMediaGroupDropdown';
import { useBackgroundLocation } from '@hooks/useBackgroundLocation';
import { useCheckMediaGroupUpdated } from '@hooks/useCheckMediaGroupUpdated';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Breadcrumb from '@ui/Breadcrumb';
import Button from '@ui/Button';
import Checkbox from '@ui/Checkbox';
import ContextMenu from '@ui/ContextMenu';
import { RecursiveDropdownMenu } from '@ui/DropdownMenu';
import OptionalLink from '@ui/OptionalLink';
import UserAvatar from '@ui/UserAvatar';
import { urlFor } from '@/urls';
import styles from './List.module.scss';

const { Add } = Icon16;
const { Down, Up, Menu, ProjectCategory } = Icon24;

const MediaGroupInStatus = ({
  mediaGroup,
  selected,
  onToggleSelected,
  menuEnabled,
}: {
  mediaGroup: MediaGroupDTO;
  selected: boolean;
  onToggleSelected: () => void;
  menuEnabled: boolean;
}) => {
  const backgroundLocation = useBackgroundLocation();
  const currentLocation = `${location.pathname}${location.search}${location.hash}`;
  const mgMenuItems = useMediaGroupContextMenu({ mediaGroup });
  return (
    <div className={clsx(styles.mediaGroupListing, selected ? styles.selected : null)}>
      <div className={styles.mediaGroupInfo}>
        <Checkbox checked={selected} size="sm" onChange={() => onToggleSelected()} />
        <Breadcrumb
          breadcrumb={[
            {
              children: (
                <ContextMenu items={mgMenuItems}>
                  <OptionalLink
                    state={{
                      backgroundLocation: [currentLocation, ...backgroundLocation],
                    }}
                    href={urlFor('mediaGroup', { mediaGroupId: mediaGroup.id })}
                  >
                    <span className={styles.text} title={mediaGroup.label || undefined}>
                      {mediaGroup.isGeneratingContent
                        ? 'Generating info...'
                        : mediaGroup.label}
                    </span>
                  </OptionalLink>
                </ContextMenu>
              ),
            },
            {
              text: mediaGroup.category?.label || 'Uncategorized',
              icon: <ProjectCategory size={16} />,
            },
            {
              text: mediaGroup.contentType,
              icon: <ContentType contentType={mediaGroup.contentType} />,
            },
          ]}
          nav={false}
          className={styles.breadcrumb}
          showTitle
        />
      </div>
      <div className={styles.mediaGroupActions}>
        {menuEnabled && (
          <RecursiveDropdownMenu
            items={mgMenuItems}
            dropdownMenuProps={{ isPadded: true }}
          >
            <Button variant="ghost">
              <Menu />
            </Button>
          </RecursiveDropdownMenu>
        )}
        <MediaGroupStatusIcon status={mediaGroup.status} />
        <UserAvatar
          name={mediaGroup.author.name}
          size="xs"
          imageUrl={mediaGroup.author.avatarUrl}
        />
      </div>
    </div>
  );
};

const StatusFeed = ({
  status,
  selectedMediaGroupIds,
  onSelected,
  projectId,
  sortBy,
}: {
  status: MediaGroupStatus;
  selectedMediaGroupIds: Set<string>;
  onSelected: (selected: MediaGroupDTO) => void;
  projectId: string;
  sortBy: MediaGroupSortOption;
}) => {
  const workspaceIid = useWorkspaceId();
  const [expanded, setExpanded] = useState(true);
  const {
    data: mediaGroupData,
    isLoading: mediaGroupIsLoading,
    fetchNextPage: mediaGroupFetchNextPage,
    hasNextPage: mediaGroupHasNextPage,
    enabled: mediaGroupIsEnabled,
    isFetchingNextPage: mediaGroupIsFetchingNextPage,
  } = useListMediaGroups(
    workspaceIid,
    {
      status: [status.id],
      project: [projectId],
      sort: sortBy,
    },
    expanded
  );
  const mediaGroups = mediaGroupData?.pages?.flatMap((page) => page.mediaGroups) || [];

  const updatedMediaGroupMap = useCheckMediaGroupUpdated(mediaGroups);

  return (
    <div>
      <div className={styles.status}>
        <div className={styles.statusInfo}>
          <MediaGroupStatusIcon status={status} />
          <span className={styles.statusLabel}>{status.label}</span>
          <span className={styles.statusItemCount}>{status.itemsCount}</span>
          <Button
            disabled={!status.itemsCount}
            className={styles.chevron}
            onClick={() => setExpanded(!expanded)}
            variant="icon"
          >
            {(!expanded || !status.itemsCount) && <Down size={24} />}
            {expanded && !!status.itemsCount && <Up size={24} />}
          </Button>
        </div>
        <div>
          <NewMediaGroupDropdown mediaGroupAttributes={{ statusId: status.id }}>
            <Button variant="ghost">
              <Add />
            </Button>
          </NewMediaGroupDropdown>
        </div>
      </div>
      {expanded && (
        <>
          {mediaGroups.length > 0 && (
            <div>
              {mediaGroups.map((mediaGroup) => {
                const mg = updatedMediaGroupMap[mediaGroup.id] || mediaGroup;
                return (
                  <MediaGroupInStatus
                    selected={selectedMediaGroupIds.has(mg.id)}
                    menuEnabled={selectedMediaGroupIds.size === 0}
                    onToggleSelected={() => onSelected(mg)}
                    key={mg.id}
                    mediaGroup={mg}
                  />
                );
              })}
            </div>
          )}
          {(mediaGroupIsLoading ||
            !mediaGroupIsEnabled ||
            mediaGroupIsFetchingNextPage) && (
            <div className={styles.loadingPlaceholder}>
              <LoadingPlaceholder />
            </div>
          )}
          {mediaGroupHasNextPage && !mediaGroupIsFetchingNextPage && (
            <Button
              variant="link"
              className={styles.loadMoreButton}
              onClick={() => {
                if (mediaGroupHasNextPage) {
                  mediaGroupFetchNextPage();
                }
              }}
            >
              Load more
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export const ProgressList = ({
  projectId,
  onSelected,
  selectedMediaGroupIds,
  sortBy,
}: {
  projectId: string;
  selectedMediaGroupIds: Set<string>;
  onSelected: (mediaGroup: MediaGroupDTO) => void;
  sortBy: MediaGroupSortOption;
}) => {
  const { data: project } = useProject(projectId);

  return (
    <div>
      {project?.project.mediaGroupStatuses.map((status, i) => {
        return (
          <StatusFeed
            selectedMediaGroupIds={selectedMediaGroupIds}
            onSelected={onSelected}
            status={status}
            projectId={projectId}
            sortBy={sortBy}
            key={i}
          />
        );
      })}
    </div>
  );
};
