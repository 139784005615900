import * as RadixTabs from '@radix-ui/react-tabs';
import { clsx } from 'clsx';
import {
  type ComponentProps,
  type ForwardedRef,
  forwardRef,
  type PropsWithChildren,
  type ReactNode,
} from 'react';

import TabButton, {
  type TabButtonProps,
  type TabButtonVariants,
  TabButtonWrapper,
  type TabButtonWrapperProps,
} from '@ui/TabButton';
import styles from './Tabs.module.scss';
import Tooltip from './Tooltip';

export type Tab<T extends string> = {
  content: React.ReactNode;
  icon?: React.ReactNode;
  isActive?: boolean;
  ref?: React.RefObject<HTMLButtonElement>;
  title?: React.ReactNode;
  tooltip?: ReactNode;
  tooltipSide?: ComponentProps<typeof Tooltip>['side'];
  value?: T;
};

type TabsProps<T extends string> = {
  activeTab?: string;
  className?: string;
  contentClassName?: string;
  label?: string;
  onClick?: (value?: string) => void;
  orientation?: 'horizontal' | 'vertical';
  setActiveTab?: (value: T) => void;
  tabs: Tab<T>[];
  tabButtonProps?: TabButtonProps;
  tabWrapperProps?: Omit<TabButtonWrapperProps, 'children'>;
};

export default function Tabs<T extends string>({
  activeTab,
  className,
  contentClassName,
  label,
  onClick,
  orientation = 'horizontal',
  setActiveTab,
  tabs,
  tabButtonProps,
  tabWrapperProps,
}: TabsProps<T>) {
  if (!tabs.length) return;

  const defaultValue = (tabs.findIndex((tab) => tab.isActive) ?? 0).toString();
  const handleTabChange = (value: string) => {
    setActiveTab?.(value as T);
  };

  return (
    <RadixTabs.Root
      className={clsx(styles.tabsRoot, styles[orientation], className)}
      defaultValue={defaultValue}
      onValueChange={handleTabChange}
      orientation={orientation}
      value={activeTab}
    >
      <Triggers
        label={label}
        onClick={onClick}
        tabButtonProps={tabButtonProps}
        tabs={tabs}
        tabWrapperProps={tabWrapperProps}
      />
      {tabs.map(({ content, value }, idx) => (
        <RadixTabs.Content
          className={clsx(styles.tabsContent, contentClassName)}
          key={idx}
          value={value ?? idx.toString()}
        >
          {content}
        </RadixTabs.Content>
      ))}
    </RadixTabs.Root>
  );
}

type TriggersProps<T extends string> = {
  onClick?: (value?: string) => void;
  label?: string;
  tabs: Tab<T>[];
  tabButtonProps?: TabButtonProps;
  tabWrapperProps?: Omit<TabButtonWrapperProps, 'children'>;
};

const Triggers = <T extends string>({
  onClick,
  label,
  tabs,
  tabButtonProps,
  tabWrapperProps,
}: TriggersProps<T>) => {
  return (
    <RadixTabs.List aria-label={label} asChild>
      <TabButtonWrapper
        {...tabWrapperProps}
        variant={tabWrapperProps?.variant ?? 'dark'}
      >
        {tabs.map((tab, idx) => (
          <Trigger
            onClick={() => onClick?.(tab.value)}
            idx={idx}
            key={idx}
            ref={tab.ref}
            tab={tab}
            tabButtonProps={tabButtonProps}
            variant={tabWrapperProps?.variant}
          />
        ))}
      </TabButtonWrapper>
    </RadixTabs.List>
  );
};

type TriggerProps<T extends string> = {
  idx: number;
  onClick?: () => void;
  tab: Tab<T>;
  tabButtonProps?: TabButtonProps;
  variant?: TabButtonVariants;
};

const TriggerComponent = <T extends string>(
  { idx, onClick, tab, tabButtonProps, variant }: TriggerProps<T>,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const { icon, title, value, tooltip, tooltipSide } = tab;

  return (
    <RadixTabs.Trigger
      asChild
      onClick={onClick}
      ref={ref}
      value={value ?? idx.toString()}
    >
      <TabButton {...tabButtonProps} variant={variant ?? 'dark'}>
        <OptionalTooltip content={tooltip} side={tooltipSide}>
          {icon}
          {title}
        </OptionalTooltip>
      </TabButton>
    </RadixTabs.Trigger>
  );
};

type OptionalTooltipProps = PropsWithChildren<{
  content: ReactNode;
  side?: ComponentProps<typeof Tooltip>['side'];
}>;

const OptionalTooltip = forwardRef<HTMLDivElement, OptionalTooltipProps>(
  ({ content, side = 'left', children }, ref) => {
    if (!content) {
      return <div ref={ref}>{children}</div>;
    }

    return (
      <div ref={ref}>
        <Tooltip content={content} side={side}>
          {/* Tooltip child must be `Slot`-able, so wrap in div to ensure that */}
          <div>{children}</div>
        </Tooltip>
      </div>
    );
  }
);

const Trigger = forwardRef(TriggerComponent) as <T extends string>(
  props: TriggerProps<T> & { ref?: React.ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof TriggerComponent>;
