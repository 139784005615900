import { type ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';

import type { Chat, MediaGroupDTO } from '@spaceduck/api';
import { exists } from '@spaceduck/utils';
import { Icon24 } from '@spaceduck/icons';

import { useCreateChatSession, useGetChatSession, useQueryChatSession } from '@api/ai';
import { toastApiErrorOr } from '@api/util';
import ChatMessageInput from '@components/ai/ChatMessageInput';
import { ChatHistory } from '@components/ai/ChatHistory';
import Spinner from '@components/Spinner';
import { ChatContent, SourcesPanel } from '@pages/ai/ResearchAssistant';
import Button from '@ui/Button';
import styles from './ResearchChat.module.scss';
import type { SelectedSourceType } from '@/components/ai/useSourcesStore';
import { useInteractWithChatMessage } from '@/hooks/useInteractWithChatMessage';

const { ResearchChatAI, ChatHistory: ChatHistoryIcon, Close } = Icon24;

type OnSubmitChatMessage = ComponentProps<typeof ChatMessageInput>['onSubmit'];

export const ResearchChat = ({
  addToCurrentDocument,
  mediaGroup,
}: {
  addToCurrentDocument: (text: string) => void;
  mediaGroup: MediaGroupDTO;
}) => {
  const projectId = mediaGroup.project?.id;
  const { mutateAsync: queryChatSessionInternal } = useQueryChatSession();
  const { mutateAsync: queryChatSession } = useInteractWithChatMessage(
    queryChatSessionInternal
  );
  const { mutateAsync: createChatSessionInternal } = useCreateChatSession();
  const { mutateAsync: createChatSession, reset } = useInteractWithChatMessage(
    createChatSessionInternal
  );
  const [chatId, setChatId] = useState<string | null>(null);
  const { data: chatSessionData, isLoading } = useGetChatSession(chatId);
  const [showChatHistory, setShowChatHistory] = useState(false);
  const [showSources, setShowSources] = useState(false);
  const [currentChatSession, setCurrentChatSession] = useState<Chat | null>(null);

  useEffect(() => {
    if (chatSessionData) {
      if (!isEqual(chatSessionData.chat, currentChatSession)) {
        setCurrentChatSession(chatSessionData.chat);
      }
      return;
    }

    setCurrentChatSession(null);
  }, [chatSessionData]);

  const createNewChatSession = useCallback<OnSubmitChatMessage>(
    async (query, mediaGroups, links, temporaryFiles, model) => {
      if (!projectId) {
        return false;
      }
      setCurrentChatSession({
        projectId,
        id: 'temp',
        label: query,
        mediaGroups,
        messages: [
          {
            id: '',
            pending: true,
            query,
            response: {
              parts: [],
            },
          },
        ],
        provider: model,
      });
      let response: Awaited<ReturnType<typeof createChatSession>>;
      try {
        response = await createChatSession({
          projectId,
          provider: model,
          mediaGroupIds: mediaGroups.map((mediaGroup) => mediaGroup.id),
          links: links.map((link) => link.id).filter(exists),
          temporaryFiles: temporaryFiles.map((file) => file.id),
          initialQuery: query,
        });
      } catch (error) {
        toastApiErrorOr(error, 'Failed to create chat session', {
          iconVariant: 'warning',
          titleText: 'Chat Session Error',
          bodyText:
            'An unknown error occurred while creating chat session. Please try again later',
        });
        setCurrentChatSession(null);
        reset();
        return false;
      }
      setChatId(response.chatSessionId);
      return true;
    },
    [projectId, createChatSession, reset]
  );

  const addQueryToExistingSession = useCallback<OnSubmitChatMessage>(
    async (query) => {
      if (!currentChatSession) {
        return false;
      }
      setCurrentChatSession({
        ...currentChatSession,
        messages: [
          ...currentChatSession.messages,
          {
            id: '',
            pending: true,
            query,
            response: {
              parts: [],
            },
          },
        ],
      });
      try {
        await queryChatSession({
          chatId: currentChatSession.id,
          query,
        });
      } catch (error) {
        toastApiErrorOr(error, 'Error creating chat session', {
          iconVariant: 'warning',
          titleText: 'Failed to add message',
          bodyText:
            'Unknown error occurred while adding your message. Please try again later.',
        });
      }
      return false;
    },
    [currentChatSession]
  );

  const handleCloseHistory = useCallback(() => {
    setShowChatHistory(false);
  }, []);

  const handleOpenHistory = useCallback(() => {
    setShowChatHistory(true);
  }, []);

  const handleSelectChatSession = useCallback((chatId: string) => {
    setChatId(chatId);
    setShowChatHistory(false);
  }, []);

  const handleNewChat = useCallback(() => {
    setCurrentChatSession(null);
    setChatId(null);
  }, []);

  const handleExpandSources = useCallback(() => {
    setShowSources(true);
  }, []);

  const handleCollapseSources = useCallback(() => {
    setShowSources(false);
  }, []);

  const initialSources = useMemo<SelectedSourceType[]>(
    () =>
      !currentChatSession
        ? [
            {
              id: mediaGroup.id,
              label: mediaGroup.label,
              type: 'library',
            },
          ]
        : [],
    [currentChatSession, mediaGroup.id, mediaGroup.label]
  );

  if (!projectId) {
    return;
  }

  if (isLoading) {
    return <Spinner />;
  }
  if (showChatHistory) {
    return (
      <ChatHistoryPanel
        projectId={projectId}
        onClose={handleCloseHistory}
        onSelect={handleSelectChatSession}
      />
    );
  }

  if (showSources && currentChatSession) {
    return (
      <SourcesPanel
        onClose={handleCollapseSources}
        sources={currentChatSession.mediaGroups}
        title={currentChatSession.label}
      />
    );
  }

  return (
    <div className={styles.researchChat}>
      <div className={styles.header}>
        <span>Research assistant</span>
        <div className={styles.actions}>
          <Button variant="outlined" size="sm" onClick={handleNewChat}>
            New chat
          </Button>
          <Button variant="outlined" padX="2" size="sm" onClick={handleOpenHistory}>
            <ChatHistoryIcon size={20} />
          </Button>
        </div>
      </div>
      <div className={styles.chatContent}>
        {currentChatSession && (
          <ChatContent
            addResponseAction={addToCurrentDocument}
            chatId={currentChatSession.id}
            chatProvider={currentChatSession.provider}
            expandSources={handleExpandSources}
            hasPreviousMessages={!!currentChatSession}
            messages={currentChatSession.messages}
            showSourcesCount={2}
            sources={currentChatSession.mediaGroups}
          />
        )}
        {!currentChatSession && (
          <div className={styles.emptyChat}>
            <ResearchChatAI />
            <p>
              Hello! I am your AI research assistant. I have access to your library and
              the web. Try asking me some questions...
            </p>
          </div>
        )}
      </div>
      <div className={styles.chatMessageInput}>
        <ChatMessageInput
          currentChatSession={currentChatSession}
          initialSources={initialSources}
          projectId={projectId}
          readOnly={!!currentChatSession}
          onSubmit={
            currentChatSession ? addQueryToExistingSession : createNewChatSession
          }
          showTools={!currentChatSession}
        />
      </div>
    </div>
  );
};

const ChatHistoryPanel = ({
  projectId,
  onClose,
  onSelect,
}: { projectId: string; onClose: () => void; onSelect: (chatId: string) => void }) => {
  return (
    <div className={styles.chatHistory}>
      <div className={styles.header}>
        <span>Chat history</span>
        <Button size="sm" padX="2" variant="outlined" onClick={onClose}>
          <Close size={16} />
        </Button>
      </div>
      <ChatHistory projectId={projectId} onSelectChatSession={onSelect} />
    </div>
  );
};
