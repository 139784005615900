import { isProject, type MediaGroupDTO } from '@spaceduck/api';
import clsx from 'clsx';
import {
  type ChangeEvent,
  type KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styles from './MediaGroupActionMenu.module.scss';
import type { ContextMenuItemProps } from './ui/ContextMenu';

import {
  useCopyMediaGroup,
  useDeleteMediaGroup,
  usePatchMediaGroup,
  useRegenerateMediaGroupThumbnail,
} from '@/api/mediaGroup';
import { useListMediaGroupCategories, useProject } from '@/api/project';
import { isCapable, toastApiErrorOr } from '@/api/util';
import { activeProjectKeys } from '@/const';
import useDeleteMediaGroupConfirmModal from '@/hooks/useDeleteMediaGroupConformModal';
import useMoveMediaGroupConfirmModal from '@/hooks/useMoveMediaGroupConfirmModal';
import useWorkspaceId from '@/hooks/useWorkspaceId';
import createToast from '@/utils/createToast';
import { debounce } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { CategoryMenuItem } from './CategoryMenu';
import { makeAddToProjectMenu } from './MediaGroupGrid';
import { StatusMenuItem } from './StatusMenu';
import { useConfirmModal } from './ui/ConfirmModal';
import { useWorkspaceProjects } from '@/api/workspace';
import MenuSearch from './MenuSearch';
import { stringContains } from '@/utils/string';

const FILTER_TEXT_DEBOUNCE = 250;

const stopPropagationOnEscape = (ev: KeyboardEvent) => {
  if (ev.key !== 'Escape') {
    ev.stopPropagation();
  }
};

const useCategorySubMenu = ({
  onCategoryChange,
  projectId,
  selectedCategoryId,
}: {
  onCategoryChange: (categoryId: string | null) => void;
  projectId?: string;
  selectedCategoryId?: string;
}) => {
  const handleCategoryChange = useCallback(
    (id: string | null) => onCategoryChange(id),
    [onCategoryChange]
  );
  const [categoryFilterText, setCategoryFilterText] = useState('');
  const debouncedFilterTextChange = useMemo(
    () =>
      setCategoryFilterText
        ? debounce(setCategoryFilterText, FILTER_TEXT_DEBOUNCE)
        : null,
    [setCategoryFilterText]
  );
  const [tempCategoryFilterText, setTempCategoryFilterText] = useState('');
  const handleCategoryFilterTextChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setTempCategoryFilterText(ev.currentTarget.value);
    debouncedFilterTextChange?.(ev.currentTarget.value);
  };

  const {
    data: categoryListing,
    hasNextPage: hasNextCategoryPage,
    isFetchingNextPage: isFetchingNextCategoryPage,
    fetchNextPage: fetchNextCategoryPage,
  } = useListMediaGroupCategories(projectId ?? null, categoryFilterText);
  const { ref: categoryLoaderRef, inView: categoryLoaderInView } = useInView();

  useEffect(() => {
    if (categoryLoaderInView && hasNextCategoryPage && !isFetchingNextCategoryPage) {
      fetchNextCategoryPage();
    }
  }, [
    categoryLoaderInView,
    hasNextCategoryPage,
    fetchNextCategoryPage,
    isFetchingNextCategoryPage,
  ]);

  if (!(categoryListing?.pages || categoryListing?.pages.length === 0)) return [];

  const categories = categoryListing.pages.flatMap((page) => page.categories);

  if (categories.length === 0) {
    return [
      {
        content: <div className={clsx(styles.noResults)}>No categories found</div>,
        isMenuItem: false,
      },
    ];
  }

  return [
    {
      content: (
        <MenuSearch
          onChange={handleCategoryFilterTextChange}
          onKeyDown={stopPropagationOnEscape}
          value={tempCategoryFilterText}
        />
      ),
      isMenuItem: false,
      className: styles.sticky,
    },
    {
      content: (
        <CategoryMenuItem
          asLabel
          className={styles.categoryMenuItem}
          handleCategoryChange={handleCategoryChange}
          label="Uncategorized"
          selectedCategoryId={selectedCategoryId}
          value={null}
        />
      ),
    },
    ...categories.map((category) => {
      const { label, id } = category;
      return {
        content: (
          <CategoryMenuItem
            asLabel
            className={styles.categoryMenuItem}
            handleCategoryChange={handleCategoryChange}
            label={label}
            selectedCategoryId={selectedCategoryId}
            value={id}
          />
        ),
      };
    }),
    {
      content: (
        <>
          {hasNextCategoryPage ? (
            <div ref={categoryLoaderRef} style={{ width: '100%', height: '10px' }} />
          ) : null}
        </>
      ),
      className: styles.loader,
      isMenuItem: false,
    },
  ];
};

export const useMediaGroupContextMenu = ({
  mediaGroup,
  onTagCreateClick,
  onShowSimilar,
  showPinning = false,
}: {
  mediaGroup: MediaGroupDTO;
  onTagCreateClick?: () => void;
  onShowSimilar?: () => void;
  showPinning?: boolean;
}) => {
  const { mutateAsync: patchMediaGroup } = usePatchMediaGroup();
  const { mutateAsync: copyMediaGroupToProject } = useCopyMediaGroup();
  const { mutateAsync: deleteMediaGroup } = useDeleteMediaGroup();
  const { mutateAsync: regenerateMediaGroupThumbnail } =
    useRegenerateMediaGroupThumbnail();

  const { data: project } = useProject(mediaGroup.project?.id || null);

  const [projectFilterText, setProjectFilterText] = useState('');

  const moveMediaGroupsConfirmModal = useMoveMediaGroupConfirmModal();

  const categorySubItems = useCategorySubMenu({
    onCategoryChange: (categoryId: string | null) => {
      handleCategoryChange(mediaGroup.id, categoryId);
    },
    projectId: mediaGroup.project?.id,
    selectedCategoryId: mediaGroup.category?.id,
  });

  const openDeleteMediaGroupConfirmModal = useDeleteMediaGroupConfirmModal({
    onConfirm: async (id: string) => {
      await deleteMediaGroup(id);
    },
  });
  const confirmRegenerateThumbnailModal = useConfirmModal<string>({
    title: 'Regenerate Thumbnail',
    subtitle: 'This will replace the existing thumbnail',
    confirmText: 'Yes, regenerate thumbnail',
    onConfirm: async (mediaGroupId) => {
      if (!mediaGroupId) {
        return;
      }
      await regenerateMediaGroupThumbnail(mediaGroupId);
      createToast({
        titleText: 'Thumbnail generation enqueued',
        bodyText: 'Media group thumbnail will soon be updated.',
        iconVariant: 'success',
      });
    },
  });
  const handleAddToProject = async (mediaGroupId: string, projectId?: string) => {
    copyMediaGroupToProject({
      mediaGroupIds: [mediaGroupId],
      projectId,
      mode: 'copy',
    });
  };

  const handleCategoryChange = async (
    mediaGroupId: string,
    categoryId: string | null
  ) => {
    if (mediaGroup.category?.id === categoryId) return;

    await patchMediaGroup({
      mediaGroupId,
      patch: { categoryId },
    });
    createToast({
      titleText: 'Category updated',
      bodyText: 'Content category was updated',
      iconVariant: 'success',
    });
  };
  const handleStatusUpdate = async (mediaGroupId: string, statusId: string | null) => {
    if (mediaGroup.status?.id === statusId) return;

    await patchMediaGroup({
      mediaGroupId,
      patch: { status: statusId },
    });
    createToast({
      titleText: 'Status updated',
      bodyText: 'Status was updated',
      iconVariant: 'success',
    });
  };

  const workspaceId = useWorkspaceId();
  const handleInputFocus = (ev: KeyboardEvent) => {
    if (ev.key !== 'Escape') {
      ev.stopPropagation();
    }
  };

  const { data: projects } = useWorkspaceProjects(workspaceId, {
    status: activeProjectKeys,
    flattenStacks: true,
  });
  const contextMenuItems: ContextMenuItemProps[] = [
    {
      content: 'Copy to...',
      subMenu: [
        {
          content: (
            <MenuSearch
              onChange={(ev) => setProjectFilterText(ev.target.value)}
              onKeyDown={handleInputFocus}
              placeholder="Search spaces"
              value={projectFilterText}
            />
          ),
          isMenuItem: false,
          className: styles.sticky,
        },
        ...makeAddToProjectMenu({
          projects:
            projects?.projects
              .filter(isProject)
              .filter(({ label }) =>
                stringContains(label, projectFilterText, { ignoreCase: true })
              ) ?? [],
          onAddToProject: (projectId) => handleAddToProject(mediaGroup.id, projectId),
          onAddToRepository: () => handleAddToProject(mediaGroup.id),
        }),
      ],
    },
  ];

  if (isCapable('edit', mediaGroup.userCapabilities).capable) {
    contextMenuItems.push({
      content: 'Move to...',
      subMenu: [
        {
          content: (
            <MenuSearch
              onChange={(ev) => setProjectFilterText(ev.target.value)}
              onKeyDown={handleInputFocus}
              placeholder="Search spaces"
              value={projectFilterText}
            />
          ),
          isMenuItem: false,
          className: styles.sticky,
        },
        ...makeAddToProjectMenu({
          projects:
            projects?.projects.filter(isProject).filter(({ label, id }) => {
              return (
                stringContains(label, projectFilterText, { ignoreCase: true }) &&
                id !== mediaGroup.project?.id
              );
            }) ?? [],
          onAddToProject: (projectId) =>
            moveMediaGroupsConfirmModal.open({
              mediaGroupIds: [mediaGroup.id],
              projectId,
            }),
          onAddToRepository:
            (!!mediaGroup.project?.id &&
              (() =>
                moveMediaGroupsConfirmModal.open({
                  mediaGroupIds: [mediaGroup.id],
                }))) ||
            undefined,
        }),
      ],
    });
    if (project?.project.mediaGroupStatuses) {
      contextMenuItems.push({
        content: 'Change status',
        subMenu: project?.project.mediaGroupStatuses.map((value) => {
          return {
            content: (
              <StatusMenuItem
                className={styles.statusMenuItem}
                handleStatusUpdate={(status) => {
                  handleStatusUpdate(mediaGroup.id, status);
                }}
                key={value.id}
                showSelectedAsCheck
                currentStatus={mediaGroup.status}
                status={value}
              />
            ),
          };
        }),
        width: 218,
      });
    }
    if (mediaGroup.project) {
      contextMenuItems.push({
        content: 'Change category',
        subMenu: categorySubItems,
        width: 228,
      });
    }
    if (onTagCreateClick) {
      contextMenuItems.push({
        content: 'Add a tag',
        onClick: () => onTagCreateClick(),
      });
    }
    contextMenuItems.push({
      content: 'Delete',
      onClick: () => openDeleteMediaGroupConfirmModal(mediaGroup.id),
    });
  }

  if (showPinning) {
    if (mediaGroup.isStarred) {
      contextMenuItems.push({
        content: 'Remove Pin',
        onClick: async () => {
          try {
            await patchMediaGroup({
              mediaGroupId: mediaGroup.id,
              patch: { isStarred: false },
            });
          } catch (error) {
            toastApiErrorOr(error, 'failed to remove media group star', {
              iconVariant: 'warning',
              titleText: 'Failed to Unpin',
              bodyText:
                'An unknown error occurred while trying to unpin. Please try again later.',
            });
          }
        },
      });
    } else {
      contextMenuItems.push({
        content: 'Pin to top',
        onClick: async () => {
          try {
            await patchMediaGroup({
              mediaGroupId: mediaGroup.id,
              patch: { isStarred: true },
            });
          } catch (error) {
            toastApiErrorOr(error, 'failed to add media group star', {
              iconVariant: 'warning',
              titleText: 'Failed to Pin',
              bodyText:
                'An unknown error occurred while trying to pin. Please try again later.',
            });
          }
        },
      });
    }
  }

  if (onShowSimilar) {
    contextMenuItems.push({
      content: 'Show similar items',
      onClick: onShowSimilar,
    });
  }

  if (isCapable('rethumb', mediaGroup.userCapabilities).capable) {
    contextMenuItems.push({
      content: 'Regenerate Thumbnail',
      onClick: () => confirmRegenerateThumbnailModal.open(mediaGroup.id),
    });
  }
  return contextMenuItems;
};
