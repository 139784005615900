import { Icon24, Icon64 } from '@spaceduck/icons';
import { useContext, useState } from 'react';

import { isProject } from '@spaceduck/api';

import { isCapable } from '@api/util';
import { useWorkspaceProjects } from '@api/workspace';
import NoEntries from '@components/NoEntries';
import ProjectListItem from '@components/ProjectListItem';
import { useCreateProjectModal } from '@components/admin/CreateProjectsModal';
import { useDebouncedValue } from '@hooks/useDebouncedValue';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { Search } from '@pages/common';
import { ThemeContext } from '@themes/Theme';
import Button from '@ui/Button';
import { Container, Header, SettingsError, TopNav } from './common';
import styles from './SpacesManager.module.scss';

const { Add } = Icon24;
const { Projects } = Icon64;

export default function SpacesManagerPage() {
  const workspaceId = useWorkspaceId();
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 400);
  const { data, status, isFetching } = useWorkspaceProjects(workspaceId, {
    query: debouncedSearchQuery,
    sort: 'open',
    flattenStacks: true,
  });
  const { open: openCreateProjectModal } = useCreateProjectModal({
    canCreateProject: isCapable('create', data?.capabilities),
    redirectOnCreate: false,
  });

  const hasError = status === 'error';
  const noSpaces = data?.projects ? data.projects.length === 0 : true;
  const isEmptyWorkspace = !debouncedSearchQuery && noSpaces;

  return (
    <>
      <TopNav
        currentBreadcrumb="Spaces"
        buttonIsDisabled={!isCapable('create', data?.capabilities)?.capable}
        buttonOnClick={openCreateProjectModal}
        buttonText="Create space"
        owner="workspace"
        showAddIcon
        title="Spaces Manager"
      />
      {hasError && (
        <Container>
          <PageHeader />
          <SettingsError />
        </Container>
      )}
      {!hasError && isEmptyWorkspace && <NoSpaces onClick={openCreateProjectModal} />}
      {!hasError && !isEmptyWorkspace && (
        <Container>
          <PageHeader />
          <Search
            defaultValue={searchQuery}
            onInput={(ev) => setSearchQuery(ev.currentTarget.value)}
            status={status}
            placeholder="Search spaces..."
          />
          {status === 'pending' ? (
            <div className={styles.statusMessage}>Searching...</div>
          ) : (
            <>
              {data?.projects.length ? (
                <div className={styles.list}>
                  {data.projects.filter(isProject).map((project) => {
                    return (
                      <ProjectListItem
                        key={project.id}
                        project={project}
                        redirectOnDelete={false}
                      />
                    );
                  })}
                  {isFetching && (
                    <div className={styles.statusMessage}>Loading more...</div>
                  )}
                </div>
              ) : (
                <div className={styles.statusMessage}>No matches found.</div>
              )}
            </>
          )}
        </Container>
      )}
    </>
  );
}

const PageHeader = () => {
  return (
    <Header>
      <h1>Spaces</h1>
      <p>Manage your Spaces.</p>
    </Header>
  );
};

const NoSpaces = ({ onClick }: { onClick?: () => void }) => {
  const { theme } = useContext(ThemeContext);
  const iconProps =
    theme === 'light'
      ? {
          topStroke: '#4B4E68',
          middleStroke: '#7A7E9F',
          bottomStroke: '#B5B7CA',
        }
      : {};

  return (
    <NoEntries icon={<Projects {...iconProps} />} pageType="settings">
      <h1>Spaces</h1>
      <p>
        Spaces help you organize, research, share, and track progress with your team,
        clients, stakeholders or even yourself.
      </p>
      {onClick && (
        <Button onClick={onClick} iconBefore={<Add />} size="sm" variant="primary">
          New space
        </Button>
      )}
    </NoEntries>
  );
};
