import {
  type ConnectionLineType,
  type Edge,
  type MarkerType,
  type Node,
  type OnConnect,
  type OnEdgesChange,
  type OnNodesChange,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from '@xyflow/react';
import { createStore } from 'zustand';
import { temporal } from 'zundo';
import isEqual from 'lodash/isEqual';
import { devtools } from 'zustand/middleware';

import type { EdgeStyle, EdgeWidth } from '../types/board';
import type { ConnectionLineColor } from '../types/colors';

export type ActionMenuMode = 'select' | 'document' | 'text' | 'frame';
export type FullPayload = { nodes?: Node[]; edges?: Edge[] } | null;

export type BoardState = {
  actionMenuMode: ActionMenuMode;
  setActionMenuMode: (mode: ActionMenuMode) => void;
  edges: Edge[];
  addNode: (node: Node) => void;
  getEdgeById: (id: string) => Edge | undefined;
  getNodeById: (id: string) => Node | undefined;
  clipboardItems: { nodes: Node[]; edges: Edge[] } | undefined;
  setClipboardItems: (items: { nodes: Node[]; edges: Edge[] } | undefined) => void;
  onNodesChange: OnNodesChange<Node>;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  nodes: Node[];
  setEdges: (edges: Edge[]) => void;
  setNodes: (payload: Node[] | ((nodes: Node[]) => Node[])) => void;
  setNodesAndEdges: (nodes: Node[], edges: Edge[]) => void;
  selectedNodes: string[];
  setSelectedNodes: (nodes: string[]) => void;
  selectedEdges: string[];
  setSelectedEdges: (edges: string[]) => void;
  defaultConnectionLineType: `${ConnectionLineType}`;
  setDefaultConnectionLineType: (type: `${ConnectionLineType}`) => void;
  defaultConnectionLineStyle: EdgeStyle;
  setDefaultConnectionLineStyle: (style: EdgeStyle) => void;
  defaultConnectionLineArrow: 'directional' | 'nonDirectional';
  setDefaultConnectionLineArrow: (arrow: 'directional' | 'nonDirectional') => void;
  defaultConnectionLineColor: ConnectionLineColor;
  setDefaultConnectionLineColor: (color: ConnectionLineColor) => void;
  defaultConnectionLineWidth: EdgeWidth;
  setDefaultConnectionLineWidth: (width: EdgeWidth) => void;
  defaultMarkerStartType:
    | `${MarkerType}`
    | 'markerTypeCircle'
    | 'markerTypeDiamond'
    | null;
  setDefaultMarkerStartType: (
    type: `${MarkerType}` | 'circle' | 'diamond' | null
  ) => void;
  defaultMarkerEndType:
    | `${MarkerType}`
    | 'markerTypeCircle'
    | 'markerTypeDiamond'
    | null;
  setDefaultMarkerEndType: (
    type: `${MarkerType}` | 'circle' | 'diamond' | null
  ) => void;
  panOnDrag: boolean;
  setPanOnDrag: (enabled: boolean) => void;
  sortNodes: () => Node[];
  lastSaved: FullPayload;
  setLastSaved: (state: FullPayload) => void;
  isDraggingHandle: boolean;
  setIsDraggingHandle: (isDraggingHandle: boolean) => void;
};

const isLocal = (import.meta.env.VITE_SENTRY_ENVIRONMENT ?? 'development') === 'local';
const enableDevtools =
  isLocal && (import.meta.env.VITE_ENABLE_ZUSTAND_DEVTOOLS ?? 'yes') === 'yes';

export const useCreateBoardStore = ({
  initialNodes,
  initialEdges,
}: { initialNodes: Node[]; initialEdges: Edge[] }) =>
  createStore<BoardState>()(
    temporal(
      devtools(
        (set, get) => ({
          actionMenuMode: 'select',
          setActionMenuMode: (mode: ActionMenuMode) => set({ actionMenuMode: mode }),
          nodes: initialNodes,
          edges: initialEdges,
          onNodesChange: (changes) => {
            set({
              nodes: applyNodeChanges(changes, get().nodes),
            });
          },
          onEdgesChange: (changes) => {
            set({
              edges: applyEdgeChanges(changes, get().edges),
            });
          },
          onConnect: (connection) => {
            set({
              edges: addEdge(connection, get().edges),
            });
          },
          setNodesAndEdges: (nodes, edges) => {
            set({ nodes, edges });
          },
          setNodes: (payload) => {
            set(
              typeof payload === 'function'
                ? ({ nodes }) => ({ nodes: payload(nodes) })
                : { nodes: payload }
            );
          },
          setEdges: (edges) => {
            set({ edges });
          },
          addNode: (node: Node) => set({ nodes: [...get().nodes, node] }),
          addEdge(edge: Edge) {
            set({ edges: [...get().edges, edge] });
          },
          getEdgeById: (id) => {
            return get().edges.find((edge) => edge.id === id);
          },
          getNodeById: (id) => {
            return get().nodes.find((node) => node.id === id);
          },
          selectedNodes: [],
          setSelectedNodes: (ids) => set({ selectedNodes: ids }),
          clipboardItems: undefined,
          setClipboardItems: (item) => set({ clipboardItems: item }),
          selectedEdges: [],
          setSelectedEdges: (ids) => set({ selectedEdges: ids }),
          defaultConnectionLineType: 'step',
          setDefaultConnectionLineType: (type) =>
            set({ defaultConnectionLineType: type }),
          defaultConnectionLineStyle: 'solid',
          setDefaultConnectionLineStyle: (style) =>
            set({ defaultConnectionLineStyle: style }),
          defaultConnectionLineArrow: 'nonDirectional',
          setDefaultConnectionLineArrow: (arrow) =>
            set({ defaultConnectionLineArrow: arrow }),
          defaultConnectionLineColor: 'neutral1',
          setDefaultConnectionLineColor: (color) =>
            set({ defaultConnectionLineColor: color }),
          defaultConnectionLineWidth: 'small',
          setDefaultConnectionLineWidth: (width) =>
            set({ defaultConnectionLineWidth: width }),
          defaultMarkerStartType: 'arrow',
          setDefaultMarkerStartType: (type) => {
            if (type === 'arrow') {
              set({ defaultMarkerStartType: type });
            } else if (type === 'circle') {
              // Match SVG Id
              set({ defaultMarkerStartType: 'markerTypeCircle' });
            } else if (type === 'diamond') {
              // Match SVG Id
              set({ defaultMarkerStartType: 'markerTypeDiamond' });
            } else {
              set({ defaultMarkerStartType: null });
            }
          },
          defaultMarkerEndType: null,
          setDefaultMarkerEndType: (type) => {
            if (type === 'arrow') {
              set({ defaultMarkerEndType: 'arrow' });
            } else if (type === 'circle') {
              // Match SVG Id
              set({ defaultMarkerEndType: 'markerTypeCircle' });
            } else if (type === 'diamond') {
              // Match SVG Id
              set({ defaultMarkerEndType: 'markerTypeDiamond' });
            } else {
              set({ defaultMarkerEndType: null });
            }
          },
          panOnDrag: false,
          setPanOnDrag: (enabled: boolean) => set({ panOnDrag: enabled }),
          sortNodes: () => {
            const nodes = get().nodes;
            const frameNodes = nodes.filter((node) => node.type === 'groupNode');
            const nonFrameNodes = nodes.filter((node) => node.type !== 'groupNode');
            const sortedNodes = [...frameNodes, ...nonFrameNodes];
            set({ nodes: sortedNodes });
            return sortedNodes;
          },
          lastSaved: { nodes: initialNodes, edges: initialEdges },
          setLastSaved: (state) => set({ lastSaved: state }),
          isDraggingHandle: false,
          setIsDraggingHandle: (isDraggingHandle) => set({ isDraggingHandle }),
        }),
        {
          enabled: enableDevtools,
        }
      ),
      {
        partialize: (state) => {
          const { nodes, edges } = state;
          return { nodes, edges };
        },
        equality: (pastState, currentState) => {
          const basePastState = {
            edges: pastState.edges.map((edge) => {
              const { selected, ...rest } = edge;
              return rest;
            }),
            nodes: pastState.nodes
              .filter((node) => node.id !== 'menuPlaceholder')
              .map((node) => {
                const { measured, selected, ...rest } = node;
                return rest;
              }),
          };

          const baseCurrentState = {
            edges: currentState.edges.map((edge) => {
              const { selected, ...rest } = edge;
              return rest;
            }),
            nodes: currentState.nodes
              .filter((node) => node.id !== 'menuPlaceholder')
              .map((node) => {
                const { measured, selected, ...rest } = node;
                return rest;
              }),
          };

          return isEqual(basePastState, baseCurrentState);
        },
      }
    )
  );

export default useCreateBoardStore;
