import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 12,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 12 12"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#a)">
        <path
          fill={color}
          d="M10.2 5.4 2.7.15a.75.75 0 0 0-1.2.6v10.5c0 .6.675.975 1.2.6l7.5-5.25a.78.78 0 0 0 0-1.2"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={color} d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
