import { useContext, useEffect, useState } from 'react';

import { Icon16, Icon32 } from '@spaceduck/icons';

import Browse01 from '@assets/img/onboarding/library-01.png';
import Browse02 from '@assets/img/onboarding/library-02.png';
import Browse03 from '@assets/img/onboarding/library-03.png';
import { FileInputContext } from '@components/FileUploadWrapper';
import { NewMediaGroupDropdown } from '@components/NewMediaGroupDropdown';
import Badge from '@ui/Badge';
import Button from '@ui/Button';
import { BROWSER_EXT_DIV_ID, BROWSER_EXT_URL } from '@/const';
import styles from './EmptyLibrary.module.scss';
import Card, { CardGrid } from './empty/Card';
import Lightbox from './empty/Lightbox';

const { Add } = Icon16;
const { Play } = Icon32;

const VIDEO_URL = 'https://www.youtube.com/embed/ajiOCDHa0EU?si=UN7ZldrQcxhm9OSe';

export default function EmptyLibrary() {
  const [showBrowserExt, setShowBrowserExt] = useState(
    !document.getElementById(BROWSER_EXT_DIV_ID)
  );
  const [showLightbox, setShowLightbox] = useState(false);
  const { inputRef } = useContext(FileInputContext);

  useEffect(() => {
    setShowBrowserExt(!document.getElementById(BROWSER_EXT_DIV_ID));
  }, []);

  return (
    <>
      <div className={styles.emptyLibraryContainer}>
        <div className={styles.text}>
          <h1>Library</h1>
          <p>
            Start building your personal hub for ideas and insights. Upload files,
            notes, PDFs, images, and more to organize collections, spark inspiration, or
            dive into deep research.
          </p>
        </div>
        {!!VIDEO_URL && (
          <div className={styles.video}>
            <button className={styles.iconButton} onClick={() => setShowLightbox(true)}>
              <Play />
            </button>
            <button className={styles.textButton} onClick={() => setShowLightbox(true)}>
              Learn how Spaces work
            </button>
          </div>
        )}
        <CardGrid>
          {showBrowserExt && (
            <Card
              button={
                <Button
                  iconBefore={<Add />}
                  onClick={() => window.open(BROWSER_EXT_URL)}
                  size="sm"
                  variant="outlined"
                >
                  Download Now
                </Button>
              }
            >
              <img className={styles.paddedImage} alt="" src={Browse01} />
              <div className={styles.headingRow}>
                <h3>Browser Extension</h3>
                <Badge preset="trial" size="sm">
                  Must Have!
                </Badge>
              </div>
              <p>
                Install our Browser Extension and effortlessly capture anything from the
                web on the go.
              </p>
            </Card>
          )}
          <Card
            button={
              <Button
                iconBefore={<Add />}
                onClick={() => inputRef?.current?.click()}
                size="sm"
                variant="outlined"
              >
                Upload Content
              </Button>
            }
          >
            <img className={styles.paddedImage} alt="" src={Browse02} />
            <h3>Upload your first media or file</h3>
            <p>
              Just drag and drop or upload your files, and watch as they are
              automatically organized for you.
            </p>
          </Card>
          <Card
            button={
              <NewMediaGroupDropdown
                align="start"
                exclude={['media', 'link']}
                triggerText="New item"
                variant="outlined"
              />
            }
          >
            <img className={styles.paddedImage} alt="" src={Browse03} />
            <h3>Document & Board</h3>
            <p>
              Documents and boards auto-connect to your content for easier research and
              analysis.
            </p>
          </Card>
        </CardGrid>
      </div>
      {!!VIDEO_URL && (
        <Lightbox
          close={() => setShowLightbox(false)}
          embedUrl={VIDEO_URL}
          open={showLightbox}
        />
      )}
    </>
  );
}
