import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  size = 64,
  topStroke = '#B5B7CA',
  leftStroke = '#4B4E68',
  rightStroke = '#7A7E9F',
}: IconProps & {
  topStroke?: string;
  leftStroke?: string;
  rightStroke?: string;
}) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 64 64"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="31.9999"
        cy="18.6667"
        r="9.66667"
        stroke={topStroke}
        strokeWidth="2"
      />
      <circle
        cx="15.9999"
        cy="45.3334"
        r="9.66667"
        stroke={leftStroke}
        strokeWidth="2"
      />
      <circle
        cx="47.9999"
        cy="45.3334"
        r="9.66667"
        stroke={rightStroke}
        strokeWidth="2"
      />
    </svg>
  );
}
