import { Icon16, Icon24 } from '@spaceduck/icons';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useShallow } from 'zustand/shallow';

import type { DetailsModelTab } from '@/types/MediaGroup';
import { urlFor } from '@/urls';
import { useMediaGroupDetail } from '@api/mediaGroup';
import { isCapable } from '@api/util';
import { useNavigateWithState } from '@hooks/useNavigateWithState';
import { asMilliseconds } from '@spaceduck/utils';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import Tabs, { type Tab } from '@ui/Tabs';
import AiSettingsPanel from './sidebar/AISettings';
import BrowseRepository from './sidebar/BrowseRepository';
import Comments from './sidebar/Comments';
import Info from './sidebar/Info';
import { ResearchChat } from './sidebar/ResearchChat';
import type { Editor } from '@tiptap/core';
import { markdownToHtml } from '@/utils/markdown';
import styles from './DetailsModalSidebar.module.scss';

const {
  Comment: CommentIcon,
  Info: InfoIcon,
  RepositoryBrowse: RepositoryBrowseIcon,
} = Icon16;

const { AI, ResearchChatAI } = Icon24;

export const getUrl = (activeTab: DetailsModelTab, mediaGroupId: string) => {
  switch (activeTab) {
    case 'aiSettings':
      return urlFor('mediaGroupAISettings', { mediaGroupId });
    case 'browseRepository':
      return urlFor('mediaGroupBrowseRepository', { mediaGroupId });
    case 'comment':
      return urlFor('mediaGroupComments', { mediaGroupId });
    case 'tableOfContent':
      return urlFor('mediaGroupTableOfContent', { mediaGroupId });
    default:
      return urlFor('mediaGroup', { mediaGroupId });
  }
};

export default function DetailsModalSidebar({
  mediaGroupId,
  activeTab,
  view,
  enlarged,
  editor,
}: {
  mediaGroupId: string;
  activeTab: DetailsModelTab;
  view: string | null;
  enlarged: boolean;
  editor?: Editor | null;
}) {
  const { drawerIsOpen, openDrawer, toggleDrawer, isDraggingOnBoard } =
    useDetailsModalStore(
      useShallow((state) => ({
        drawerIsOpen: state.drawerIsOpen,
        openDrawer: state.openDrawer,
        toggleDrawer: state.toggleDrawer,
        isDraggingOnBoard: state.isDraggingOnBoard,
      }))
    );
  const { data, isLoading } = useMediaGroupDetail(mediaGroupId, {
    enabled: !isDraggingOnBoard,
    refetchInterval: asMilliseconds({ seconds: 3 }),
  });
  const navigateWithState = useNavigateWithState();
  const setActiveTab = (activeTab: DetailsModelTab) => {
    const url = urlFor('mediaGroupModal', { mediaGroupId, activeTab, view });
    navigateWithState(url);
  };

  if (!data) return null; // TODO: add error state

  const { mediaGroup, userCapabilities } = data;

  const tabs = useMemo(() => {
    const tabs: Tab<DetailsModelTab>[] = [
      {
        isActive: true,
        icon: <InfoIcon />,
        content: (
          <Info
            mediaGroup={mediaGroup}
            userCanEdit={isCapable('edit', userCapabilities).capable}
          />
        ),
        value: 'info',
        tooltip: 'Info',
      },
    ];

    tabs.push({
      icon: <RepositoryBrowseIcon />,
      content: (
        <BrowseRepository
          disableQuery={isDraggingOnBoard}
          isLoading={isLoading}
          mediaGroupId={mediaGroup.id}
        />
      ),
      isActive: false,
      value: 'browseRepository',
      tooltip: 'Browse',
    });

    if (mediaGroup.kind === 'document') {
      tabs.push({
        icon: <AI />,
        content: <AiSettingsPanel mediaGroup={mediaGroup} />,
        isActive: false,
        value: 'aiSettings',
        tooltip: 'AI Settings',
      });

      if (mediaGroup.project) {
        tabs.push({
          icon: <ResearchChatAI />,
          content: (
            <ResearchChat
              addToCurrentDocument={async (text) => {
                if (editor) {
                  editor.commands.insertContent(await markdownToHtml(text));
                }
              }}
              mediaGroup={mediaGroup}
            />
          ),
          isActive: false,
          value: 'researchChat',
          tooltip: 'AI Chat Assistant',
        });
      }
    }

    if (isCapable('comment', userCapabilities).capable) {
      tabs.push({
        icon: <CommentIcon />,
        content: <Comments isLoading={isLoading} mediaGroupId={mediaGroup.id} />,
        isActive: false,
        value: 'comment',
        tooltip: 'Comments',
      });
    }

    return tabs;
  }, [mediaGroup, userCapabilities, isDraggingOnBoard, isLoading, editor]);

  return (
    <div
      className={clsx(styles.sidebar, enlarged && styles.enlarged)}
      data-state={drawerIsOpen ? 'open' : 'closed'}
    >
      <div className={styles.tabContainer}>
        <Tabs
          activeTab={activeTab}
          className={styles.tabs}
          contentClassName={styles.tabContent}
          onClick={(value?: string) => {
            if (activeTab === value) {
              toggleDrawer();
            } else {
              if (!drawerIsOpen) {
                openDrawer();
              }
              setActiveTab(value as DetailsModelTab);
            }
          }}
          orientation="vertical"
          tabs={tabs}
          tabButtonProps={{ size: 'xs', variant: 'ghost' }}
          tabWrapperProps={{ className: styles.tabWrapper, variant: 'ghost' }}
        />
      </div>
    </div>
  );
}
