import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router';

import type { MediaGroupCategory } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';

import { useDeleteMediaGroupCategory } from '@api/mediaGroupCategory';
import { useListMediaGroupCategories, useProject } from '@api/project';
import Head from '@components/Head';
import EmptyCategories from '@components/projects/EmptyCategories';
import { useCreateCategoryModal } from '@hooks/useCreateCategoryModal';
import { useDebouncedSearch } from '@hooks/useDebouncedSearch';
import { useEditCategoryModal } from '@hooks/useEditCategoryModal';
import { EditMenu, Search, SubNav } from '@pages/common';
import Button from '@ui/Button';
import { useConfirmModal } from '@ui/ConfirmModal';
import DropdownMenu, { MenuItem } from '@ui/DropdownMenu';
import createToast from '@utils/createToast';
import { stringContains } from '@utils/string';
import { urlFor } from '@/urls';
import ProjectTabs from './common/ProjectTabs';
import PageHeader from './common/PageHeader';
import ProjectMenu from './common/ProjectMenu';
import TwoColumnContent from './common/TwoColumnContent';
import styles from './Categories.module.scss';

const { Sort } = Icon16;
const { Add, Down, List } = Icon24;

const sortByOptions = ['Newest', 'Oldest', 'Name'] as const;
type SortByOption = (typeof sortByOptions)[number];

function alphabeticalSort(a: MediaGroupCategory, b: MediaGroupCategory) {
  if (a.label > b.label) return 1;
  if (a.label < b.label) return -1;
  return 0;
}

function oldestFirstSort(a: MediaGroupCategory, b: MediaGroupCategory) {
  return a.lastUpdated > b.lastUpdated ? 1 : -1;
}

function newestFirstSort(a: MediaGroupCategory, b: MediaGroupCategory) {
  return a.lastUpdated <= b.lastUpdated ? 1 : -1;
}

function getSortFunction(sortBy: SortByOption) {
  switch (sortBy) {
    case 'Oldest':
      return oldestFirstSort;
    case 'Name':
      return alphabeticalSort;
    default:
      return newestFirstSort;
  }
}

export default function CategoriesPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const { debouncedSetSearchQuery } = useDebouncedSearch(setSearchQuery);
  const [sortBy, setSortBy] = useState<SortByOption>('Newest');
  const projectId = useParams<{ projectId?: string }>().projectId;
  const { data: projectData } = useProject(projectId || null);
  const { data: categoriesData } = useListMediaGroupCategories(projectId || null);
  const categories = categoriesData?.pages.flatMap((page) => page.categories);

  const sortedCategories = useMemo(() => {
    if (!categories) return [];
    const sortFn = getSortFunction(sortBy);

    if (searchQuery) {
      const filteredCategories = [...categories].filter((category) =>
        stringContains(category.label, searchQuery, { ignoreCase: true })
      );
      return filteredCategories.sort(sortFn);
    }

    return [...categories].sort(sortFn);
  }, [categories, searchQuery, sortBy]);

  const { open: openCreateModal } = useCreateCategoryModal();

  if (!projectData) {
    return;
  }

  return (
    <>
      <Head title={`${projectData.project?.label || 'Space'} Categories`} />
      <PageHeader />
      <ProjectTabs
        cta={
          <Button
            onClick={() => openCreateModal(projectData.project.id)}
            size="sm"
            variant="outlined"
            iconBefore={<Add size={16} />}
          >
            New Category
          </Button>
        }
      />
      <TwoColumnContent
        contentToolbar={null}
        sidebar={<ProjectMenu project={projectData.project} />}
      >
        {!categories || categories.length === 0 ? (
          <EmptyCategories projectId={projectId} />
        ) : (
          <>
            <SubNav>
              <div className={styles.utilities}>
                <DropdownMenu
                  className={styles.sortDropdown}
                  isPadded
                  triggerContent={
                    <Button
                      className={styles.sortButton}
                      iconAfter={<Down size={16} />}
                      iconBefore={<Sort size={15} />}
                      variant="outlined"
                    >
                      Sort by: {sortBy}
                    </Button>
                  }
                >
                  {sortByOptions.map((sortByOption, idx) => (
                    <MenuItem
                      key={idx}
                      onSelect={() => {
                        if (sortBy !== sortByOption) {
                          setSortBy(sortByOption);
                        }
                      }}
                    >
                      {sortByOption}
                    </MenuItem>
                  ))}
                </DropdownMenu>
                {/* TODO: Hook search up with API */}
                <Search
                  className={styles.searchBox}
                  collapsible
                  defaultExpanded={false}
                  defaultValue={searchQuery}
                  onInput={(ev) => debouncedSetSearchQuery(ev.currentTarget.value)}
                  placeholder="Find a category..."
                  size="sm"
                  status="success"
                />
              </div>
            </SubNav>
            <CategoriesTable categories={sortedCategories} />
          </>
        )}
      </TwoColumnContent>
    </>
  );
}

const CategoriesTable = ({
  categories,
}: {
  categories?: MediaGroupCategory[];
}) => {
  if (!categories || categories.length === 0) return null;

  return (
    <div className={styles.tableWrapper}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Category Name</th>
            <th>Number of items</th>
            <th>Number of properties</th>
            <th title="Actions" />
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <CategoriesTableRow category={category} key={category.id} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const CategoriesTableRow = ({ category }: { category: MediaGroupCategory }) => {
  const { open: openEditModal } = useEditCategoryModal();
  const { mutateAsync: deleteMediaGroupCategory } = useDeleteMediaGroupCategory();

  const { open: openDeleteModal } = useConfirmModal<{ id: string }>({
    title: 'Delete category',
    subtitle:
      'This action cannot be undone. Deleting this category and its properties will permanently remove them from all associated content.',
    confirmVariant: 'danger',
    confirmText: 'Yes, delete category',
    onConfirm: async (vars) => {
      if (!vars) return;

      const { id } = vars;

      const response = await deleteMediaGroupCategory(id);
      createToast({
        titleText: 'Category deleted successfully',
        bodyText: `Category name "${response.category.label}" was deleted.`,
        iconVariant: 'success',
      });
    },
  });

  return (
    <tr>
      <td>
        <Link
          className={styles.tableLabel}
          to={urlFor('workspaceCategory', {
            categoryId: category.id,
          })}
        >
          <List size={20} />
          {category.label}
        </Link>
      </td>
      <td>{category.mediaGroupCount || '-'}</td>
      <td>{category.propertiesCount || '-'}</td>
      <td>
        <EditMenu>
          <MenuItem
            disabled={
              !category.capabilities?.find(
                ({ capability, capable }) => capability === 'edit' && capable
              )
            }
            onSelect={() => openEditModal(category)}
          >
            Edit
          </MenuItem>
          <MenuItem
            disabled={
              !category.capabilities?.find(
                ({ capability, capable }) => capability === 'delete' && capable
              )
            }
            onSelect={() => openDeleteModal({ id: category.id })}
          >
            Delete
          </MenuItem>
        </EditMenu>
      </td>
    </tr>
  );
};
