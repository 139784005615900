import { clsx } from 'clsx';
import { forwardRef } from 'react';
import type { DetailedHTMLProps, InputHTMLAttributes, ReactNode } from 'react';

import { Icon24 } from '@spaceduck/icons';
import styles from './Checkbox.module.scss';
const { Check } = Icon24;

type Layout = 'standard' | 'toggle';
type Size = 'xs' | 'sm' | 'md' | 'lg';
type Variant = 'primary' | 'secondary' | 'tertiary';

type CheckboxProps = {
  asMenuItem?: boolean;
  children?: ReactNode;
  className?: string;
  hideCheckboxIcon?: boolean;
  icon?: ReactNode;
  layout?: Layout;
  onClick?: (value: string) => void;
  singleSelection?: boolean;
  size?: Size;
  variant?: Variant;
  value?: string;
} & Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'size' | 'type' | 'value'
>;

const iconSize = {
  xs: 20,
  sm: 20,
  md: 24,
  lg: 32,
};

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      asMenuItem = false,
      children,
      className,
      hideCheckboxIcon = false,
      icon,
      onClick,
      singleSelection,
      size = 'sm',
      layout = 'standard',
      variant = 'primary',
      ...inputProps
    },
    ref
  ) => {
    return (
      <label
        className={clsx(
          styles.selectionButton,
          singleSelection && styles.singleSelection,
          styles[size],
          styles[variant],
          inputProps.disabled && styles.disabled,
          layout === 'toggle' && styles.isToggle,
          asMenuItem && styles.asMenuItem,
          className
        )}
        onClick={() => inputProps?.value && onClick?.(inputProps?.value)}
      >
        <input type="checkbox" {...inputProps} ref={ref} />
        {layout === 'toggle' ? (
          <>
            <span className={styles.toggle} />
          </>
        ) : (
          <>
            {!hideCheckboxIcon && (
              <span className={styles.icon}>
                <Check size={iconSize[size]} />
              </span>
            )}
          </>
        )}
        {icon && <span className={styles.customIcon}>{icon}</span>}
        {children}
      </label>
    );
  }
);

export default Checkbox;
