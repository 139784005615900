import type { MediaGroupCategory } from '@spaceduck/api';

import { EditCategoryModel } from '@components/modals/ManageCategoryModal';
import { useModalManager } from '@context/ModalManagerContext';

export function useEditCategoryModal() {
  const { openModal, closeModal } = useModalManager();
  return {
    open: (category: MediaGroupCategory) => {
      openModal({
        component: <EditCategoryModel category={category} closeModal={closeModal} />,
      });
    },
    close: closeModal,
  };
}
