import type { ReactNode } from 'react';
import clsx from 'clsx';

import { Brand } from '@spaceduck/icons';

import styles from './Header.module.scss';

const { BrandImage, BrandText } = Brand;

export const Header = ({
  className,
  children,
}: {
  className?: string;
  children?: ReactNode;
}) => {
  return (
    <div className={clsx(styles.header, className)}>
      <div className={styles.branding}>
        <BrandImage />
        <BrandText />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
