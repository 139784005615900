import { createContext, useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router';
import { z } from 'zod';

import { createLocalStorageHook } from '@hooks/useLocalStorage';
import Spinner from '@ui/Spinner';
import './index.scss';
import styles from './Theme.module.scss';

export const themes = ['dark', 'light', 'system'] as const;
export type Theme = (typeof themes)[number];
export type DisplayTheme = Extract<Theme, 'dark' | 'light'>;

export const ThemeContext = createContext<{
  theme: Theme;
  setTheme: React.Dispatch<React.SetStateAction<Theme>> | null;
}>({ theme: 'system', setTheme: null });

export default function Theme() {
  const { setTheme, theme } = useTheme();

  if (!theme) return <Spinner />;

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <Outlet />
    </ThemeContext.Provider>
  );
}

const useSavedTheme = createLocalStorageHook({
  key: 'theme',
  initial: 'system',
  schema: z.enum(themes),
});

const getDisplayTheme = (theme: Theme): DisplayTheme => {
  if (theme === 'dark' || theme === 'light') return theme;
  return window.matchMedia('(prefers-color-scheme: light)').matches ? 'light' : 'dark';
};

const addBodyClass = (cls: string | undefined) => {
  if (cls === undefined) {
    return;
  }
  return document.body?.classList.add(cls);
};

const removeBodyClass = (cls: string | undefined) => {
  if (cls === undefined) {
    return;
  }
  return document.body?.classList.remove(cls);
};

const useTheme = () => {
  const { value: theme, setValue: setTheme } = useSavedTheme();
  const [displayTheme, setDisplayTheme] = useState(getFallbackTheme(theme));

  useEffect(() => {
    if (theme === displayTheme) return;
    if (theme === 'dark' || theme === 'light') {
      setDisplayTheme(theme);
      return;
    }

    if (theme === 'system') {
      const mq = window.matchMedia('(prefers-color-scheme: light)');
      setDisplayTheme(mq.matches ? 'light' : 'dark');

      const systemThemeChangeHandler = (ev: MediaQueryListEvent) => {
        setDisplayTheme(ev.matches ? 'light' : 'dark');
      };

      mq.addEventListener('change', systemThemeChangeHandler);

      return () => mq.removeEventListener('change', systemThemeChangeHandler);
    }
  }, [theme]);

  const clearRef = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    addBodyClass(styles.noTransition);
    document.documentElement.setAttribute('data-sd-theme', getDisplayTheme(theme));

    clearTimeout(clearRef.current);
    clearRef.current = setTimeout(() => {
      removeBodyClass(styles.noTransition);
    }, 1);
  }, [displayTheme]);

  return {
    theme,
    setTheme,
  };
};

function getFallbackTheme(theme: Theme | DisplayTheme): DisplayTheme {
  return theme === 'light' ? 'light' : 'dark';
}
