import { useCallback, useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router';

import { isCapable, toastApiErrorOr } from '@api/util';
import { useWorkspace } from '@api/workspace';
import Head from '@components/Head';
import { useModalManager } from '@context/ModalManagerContext';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { useDestroySession, useUserInfo } from '@hooks/useAuth';
import { Box, Container } from '@pages/settings/workspace/common';
import Button from '@ui/Button';
import Dialog from '@ui/Dialog';
import Spinner from '@ui/Spinner';
import { WorkspaceAvatar } from '@ui/UserAvatar';
import { urlFor } from '@/urls';
import { SubscriptionFeatures } from './common/SubscriptionFeatures';
import styles from './ExpiredSubscription.module.scss';
import { sendRenewalRequest } from '@spaceduck/api';
import createToast from '@/utils/createToast';
import { useGetSubscriptionInfo } from '@/api/billing';

export default function ExpiredSubscriptionPage() {
  const workspaceId = useWorkspaceId();
  const { data: workspaceData, isLoading } = useWorkspace(workspaceId);
  const user = useUserInfo();
  const navigate = useNavigate();
  const destroySession = useDestroySession();

  const handleLogout = async () => {
    await destroySession();
    navigate('/');
  };

  const { data: subscriptionInfo } = useGetSubscriptionInfo(
    workspaceData?.workspace.id || null
  );

  useEffect(() => {
    if (!isLoading && !workspaceData) {
      navigate(urlFor('userSettingsWorkspaces'));
    }
  }, [workspaceData, isLoading]);

  if (isLoading || !workspaceData?.workspace || !subscriptionInfo) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  if (subscriptionInfo.active) {
    return (
      <Navigate
        to={urlFor('workspaceHome', { workspaceId: workspaceData.workspace.id })}
      />
    );
  }

  const { avatarUrl, label, capabilities } = workspaceData.workspace;
  const canSubscribe = isCapable('workspaceSubscribe', capabilities).capable;

  return (
    <>
      <Head title="Expired subscription" />
      <Container className={styles.container}>
        <Box>
          <header>
            <WorkspaceAvatar
              size="xs"
              workspaceAvatar={avatarUrl}
              workspaceName={label}
            />
            <span>{label}</span>
          </header>
          {canSubscribe ? (
            <OwnerContent
              onClick={async () => {
                navigate(
                  urlFor('workspaceOnboarding', {
                    workspaceId: workspaceData.workspace.id,
                  })
                );
              }}
            />
          ) : (
            <ViewerContent />
          )}
          <ul className={styles.quickLinks}>
            <li>
              <Link to={urlFor('userSettingsProfile')}>Account settings</Link>
            </li>
            <li>
              <span className={styles.dot} />
            </li>
            <li>
              <Link to={urlFor('userSettingsWorkspaces')}>
                Select another Workspace
              </Link>
            </li>
          </ul>
        </Box>
        {user?.email && (
          <p className={styles.secondaryAction}>
            {`Not ${user.email}?`}{' '}
            <Button onClick={handleLogout} variant="link">
              Signout
            </Button>
          </p>
        )}
      </Container>
    </>
  );
}

const OwnerContent = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <h3>Your workspace subscription has expired</h3>
      <p>
        Your workspace does not have an active subscription, so it has been deactivated.
        Re-subscribe now to reactivate your workspace and restore access instantly.
      </p>
      <SubscriptionFeatures subscriptionIsActive />
      <Button
        className={styles.restoreButton}
        onClick={onClick}
        isFullWidth
        size="md"
        variant="primary"
      >
        Restore Access Now
      </Button>
    </>
  );
};

const ViewerContent = () => {
  const { open } = useRequestAccessModal();
  const handleClick = useCallback(() => {
    open();
  }, [open]);

  return (
    <>
      <h3>Your workspace subscription has expired</h3>
      <p>
        Your workspace does not have an active subscription, so it has been deactivated.
        Please contact your Workspace owner(s) to restore access.
      </p>
      <Button
        className={styles.restoreButton}
        isFullWidth
        onClick={handleClick}
        size="md"
        variant="primary"
      >
        Request Access Now
      </Button>
    </>
  );
};

const RequestAccessModal = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  const workspaceId = useWorkspaceId();
  if (!workspaceId) {
    return;
  }
  return (
    <Dialog
      className={styles.requestAccessModal}
      closeModal={closeModal}
      description=""
      isOpen={true}
      maxWidth="26.25rem"
      modalHeading="Request Access"
      padding="lg"
    >
      <div className={styles.content}>
        <p>
          We will email this workspace’s owner(s) to let them know that you want to gain
          access to this workspace.
        </p>
        <div className={styles.footer}>
          <Button onClick={closeModal} size="sm" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              try {
                await sendRenewalRequest(workspaceId);
              } catch (e) {
                return toastApiErrorOr(e, 'Failed to send renewal request', {
                  iconVariant: 'warning',
                  titleText: 'Failed to send renewal request',
                  bodyText:
                    'An error occurred when attempting to send renewal request. Please try again later.',
                });
              }
              createToast({
                bodyText: 'An email has been sent',
                iconVariant: 'success',
              });
              closeModal();
            }}
            className={styles.blue}
            size="sm"
            variant="primary"
          >
            Send Request
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

function useRequestAccessModal() {
  const { openModal, closeModal } = useModalManager();
  return {
    open: () => {
      openModal({
        component: <RequestAccessModal closeModal={closeModal} />,
      });
    },
  };
}
