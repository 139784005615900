import { useBackgroundLocation } from '@/hooks/useBackgroundLocation';
import styles from '@components/detailsModal/DetailsModalHeader.module.scss';
import { Icon24 } from '@spaceduck/icons';
import Button, { ButtonLink } from '@ui/Button';
import { DetailsModalTooltip } from '../comments/DetailsModalTooltip';
const { Close, DrawerRight, Left, Right, Share } = Icon24;

export default function DetailsModalHeaderPlaceholder() {
  const [lastBackgroundLocation, ...previousLocations] = useBackgroundLocation();

  return (
    <div className={styles.header}>
      <nav className={styles.navigation}>
        <DetailsModalTooltip
          content="Close"
          shortKeys={['Esc']}
          size="md"
          variant="secondary"
        >
          <ButtonLink
            variant="icon"
            to={lastBackgroundLocation || '/'}
            state={
              previousLocations.length
                ? { backgroundLocation: previousLocations }
                : undefined
            }
          >
            <Close size={20} />
          </ButtonLink>
        </DetailsModalTooltip>
        <span className={styles.divider} />
        <Button variant="icon" disabled>
          <Left />
        </Button>
        <Button variant="icon" disabled>
          <Right />
        </Button>
      </nav>
      <div className={styles.utils}>
        <Button variant="icon" disabled>
          <Share size={20} />
        </Button>
        <span className={styles.divider} />
        <Button variant="icon" disabled>
          <DrawerRight size={20} />
        </Button>
      </div>
    </div>
  );
}
