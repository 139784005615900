import type { AliveCommentDTO, CommentDTO } from '@spaceduck/api';
import { clsx } from 'clsx';
import { type ReactNode, useEffect, useRef, useState } from 'react';

import {
  useDeleteComment,
  useEditComment,
  useToggleReaction,
  useToggleResolve,
} from '@api/comment';
import { useMediaGroupDetail } from '@api/mediaGroup';
import { useUserInfo } from '@hooks/useAuth';
import dayjs from '@lib/dayjs';
import { Icon16, Icon24 } from '@spaceduck/icons';
import AlertBanner from '@ui/AlertBanner';
import Button from '@ui/Button';
import { useDeleteConfirmModal } from '@ui/ConfirmModal';
import Tooltip from '@ui/Tooltip';
import UserAvatar, { DucklasAvatar } from '@ui/UserAvatar';
import styles from './Comment.module.scss';
import { CommentTextBox, NewCommentTextBox } from './CommentTextbox';
import CommentToolbar from './CommentToolbar';
import { EmojiButton, EmojiPicker } from './EmojiButton';
const { Reaction } = Icon16;
const { Down, Up, Resolve } = Icon24;
import { usePatchProjectMembership } from '@/api/project';
import {
  type Editor,
  EditorContent,
  type JSONContent,
  generateText,
} from '@tiptap/react';
import { tiptapExtensions, useReadOnlyTiptap } from './Tiptap';

function ImagePreview({
  pointAt,
  assetUrl,
}: {
  pointAt: { x: number; y: number };
  assetUrl: string;
}) {
  const [imageSize, setImageSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  const onImageLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const img = event.currentTarget;
    setImageSize({ width: img.naturalWidth, height: img.naturalHeight });
  };

  return (
    <div className={styles.previewContainer}>
      {assetUrl && (
        <img
          src={assetUrl}
          onLoad={onImageLoad}
          style={{
            position: 'absolute',
            transform: `scale(${imageSize.width / 150})`,
            transformOrigin: `${pointAt.x * 100}% ${pointAt.y * 100}%`,
            maxWidth: '100%',
            maxHeight: '100%',
          }}
        />
      )}
    </div>
  );
}

function InviteInComment({
  actionableInvites,
  projectId,
  dismiss,
  comment,
  usersMentionedInComment,
}: {
  actionableInvites: ActionableInvitesInComment;
  projectId: string;
  dismiss: () => void;
  comment: AliveCommentDTO;
  usersMentionedInComment: { id: string; label: string }[];
}) {
  const { mutateAsync: patchProjectMembership } = usePatchProjectMembership();
  return (
    <div className={clsx(styles.comment, styles.inviteInComment)}>
      <DucklasAvatar size="xs" />
      <div className={styles.commentRight}>
        <div className={styles.meta}>
          <span className={styles.createdInfo}>
            <span className={styles.createdByName} title={'Ducklas'}>
              {'Ducklas'}
            </span>
            <span className={styles.createdAt}>Only visible to you</span>
          </span>
        </div>
        {actionableInvites.userIds.map((userId) => (
          <div key={userId}>
            <p>
              You mentioned{' '}
              <span className={styles.mention}>
                @{usersMentionedInComment.find((user) => user.id === userId)?.label}
              </span>{' '}
              but they're not in this private project.
            </p>
            <div className={styles.inviteActions}>
              <Button variant="outlined" onClick={dismiss} size="sm">
                Never mind
              </Button>
              <Button
                variant="primary"
                size="sm"
                onClick={async () => {
                  await patchProjectMembership({
                    projectId: projectId,
                    userId: userId,
                    role: 'V',
                    trigger: {
                      reason: 'comment',
                      id: comment.id,
                    },
                  });
                  dismiss();
                }}
              >
                Invite them
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const renderTextContent = (content: string, isAnnotation?: boolean) => (
  <>
    {content.split('\n').map((line, index) => (
      <p
        key={index}
        className={clsx(styles.textContent, isAnnotation && styles.isAnnotation)}
      >
        {line || <br />}
      </p>
    ))}
  </>
);

const TextContent = ({
  content,
  isAnnotation,
  forceTruncate,
}: {
  content: string;
  isAnnotation?: boolean;
  forceTruncate: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);
  const shouldTruncate = content.length > 150 && !expanded;
  return (
    <>
      {renderTextContent(
        shouldTruncate ? `${content.substring(0, 150)}...` : content,
        isAnnotation
      )}
      {shouldTruncate && !forceTruncate && (
        <p className={styles.showMore} onClick={() => setExpanded(true)}>
          Show more
        </p>
      )}
    </>
  );
};

const DocumentContent = ({
  document,
  isAnnotation,
  editor,
  forceTruncate,
}: {
  document: JSONContent;
  isAnnotation?: boolean;
  editor: Editor;
  forceTruncate: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);
  let shouldTruncate = false;
  let renderedContent: ReactNode;
  try {
    const text = generateText(document, tiptapExtensions(true));
    shouldTruncate = text.length > 150 && !expanded;
    if (shouldTruncate) {
      renderedContent = renderTextContent(`${text.substring(0, 150)}...`, isAnnotation);
    } else {
      renderedContent = <EditorContent editor={editor} />;
    }
  } catch (e) {
    renderedContent = renderTextContent('', isAnnotation);
  }

  useEffect(() => {
    editor.commands.setContent(document);
  }, [document]);

  return (
    <>
      {renderedContent}
      {shouldTruncate && !forceTruncate && (
        <p className={styles.showMore} onClick={() => setExpanded(true)}>
          Show more
        </p>
      )}
    </>
  );
};

export const useCommentContent = (
  comment: AliveCommentDTO,
  forceTruncate: boolean,
  isAnnotation?: boolean
): {
  component: ReactNode;
  usersMentionedInComment: { id: string; label: string }[];
} => {
  const editor = useReadOnlyTiptap({
    editorProps: {
      attributes: {
        class: clsx(styles.textContent, isAnnotation && styles.isAnnotation),
      },
    },
    content: comment.document,
  });
  if (comment.document && editor) {
    const usersMentionedInComment: { id: string; label: string }[] = [];
    editor?.state.doc.descendants((node) => {
      if (node.type.name === 'mention') {
        usersMentionedInComment.push({
          id: node.attrs.id,
          label: node.attrs.label,
        });
      }
    });
    return {
      component: (
        <DocumentContent
          forceTruncate={forceTruncate}
          document={comment.document}
          isAnnotation={isAnnotation}
          editor={editor}
        />
      ),
      usersMentionedInComment,
    };
  }
  return {
    component: (
      <TextContent
        forceTruncate={forceTruncate}
        content={comment.content}
        isAnnotation={isAnnotation}
      />
    ),
    usersMentionedInComment: [],
  };
};

function Comment({
  assetUrl,
  comment,
  hasReplies,
  isAnnotation,
  isCollapsed,
  isReply,
  isReplying = false,
  mediaGroupId,
  onCollapse,
  onReplyClick,
  onResolve,
  onUncollapse,
  showPreview,
  actionableInvites,
}: {
  assetUrl?: string;
  comment: AliveCommentDTO;
  hasReplies: boolean;
  isAnnotation?: boolean;
  isCollapsed?: boolean;
  isReply?: boolean;
  isReplying?: boolean;
  mediaGroupId: string;
  onCollapse?: () => void;
  onReplyClick?: () => void;
  onResolve?: (resolved: boolean) => void;
  onUncollapse?: () => void;
  showPreview?: boolean;
  actionableInvites?: ActionableInvitesInComment;
}) {
  const [actionableInvitesLocal, setActionableInvitesLocal] =
    useState(actionableInvites);
  const me = useUserInfo();
  const { mutateAsync: toggleReactionMutation } = useToggleReaction({
    mediaGroupId,
  });
  const [showInviteBox, setShowInviteBox] = useState<boolean>(
    actionableInvitesLocal?.commentId === comment.id &&
      !!actionableInvitesLocal.userIds.length
  );
  const { mutateAsync: deleteComment } = useDeleteComment({
    mediaGroupId,
    commentId: comment.id,
  });
  const { mutateAsync: editComment } = useEditComment({
    mediaGroupId,
    commentId: comment.id,
  });
  const confirmDeleteModal = useDeleteConfirmModal({
    onConfirm: () => {
      deleteComment();
    },
  });
  const { component: commentContent, usersMentionedInComment } = useCommentContent(
    comment,
    false,
    isAnnotation
  );
  const [toolbarVisible, setToolbarVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleReact = async (emoji: string) => {
    toggleReactionMutation({
      commentId: comment.id,
      emoji,
      setOn: !comment.reactions
        .find((r) => r.emoji === emoji)
        ?.reactedBy.some((u) => u.id === me?.id),
    });
  };

  const handleEdit = async (content: string, document?: JSONContent) => {
    const response = await editComment({ content, document });
    if (response.projectId) {
      setActionableInvitesLocal({
        commentId: response.id,
        projectId: response.projectId,
        userIds: response.invitable,
      });
    }
    setIsEditing(false);
  };

  const hasImagePreview = !!(
    !isCollapsed &&
    showPreview &&
    assetUrl &&
    comment.pointAt
  );

  useEffect(() => {
    setShowInviteBox(
      actionableInvitesLocal?.commentId === comment.id &&
        !!actionableInvitesLocal.userIds.length
    );
  }, [actionableInvitesLocal]);

  if (showInviteBox && actionableInvitesLocal) {
    return (
      <InviteInComment
        projectId={actionableInvitesLocal.projectId}
        actionableInvites={actionableInvitesLocal}
        dismiss={() => setShowInviteBox(false)}
        comment={comment}
        usersMentionedInComment={usersMentionedInComment}
      />
    );
  }
  return (
    <div
      className={clsx(
        styles.comment,
        isAnnotation && styles.isAnnotation,
        !isCollapsed && styles.isExpanded,
        hasReplies && styles.hasReplies,
        !isCollapsed && isReplying && styles.isReplying
      )}
      onMouseEnter={() => setToolbarVisible(true)}
      onMouseLeave={() => setToolbarVisible(false)}
      data-comment-id={comment.id}
    >
      <UserAvatar
        name={comment.createdBy.name}
        imageUrl={comment.createdBy.avatarUrl}
        size={isAnnotation ? 'xxs' : 'xs'}
      />
      <div className={styles.commentRight}>
        <div className={clsx(styles.meta, comment.resolved && styles.isResolved)}>
          <span className={styles.createdInfo}>
            <span className={styles.createdByName} title={comment.createdBy.name}>
              {comment.createdBy.name}
            </span>
            <span className={styles.createdAt}>
              {dayjs(comment.createdAt).fromNow()}
            </span>
          </span>
          {comment.resolved && (
            <span className={styles.collapsedMenu}>
              <Tooltip
                content={`Resolved by ${comment.resolved?.by?.name ?? 'someone'}`}
                align="end"
              >
                <Button
                  className={styles.btnResolved}
                  onClick={() => onResolve?.(false)}
                  size="sm"
                  variant="icon"
                >
                  <Resolve className={styles.resolved} size={20} />
                </Button>
              </Tooltip>
              <Button
                className={styles.collapseToggle}
                onClick={() => {
                  isCollapsed ? onUncollapse?.() : onCollapse?.();
                }}
                size="sm"
                variant="icon"
              >
                {isCollapsed ? <Down size={20} /> : <Up size={20} />}
              </Button>
            </span>
          )}
        </div>
        <div
          className={clsx(styles.summary, hasImagePreview && styles.hasImagePreview)}
          onClick={() => scrollToInlineComment(comment.id)}
        >
          {!isCollapsed && !isEditing && (
            <>
              <div className={styles.content}>{commentContent}</div>
              <div
                className={clsx(
                  styles.emojis,
                  comment.reactions.length > 0 && styles.hasEmojis
                )}
                ref={ref}
              >
                {comment.reactions.map((reaction) => (
                  <EmojiButton
                    key={reaction.emoji}
                    active={reaction.reactedBy.some((u) => u.id === me?.id)}
                    emoji={reaction.emoji}
                    count={reaction.count}
                    onClick={() => handleReact(reaction.emoji)}
                  />
                ))}
                {comment.reactions.length > 0 && (
                  <EmojiPicker onClick={handleReact} containerRef={ref}>
                    <Button size="xs" variant="emoji" className={styles.addEmoji}>
                      <Reaction />
                    </Button>
                  </EmojiPicker>
                )}
              </div>
            </>
          )}
          {!isCollapsed && isEditing && (
            <CommentTextBox
              className={styles.contentBoxEdit}
              onCancel={() => setIsEditing(false)}
              onSubmit={handleEdit}
              placeholder="Update your comment"
              value={comment.content}
              isInEditMode={true}
              isLoading={false}
              document={comment.document}
            />
          )}
        </div>
        {hasImagePreview && (
          <ImagePreview pointAt={comment.pointAt!} assetUrl={assetUrl} />
        )}
        {toolbarVisible && !isCollapsed && (
          <CommentToolbar
            onReact={handleReact}
            onResolve={onResolve}
            onReply={onReplyClick}
            onDelete={confirmDeleteModal.open}
            onEdit={() => setIsEditing((prev) => !prev)}
            showEditTools={
              comment.createdBy &&
              'id' in comment.createdBy &&
              comment.createdBy.id === me?.id
            }
            isResolved={Boolean(comment.resolved)}
            isReply={isReply}
          />
        )}
      </div>
    </div>
  );
}

export type ActionableInvitesInComment = {
  commentId: string;
  userIds: string[];
  projectId: string;
};

export function CommentThread({
  className,
  comment,
  isAnnotation,
  mediaGroupId,
  showPreviews,
  actionableInvites,
}: {
  className?: string;
  comment: CommentDTO;
  isAnnotation?: boolean;
  mediaGroupId: string;
  showPreviews?: boolean;
  actionableInvites?: ActionableInvitesInComment;
}) {
  const me = useUserInfo();
  const { mutateAsync: resolveCommentMutation } = useToggleResolve({
    mediaGroupId,
  });

  const { data: mediaGroupData } = useMediaGroupDetail(mediaGroupId);
  const assetUrl =
    mediaGroupData?.mediaGroup.media?.id === comment.pointAt?.mediaId
      ? mediaGroupData?.mediaGroup.media?.assetUrl
      : undefined;

  const [isReplying, setIsReplying] = useState(isAnnotation ?? false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  useEffect(() => {
    if (comment.kind === 'alive') {
      setIsCollapsed(Boolean(comment.resolved));
    }
  }, [comment.kind, comment.kind === 'alive' ? comment.resolved : null]);

  const hasReplies = !!(comment.replies && comment.replies.length > 0);

  // TODO: Preferred name not same as comment.createdBy.name
  const { avatarUrl, preferredName } = me || {};

  return (
    <div className={className}>
      {comment.kind === 'deleted' ? (
        <AlertBanner className={styles.deletedCommentAlert} variant="info">
          <p>This comment was deleted.</p>
        </AlertBanner>
      ) : (
        <Comment
          showPreview={showPreviews}
          assetUrl={assetUrl}
          isAnnotation={isAnnotation}
          isCollapsed={isCollapsed}
          onCollapse={() => setIsCollapsed(true)}
          onUncollapse={() => setIsCollapsed(false)}
          key={comment.id}
          mediaGroupId={mediaGroupId}
          comment={comment}
          onResolve={(resolved) => {
            resolveCommentMutation({
              commentId: comment.id,
              resolved,
            });
          }}
          onReplyClick={() => setIsReplying((prev) => !prev)}
          hasReplies={hasReplies}
          isReplying={isReplying}
          actionableInvites={actionableInvites}
        />
      )}
      <div
        className={clsx(
          styles.replies,
          isAnnotation && styles.isAnnotation,
          !isCollapsed && styles.isExpanded,
          hasReplies && styles.hasReplies
        )}
      >
        {!isCollapsed &&
          comment.replies?.map((reply) => (
            <Comment
              key={reply.id}
              mediaGroupId={mediaGroupId}
              comment={reply}
              isAnnotation={isAnnotation}
              isReply={true}
              onResolve={(resolved) => {
                resolveCommentMutation({
                  commentId: reply.id,
                  resolved,
                });
              }}
              hasReplies={hasReplies}
              isReplying={isReplying}
              actionableInvites={actionableInvites}
            />
          ))}
        {!isCollapsed && !isAnnotation && isReplying && (
          <div className={styles.reply}>
            {(preferredName || avatarUrl) && (
              <UserAvatar name={preferredName ?? ''} imageUrl={avatarUrl} size="xs" />
            )}
            <div className={styles.commentBox}>
              <NewCommentTextBox
                isLoading={false}
                mediaGroupId={mediaGroupId}
                onCancel={() => setIsReplying(false)}
                parentCommentId={comment.id}
                placeholder="Write a reply..."
              />
            </div>
          </div>
        )}
      </div>
      {isAnnotation && isReplying && (
        <div className={clsx(styles.commentBox, isAnnotation && styles.isAnnotation)}>
          <NewCommentTextBox
            isLoading={false}
            mediaGroupId={mediaGroupId}
            onCancel={() => setIsReplying(false)}
            parentCommentId={comment.id}
            placeholder="Write a reply..."
          />
        </div>
      )}
    </div>
  );
}

export function CommentSummary({
  className,
  comment,
}: {
  className?: string;
  comment: AliveCommentDTO;
}) {
  const { component: summary } = useCommentContent(comment, true);
  return (
    <div className={clsx(styles.comment, className)}>
      <UserAvatar
        name={comment.createdBy.name}
        imageUrl={comment.createdBy.avatarUrl}
        size="xxs"
      />
      <div className={styles.commentRight}>
        <div className={styles.meta}>
          <span className={styles.createdInfo}>
            <span className={styles.createdByName} title={comment.createdBy.name}>
              {comment.createdBy.name}
            </span>
            <span className={styles.createdAt}>
              {dayjs(comment.createdAt).fromNow()}
            </span>
          </span>
        </div>
        <div className={styles.summary}>
          <div className={clsx(styles.content, styles.summaryContent)}>{summary}</div>
        </div>
      </div>
    </div>
  );
}

function scrollToInlineComment(id: string) {
  const commentInNote = document.querySelector(
    `[data-comment][data-comment-id="${id}"]`
  );
  if (commentInNote) {
    commentInNote.scrollIntoView({
      block: 'nearest',
      behavior: 'smooth',
    });
  }
}
