import type { Optional } from '@spaceduck/utils';

import {
  useBulkPatchMediaGroupStatus,
  useDeleteMediaGroupStatus,
} from '@/api/mediaGroupStatus';
import { useProject } from '@/api/project';
import createToast from '@/utils/createToast';
import StatusSelect from '@components/StatusSelect';
import { useModalManager } from '@context/ModalManagerContext';
import * as Popover from '@radix-ui/react-popover';
import type {
  CreateMediaGroupStatus,
  ExistingMediaGroupStatus,
  MediaGroupStatus,
} from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import Dialog from '@ui/Dialog';
import clsx from 'clsx';
import { type ReactElement, useEffect, useRef, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  type DraggableProvided,
  type DraggableStateSnapshot,
  type DraggingStyle,
  Droppable,
  type OnDragEndResponder,
} from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';
import {
  type UseFormRegister,
  type UseFormSetValue,
  type UseFormWatch,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import MediaGroupStatusIcon from '../icons/MediaGroupStatus';
import Button from './Button';
import { ConfirmModal, useDeleteConfirmModal } from './ConfirmModal';
import DropdownMenu, { MenuItem } from './DropdownMenu';
import styles from './SpaceWorkflowModal.module.scss';

const { DraggableIndicator, Menu, Empty } = Icon24;
const { Workflow, ShapeColor } = Icon16;

type SpaceWorkflowModalProps = {
  projectId: string;
  closeModal: () => void;
  onCancel?: () => void;
};

const STATUS_DEFAULT_COLORS = [
  'EAC041',
  'FFB545',
  'FBA179',
  'F66A6A',
  'ED8CBD',
  '937EE7',
  '6DD177',
  // spell-checker: disable-next-line;
  '5BBEFF',
  '4167F0',
  '232323',
  '7A7E9F',
  'FFFFFF',
];

const isStatusNew = (
  status: MediaGroupStatus | CreateMediaGroupStatus
): status is CreateMediaGroupStatus => {
  return Object.keys(status).includes('projectId');
};

type MediaGroupStatusesPayload = {
  mediaGroupStatuses: Array<ExistingMediaGroupStatus | CreateMediaGroupStatus>;
};

const SpaceWorkflowModal = ({ closeModal, projectId }: SpaceWorkflowModalProps) => {
  const { data: project } = useProject(projectId);
  const { register, handleSubmit, setValue, control, getValues, watch } =
    useForm<MediaGroupStatusesPayload>({
      defaultValues: {
        mediaGroupStatuses: project?.project.mediaGroupStatuses.filter(
          (status) => status.id
        ),
      },
    });

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'mediaGroupStatuses',
  });

  const { mutateAsync: deleteMediagroupStatus } = useDeleteMediaGroupStatus();
  const { mutateAsync: bulkPatchMediaGroupStatus } = useBulkPatchMediaGroupStatus();

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };
  const [enabled, setEnabled] = useState(import.meta.env.MODE === 'production');

  // Re-order by dragging in development mode fix
  // Source - https://stackoverflow.com/questions/67780314/react-beautiful-dnd-invariant-failed-cannot-find-droppable-entry-with-id
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  const deleteStatusAtIndex = async (i: number, toStatus?: string | null) => {
    const status = getValues(`mediaGroupStatuses.${i}`);
    if (status && !isStatusNew(status) && status.id && toStatus !== undefined) {
      await deleteMediagroupStatus({
        mediaGroupStatusId: status.id,
        toMediaGroupStatusId: toStatus,
      });
      createToast({
        bodyText: 'Status removed',
        iconVariant: 'success',
      });
    } else if (status && !isStatusNew(status) && !status.id) {
      console.error('Cannot remove "No status".');
      createToast({
        bodyText: 'Could not remove status, please try again later',
        iconVariant: 'warning',
      });
      return;
    } else if (status && !isStatusNew(status) && toStatus === undefined) {
      console.error('Need to specify a destination for media group status deletion.');
      createToast({
        bodyText: 'Could not remove status, please try again later',
        iconVariant: 'warning',
      });
      return;
    }
    remove(i);
  };
  const renderDraggable = useDraggableInPortal();
  return (
    <Dialog
      closeModal={closeModal}
      isOpen={true}
      className={styles.managementModal}
      maxWidth="32.5rem"
      modalHeading={
        <div className={styles.modalHeader}>
          <Workflow />
          <span>Manage your workflow</span>
        </div>
      }
      padding="md"
      footer={
        <div className={styles.modalFooter}>
          <Button variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={handleSubmit(async (statuses) => {
              if (statuses.mediaGroupStatuses.length) {
                await bulkPatchMediaGroupStatus(statuses.mediaGroupStatuses);
              }
              closeModal();
            })}
          >
            Save workflow
          </Button>
        </div>
      }
    >
      <div className={styles.modalContent}>
        <form>
          <table>
            <thead>
              <tr>
                <th />
                <th>Name</th>
                <th>Color</th>
                <th>Items</th>
              </tr>
            </thead>
            <DragDropContext onDragEnd={onDragEnd}>
              {enabled && (
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {fields.map((_, i) => {
                        const statusValue = getValues(`mediaGroupStatuses.${i}`);
                        if (!statusValue) {
                          return;
                        }
                        return (
                          <Draggable
                            index={i}
                            key={`status.${i}`}
                            draggableId={`status.${i}`}
                          >
                            {renderDraggable((provided, snapshot) => {
                              return (
                                <StatusRow
                                  projectId={projectId}
                                  i={i}
                                  deleteStatusAtIndex={deleteStatusAtIndex}
                                  provided={provided}
                                  status={getValues(`mediaGroupStatuses.${i}`)}
                                  register={register}
                                  setValue={setValue}
                                  watch={watch}
                                  className={
                                    snapshot.isDragging
                                      ? styles.rowIsDragging
                                      : undefined
                                  }
                                />
                              );
                            })}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              )}
            </DragDropContext>
          </table>
        </form>
        <Button
          className={styles.addStatusButton}
          variant="outlined"
          onClick={() => {
            append({ color: 'FFFFFF', projectId, label: '' });
          }}
        >
          Add another status
        </Button>
      </div>
    </Dialog>
  );
};

const StatusRow = ({
  status,
  provided,
  register,
  deleteStatusAtIndex,
  i,
  setValue,
  watch,
  projectId,
  className,
}: {
  status: MediaGroupStatus | CreateMediaGroupStatus;
  provided: DraggableProvided;
  register: UseFormRegister<MediaGroupStatusesPayload>;
  deleteStatusAtIndex: (i: number, toStatus?: string | null) => void;
  i: number;
  setValue: UseFormSetValue<MediaGroupStatusesPayload>;
  watch: UseFormWatch<MediaGroupStatusesPayload>;
  projectId: string;
  className?: string;
}) => {
  const colorWatch = watch(`mediaGroupStatuses.${i}.color`);
  const { open: openDeletePlanConfirmationModal } = useDeleteConfirmModal({
    onConfirm: () => {
      deleteStatusAtIndex(i, null);
    },
  });
  const { open: openDeleteModal } = useConfirmDeleteStatusModal({
    projectId,
    deleteStatus: (toStatus: string | null) => deleteStatusAtIndex(i, toStatus),
  });
  const deleteStatus = async () => {
    if (isStatusNew(status)) {
      deleteStatusAtIndex(i, null);
      return;
    }
    if (!status.itemsCount) {
      openDeletePlanConfirmationModal();
      return;
    }
    openDeleteModal(status);
  };
  return (
    <tr
      className={clsx('formGroup', styles.statusRow, className)}
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={{ ...provided.draggableProps.style }}
    >
      <td className={styles.draggableCol}>
        <Button type="button" {...provided.dragHandleProps} variant="ghost" size="md">
          <DraggableIndicator />
        </Button>
      </td>
      <td className={clsx(styles.inputCell, styles.labelInputCol)}>
        <input {...register(`mediaGroupStatuses.${i}.label`)} type="text" />
      </td>
      <td className={clsx(styles.inputCell, styles.colorInputCol)}>
        <span className={styles.colorIcon}>
          <Empty color={`#${colorWatch}`} fill={`#${colorWatch}`} />
        </span>
        <span className={styles.colorPrefix}>#</span>
        <ColorPickerPopover
          trigger={
            <input
              onInput={(e) => {
                if (e.currentTarget) {
                  const s = e.currentTarget.selectionStart;
                  e.currentTarget.value = e.currentTarget.value.toUpperCase();
                  e.currentTarget.setSelectionRange(s, s);
                }
              }}
              {...register(`mediaGroupStatuses.${i}.color`)}
              type="text"
            />
          }
          onColorSelected={(color) => {
            setValue(`mediaGroupStatuses.${i}.color`, color);
          }}
        />
        {!isStatusNew(status) && (
          <input {...register(`mediaGroupStatuses.${i}.id`)} hidden />
        )}
        {isStatusNew(status) && (
          <input
            {...register(`mediaGroupStatuses.${i}.projectId`)}
            value={status.projectId}
            hidden
          />
        )}
      </td>
      <td className={styles.actionsCol}>
        <div className={styles.actions}>
          {!isStatusNew(status) ? status.itemsCount : 0}
          <DropdownMenu
            align="end"
            className={styles.dropdownMenu}
            triggerContent={
              <Button variant="icon">
                <Menu size={20} />
              </Button>
            }
            width={134}
          >
            <MenuItem onSelect={deleteStatus}>Delete</MenuItem>
          </DropdownMenu>
        </div>
      </td>
    </tr>
  );
};

// https://github.com/atlassian/react-beautiful-dnd/issues/128
export const useDraggableInPortal = () => {
  const element = useRef<HTMLDivElement>(document.createElement('div')).current;

  useEffect(() => {
    if (element) {
      element.style.pointerEvents = 'none';
      element.style.position = 'absolute';
      element.style.height = '100%';
      element.style.width = '100%';
      element.style.top = '0';

      document.body.appendChild(element);

      return () => {
        document.body.removeChild(element);
      };
    }
  }, [element]);

  return (
    render: (
      provided: DraggableProvided,
      snapshot: DraggableStateSnapshot
    ) => ReactElement
  ) =>
    (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
      const result = render(provided, snapshot);
      const style = provided.draggableProps.style as DraggingStyle;
      if (style.position === 'fixed') {
        return createPortal(result, element);
      }
      return result;
    };
};

const ColorPickerPopover = ({
  onColorSelected,
  trigger,
}: {
  onColorSelected: (color: string) => void;
  trigger: ReactElement;
}) => {
  const colorsSorted = STATUS_DEFAULT_COLORS.reduce<Array<Array<string>>>(
    (prev, curr, i) => {
      if (i % 4 === 0) {
        prev.push([]);
      }
      prev[prev.length - 1]?.push(curr);
      return prev;
    },
    []
  );
  return (
    <Popover.Root>
      <Popover.Trigger asChild>{trigger}</Popover.Trigger>
      <Popover.Portal>
        <Popover.Content>
          <div className={styles.colorPicker}>
            {colorsSorted.map((colorRow, i) => {
              return (
                <div key={i}>
                  {colorRow.map((color, iColor) => {
                    const colorHex = `#${color}`;
                    return (
                      <span key={iColor}>
                        <Popover.Close
                          className={styles.colorOption}
                          onClick={() => onColorSelected(color)}
                        >
                          <ShapeColor color={colorHex} />
                        </Popover.Close>
                      </span>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

const ConfirmModalBody = ({
  status,
  availableStatuses,
  confirm,
  closeModal,
}: {
  status: MediaGroupStatus;
  availableStatuses: MediaGroupStatus[];
  confirm: (toStatus: string | null) => void;
  closeModal: () => void;
}) => {
  const [toStatus, setToStatus] = useState<string | null>(null);
  return (
    <ConfirmModal
      title={'Are you sure you want to delete this status?'}
      subtitle={'If so, please select a status to migrate the relevant items to.'}
      cancelText={'Cancel'}
      confirmText={'Continue '}
      confirmVariant={'primary'}
      onConfirm={() => confirm(toStatus)}
      closeModal={closeModal}
      className={styles.confirmModal}
    >
      <div className={styles.deleteStatus}>
        <div className={styles.deleteStatusCol}>
          <div>Merge this status...</div>
          <div className={styles.currentStatus}>
            <span>
              <MediaGroupStatusIcon status={status} />
            </span>
            <input type="text" disabled value={status.label} />
          </div>
        </div>
        <div className={styles.deleteStatusCol}>
          <div>To this status...</div>
          <div>
            <StatusSelect
              selectGroups={[
                {
                  options:
                    availableStatuses
                      .filter(
                        (innerStatus): innerStatus is ExistingMediaGroupStatus => {
                          return (
                            !!innerStatus.id &&
                            !!status.id &&
                            innerStatus.color !== status.color
                          );
                        }
                      )
                      .map((status) => ({
                        value: status.id,
                        label: status.label,
                        icon: <MediaGroupStatusIcon status={status} />,
                      })) || [],
                },
              ]}
              placeholder="Choose a status..."
              showIndicator
              onValueChange={(value) => {
                setToStatus(value || null);
              }}
              showClear={false}
              showNoStatus
              contentClassName={styles.deleteStatusSelect}
            />
          </div>
        </div>
      </div>
    </ConfirmModal>
  );
};

function useConfirmDeleteStatusModal({
  projectId,
  deleteStatus,
}: {
  projectId: string;
  deleteStatus: (toStatus: string | null) => void;
}) {
  const { data: project } = useProject(projectId);

  const { openModal, closeModal } = useModalManager();
  return {
    open: (status: MediaGroupStatus) => {
      openModal({
        component: (
          <ConfirmModalBody
            availableStatuses={project?.project.mediaGroupStatuses || []}
            confirm={deleteStatus}
            status={status}
            closeModal={closeModal}
          />
        ),
      });
    },
    close: closeModal,
  };
}

export function useSpaceWorkflowModal(
  modalProps: Optional<SpaceWorkflowModalProps, 'closeModal'>
) {
  const { openModal, closeModal: managerCloseModal } = useModalManager();

  const closeModal = () => {
    modalProps.closeModal?.();
    managerCloseModal();
  };

  return {
    open: () => {
      openModal({
        component: <SpaceWorkflowModal closeModal={closeModal} {...modalProps} />,
        closeModal,
      });
    },
    close: managerCloseModal,
  };
}
